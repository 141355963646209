import React from 'react';
import LabelSelect from '../../../components/site/labelselect';

const characterInfo = props => {
  return (
    <div className='flex flex-row gap-5 flex-wrap '>
      <div className="input-field characters-label">
        <h6>Name</h6>
        <input
        className="browser-default"
          type="text"
          placeholder="Your setup name"
          onChange={props.handleSetName}
          value={props.characterInfo.name}
        />
      </div>
      <LabelSelect
        className="characters-label"
        key="race"
        name="race"
        value={props.characterInfo.race}
        data={props.races}
        handleSelectChange={props.handleRaceChange}
      />
      <LabelSelect
        className="characters-label"
        key="class"
        name="class"
        value={props.characterInfo.class}
        data={props.classes}
        handleSelectChange={props.handleClassChange}
      />
      <LabelSelect
        className="characters-label"
        key="spec"
        name="spec"
        value={props.characterInfo.spec}
        data={props.specs}
        handleSelectChange={props.handleSpecChange}
      />
      <LabelSelect
        className="characters-label"
        key="Level"
        name="Level"
        value={props.characterInfo.lvl}
        data={props.levels}
        handleSelectChange={props.handleLvlChange}
      />
      <LabelSelect
        className="characters-label"
        key="phase"
        name="phase"
        value={props.characterInfo.phase}
        data={props.phases}
        handleSelectChange={props.handlePhaseChange}
      />
      {/* <LabelSelect
        className="characters-label"
        selectClass="buffs-label"
        key="buffs"
        name="buffs"
        value={props.characterInfo.buffs}
        data={props.buffs}
        multiple={true}
        handleSelectChange={props.handleBuffChange}
      /> */}
    </div>
  );
};

export default characterInfo;
