import React from 'react';
import SelectList from '../elements/selectlist';

const labelSelect = props => {
  return (
    <div className={'input-field ' + props.className}>
      <h6>{props.name}</h6>
      <SelectList settings={props} />
    </div>
  );
};

export default labelSelect;
