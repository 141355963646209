import React, { Fragment } from 'react';
import AttributeHeader from './attributeHeader';
import AttributeRow from './attributeRow';
import '../../../css/attributes.scss';

const attributes = props => {
  let activeGroup = props.groupAttributesData[props.activeGroup];
  if (props.activeGroup === 'base') {
    activeGroup = [
      'strength',
      'agility',
      'stamina',
      'intellect',
      'spirit',
      'health',
      'mana'
    ];
  }
  return (
    <Fragment>
      <AttributeHeader
        handleAttributeClick={props.handleAttributeClick}
        attributesGroup={Object.keys(props.groupAttributesData)}
        activeGroup={props.activeGroup}
      />
      {activeGroup.map(attribute => (
        <AttributeRow
          key={'attribute-' + attribute}
          data={props.data.baseAttributes}
          attributeVerbage={props.attributeVerbage}
          attribute={attribute}
        />
      ))}
    </Fragment>
  );
};

export default attributes;
