import itemSet from '@/store/modules/item-set/item-set'
import location from '@/store/modules/location/location'
import Vue from 'vue'
import Vuex from 'vuex'
import listView from '../../../../lib/src/store/list-view'
import npcTypes from '../../../../lib/src/store/npc/npc-types'
import { pluralize, prettifyRouteName } from '../../../../lib/src/util'
import {
  baseTypes, getFiltersForRoute, getMetaForRoute, getMetaTitleForRoute, getSubTypesForRoute,
  onTypeChanged
} from '../../../../lib/src/util/types'
import { createRouter } from '../router'
import api from './api-client'

Vue.use(Vuex)
const router = createRouter()
const BASE_TABLE_DATA = {
  items: [],
  objects: [],
  itemSets: [],
  npcs: [],
  zones: [],
  quests: [],
  spells: [],
  factions: [],
  races: [],
  classes: [],
  events: []
}

const BASE_FILTERS = {
  name: null,
  class: null,
  checkedSources: [],
  itemSlot: null,
  itemType: null,
  itemSetType: null,
  minimumLevel: '0',
  maximumLevel: '63',
  minimumQuality: '0',
  maximumQuality: '6',
  phase: null,
  itemSetClass: null,
  location: null,
  npcType: null,
  questSort: null
}

export const createStore = () => {
  return new Vuex.Store({
    state: () => ({
      items: {},
      itemSets: {},
      objects: {},
      filters: BASE_FILTERS,
      quests: {},
      news: {},
      zones: {},
      factions: {},
      spells: {},
      npcs: {},
      races: {},
      classes: {},
      events: {},
      selectedNpcId: undefined,
      tooltips: {
        item: {},
        'item-set': {},
        npc: {},
        quest: {},
        spell: {},
        faction: {},
        race: {},
        event: {},
        classData: {},
        current: {}

      },
      types: {
        item: {},
        'item-set': {},
        npc: {},
        zone: {},
        quest: {},
        spell: {},
        object: {},
        faction: {},
        race: {},
        event: {},
        classData: {}
      },
      ui: {
        filterRelatedTable: '',
        isMobileSideNavOpen: false
      },
      tableData: BASE_TABLE_DATA,
      filteredTableData: [],
      loading: false,
      pageInfo: {},
      highlightedId: null,
      selectedRelatedTableIndex: ''
    }),
    actions: {
      fetchTooltip ({ commit }, { id, type }) {
        return api
          .getTooltip(id, type)
          .then(tooltip => commit('setTooltip', { id, type, tooltip }))
      },
      async fetchTypes ({ state, commit, dispatch }, type) {
        try {
          const types = await api.getTypes(type)

          commit('setTypes', { type, types })
          dispatch('setFiltersFromRoute')
        } catch (e) {
          console.error(e)
          console.error('Error getting types')
        }
      },
      setFiltersFromRoute ({ state, getters, dispatch }) {
        const path = state.route.path
        const filters = getFiltersForRoute(getters.baseTypes, path)
        dispatch('handleFilterChange', filters, { root: true })
      },
      async updateRouteWithFilters ({ state, getters }, fieldName) {
        const newRoute = onTypeChanged({
          fieldName,
          filters: state.filters,
          route: state.route,
          baseTypes: getters.baseTypes
        })

        if (newRoute) {
          return newRoute
        }
      },
      async fetchItemSet ({ commit }, id) {
        return api
          .getItemSet(id)
          .then(itemSet => {
            if (!itemSet) {
              router.push({ path: '/not-found' })
              router.go()
              return
            }
            commit('setItemSet', { id, itemSet })
          })
      },
      async fetchItem ({ commit }, payload) {
        const page = payload.page
        const id = payload.id
        api.getItem(id).then(async (item) => {
          if (!item) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const droppedBy = await api.getItemDroppedBy(id, page)
          const canBePlacedIn = await api.fetchRelatedTableData('item', id, 'can-be-placed-in', page)
          const canContain = await api.fetchRelatedTableData('item', id, 'can-contain', page)
          const sameModelAs = await api.getItemSameModelAs(id, page)
          const loot = await api.getItemLoot(id, page)
          const lootedFrom = await api.getItemLootedFrom(id, page)
          const disenchanting = await api.getItemDisenchanting(id, page)
          const disenchantedFrom = await api.getItemDisenchantedFrom(id, page)
          const reagentFor = await api.getItemReagentFor(id, page)
          const createdBy = await api.getItemCreatedBy(id, page)
          const toolFor = await api.getItemToolFor(id, page)
          const pickpocketedBy = await api.getItemPickpocketedBy(id, page)
          const skinnedBy = await api.getItemSkinnedBy(id, page)
          const required = await api.getItemRequiredForQuests(id, page)
          const requiredSource = await api.getItemRequiredSourceForQuests(id, page)
          const rewardFromQuests = await api.getItemRewardFromQuests(id, page)
          const soldBy = await api.getItemSoldBy(id, page)
          const containedIn = await api.getItemContainedIn(id, page)
          const fishedFrom = await api.getItemFishedFrom(id, page)
          const fishedInZone = await api.getItemFishedInZone(id, page)
          const unlocks = await api.getItemUnlocks(id, page)
          const races = await api.getItemRaces(id)
          const classes = await api.getItemClasses(id)

          return commit('setItem', {
            id,
            item: {
              ...item,
              reagentFor: reagentFor,
              canBePlacedIn,
              canContain,
              toolFor: toolFor,
              sameModelAs: sameModelAs,
              createdBy: createdBy,
              droppedBy: droppedBy,
              loot: loot,
              lootedFrom: lootedFrom,
              disenchanting: disenchanting,
              disenchantedFrom: disenchantedFrom,
              skinnedBy: skinnedBy,
              pickpocketedBy: pickpocketedBy,
              containedIn: containedIn,
              fishedFrom: fishedFrom,
              fishedInZone: fishedInZone,
              unlocks: unlocks,
              rewardFromQuests: rewardFromQuests,
              requiredForQuests: required,
              requiredSourceForQuests: requiredSource,
              soldBy: soldBy,
              races: races,
              classes: classes
            }
          })
        })
      },
      async fetchEvent ({ commit }, { id, page }) {
        api.getEvent(id).then(async event => {
          if (!event) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const [npcs, quests, objects] = await Promise.all([
            api.getEventNpcs(id, page),
            api.getEventQuests(id, page),
            api.getEventObjects(id, page)
          ])
          return commit('setEvent', { id, event: { ...event, npcs, quests, objects } })
        })
      },
      async fetchObject ({ commit }, payload) {
        const page = payload.page
        const id = payload.id
        api.getObject(id).then(async (object) => {
          if (!object) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const startsQuests = await api.getObjectStarts(id, page)
          const events = await api.fetchRelatedTableData('object', id, 'events', page)
          const contains = await api.getObjectContains(id, page)
          const sameModelAs = await api.getObjectSameModelAs(id, page)
          const endsQuests = await api.getObjectEnds(id, page)
          const fishing = await api.getObjectFishing(id, page)
          const required = await api.getObjectRequiredForQuests(id, page)
          return commit('setObject', {
            id,
            object: {
              ...object,
              fishing: fishing,
              events: events,
              sameModelAs: sameModelAs,
              starts: startsQuests,
              contains: contains,
              ends: endsQuests,
              requiredForQuests: required
            }
          })
        })
      },
      async fetchZone ({ commit }, payload) {
        const page = payload.page
        const id = payload.id
        return api.getZone(id).then(async (zone) => {
          if (!zone) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const subLocations = await api.getZoneSubLocations(id, page)
          const zoneNpcs = await api.getZoneNpcs(id, page)
          const zoneQuestRewards = await api.getZoneQuestRewards(id, page)
          const zoneObjects = await api.getZoneObjects(id, page)
          const zoneQuests = await api.getZoneQuests(id, page)
          const zoneFishing = await api.getZoneFishing(id, page)
          return commit('setZone', {
            id,
            zone: {
              ...zone,
              questRewards: zoneQuestRewards,
              subLocations: subLocations,
              npcs: zoneNpcs,
              fishing: zoneFishing,
              objects: zoneObjects,
              quests: zoneQuests
            }
          })
        }).catch(e => console.error(e))
      },
      async fetchFaction ({ commit }, payload) {
        const page = payload.page
        const id = payload.id

        return api.getFaction(id).then(async (faction) => {
          if (!faction) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const factionNpcs = await api.getFactionNpcs(id, page)
          const factionQuests = await api.getFactionQuests(id, page)
          const factionItems = await api.getFactionItems(id, page)

          return commit('setFaction', {
            id,
            faction: {
              ...faction,
              items: factionItems,
              npcs: factionNpcs,
              quests: factionQuests
            }
          })
        }).catch(e => console.error(e))
      },
      async fetchRace ({ commit }, payload) {
        const page = payload.page
        const id = payload.id

        api.getRace(id).then(async (race) => {
          if (!race) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }

          const quests = await api.getRaceQuests(id, page)
          const classes = await api.getRaceClasses(id, page)
          return commit('setRace', {
            id,
            race: {
              ...race,
              quests: quests,
              classes: classes
            }
          })
        })
      },
      async fetchClassData ({ commit }, payload) {
        const page = payload.page
        const id = payload.id
        api.getClassData(id).then(async (classData) => {
          if (!classData) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const quests = await api.getClassQuests(id, page)
          const races = await api.getClassRaces(id, page)
          return commit('setClassData', {
            id,
            classData: {
              ...classData,
              quests: quests,
              races: races
            }
          })
        })
      },
      async fetchQuest ({ commit }, payload) {
        const id = payload.id

        return api.getQuest(id).then(async (quest) => {
          if (!quest) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const events = await api.fetchRelatedTableData('quest', id, 'events', payload.page)
          if (quest && quest.requiredItems) {
            for (const item of quest.requiredItems) {
              item.droppedBy = await api.getItemDroppedBy(item.id)
            }
          }
          return commit('setQuest', {
            id,
            quest: {
              ...quest,
              events
            }
          })
        })
      },
      fetchNews ({ commit }, id) {
        return api.getNews(id).then(news => commit('setNews', { id, news }))
      },
      updateRelatedTableData ({ commit }, { type, id, tableName, apiFunctionName, newPage }) {
        // Fetch the data for the related table
        commit('setLoading')
        api.fetchRelatedTableData(type, id, apiFunctionName, newPage)
          .then(data => {
            // Commit a mutation to update the related data in the store
            commit('setRelatedTableData', { type, id, tableName, data });
            commit('clearLoading')
          })
      },
      async fetchSpell ({ commit }, payload) {
        const page = payload.page
        const id = payload.id

        return api.getSpell(id).then(async (spell) => {
          if (!spell) {
            router.push({ path: '/not-found' })
            router.go()
            return
          }
          const races = await api.getSpellRaces(id, page)
          const classes = await api.getSpellClasses(id, page)
          const castedBy = await api.getSpellCastedBy(id, page)
          const taughtBy = await api.getSpellTaughtBy(id, page)
          const usedBy = await api.getSpellUsedBy(id, page)
          const ranks = await api.getSpellRanks(id, page)
          const usedByItem = await api.getSpellUsedByItem(id, page)
          return commit('setSpell', {
            id,
            spell: {
              ...spell,
              races: races,
              classes: classes,
              usedByItem: usedByItem,
              castedBy: castedBy,
              usedBy: usedBy,
              taughtBy: taughtBy,
              ranks: ranks
            }
          })
        })
      },
      async [npcTypes.actions.fetchNpc] ({ commit }, { id, page }) {
        try {
          api.getNPC(id, page).then(async (npc) => {
            if (!npc) {
              router.push({ path: '/not-found' })
              router.go()
              return
            }
            if (npc?.id > 0 && npc.id !== parseInt(id)) {
              router.push({ path: `/npc/${npc.id}` })
              router.go()
            }
            commit(npcTypes.mutations.setSelectedNpcId, { id })

            const parallel = []
            parallel.push(api.getNPCStartsQuests(id, page))
            parallel.push(api.fetchRelatedTableData('npc', id, 'events', page))
            parallel.push(api.getNPCEndsQuests(id, page))
            parallel.push(api.getNPCSells(id, page))
            parallel.push(api.getNPCDrops(id, page))
            parallel.push(api.getNPCPickpocketing(id, page))
            parallel.push(api.getNPCSkinning(id, page))
            parallel.push(api.getNPCSpells(id, page))
            parallel.push(api.getNPCTeaches(id, page))
            parallel.push(api.getNPCObjectiveOf(id, page))

            const [starts, events, ends, sells, drops, pickpocketing, skinning, spells, trains, objectiveOf] = await Promise.all(parallel)

            commit(npcTypes.mutations.setSelectedNpcId, { id })
            npc.startsQuests = starts
            npc.endsQuests = ends
            npc.events = events
            npc.sells = sells
            npc.drops = drops
            npc.skinning = skinning
            npc.pickpocketing = pickpocketing
            npc.spells = spells
            npc.trains = trains
            npc.objectiveOf = objectiveOf
            commit(npcTypes.mutations.setNpc, { id, npc })
          })
        } catch (e) {
          console.error('Error getting NPCs', e)
        }
      },
      [npcTypes.actions.fetchNpcTypes] ({ commit }) {
        commit('fetchTypes', 'npc')
      },
      async fetchTableData ({ commit, state, getters }, url) {
        commit('setLoading')

        try {
          const res = await api.execute('get', url)
          const { items, ...pageInfo } = res

          if (state.route.name === 'npcs') {
            items.forEach(item => {
              item.thumbnail = `https://cdn.wowclassicdb.com/npcs/${item.id}.png`
            })
          }

          commit('clearLoading')
          commit('setTableData', { tableData: items, pageInfo })
        } catch (e) {
          console.error('Error setting table data', e)
          commit('clearLoading')
        }
      },
      handleFilterChange ({ state, commit }, filters) {
        commit('clearTooltip')

        if (JSON.stringify(filters) !== JSON.stringify(BASE_FILTERS)) {
          commit('clearTableData')
        }

        return commit('changeFilters', filters, { root: true })
      }
    },
    mutations: {
      [npcTypes.mutations.setNpc] (state, { id, npc }) {
        Vue.set(state.npcs, id, npc)
      },
      [npcTypes.mutations.resetSelectedNpc] (state) {
        state.selectedNpcId = undefined
      },
      [npcTypes.mutations.setSelectedNpcId] (state, { id }) {
        state.selectedNpcId = id
      },
      setTooltip (state, { id, type, tooltip }) {
        const tooltipObj = {}
        tooltipObj[id] = tooltip
        Vue.set(state.tooltips, type, tooltipObj)
      },
      setRelatedTableData (state, { type, id, tableName, data }) {
        // Get the type, e.g. 'item', 'spell', 'quest', etc.
        // Pluralise it, e.g. 'items', 'spells', 'quests', etc.
        // Get the id of the item, then overwrite the related table data with the new data
        Vue.set(state[pluralize(type)][id], tableName, data)
      },
      setCurrentTooltip (state, data) {
        Vue.set(state.tooltips, 'current', data)
      },
      clearTooltip (state, data) {
        Vue.set(state.tooltips, 'current', {})
      },
      clearRelatedTable (state) {
        Vue.set(state.ui, 'filterRelatedTable', '')
      },
      filterRelatedTable (state, { name }) {
        Vue.set(state.ui, 'filterRelatedTable', name)
      },
      setTypes (state, { type, types }) {
        Vue.set(state.types, type, types)
      },
      setItem (state, { id, item }) {
        Vue.set(state.items, id, item)
      },
      setItemSet (state, { id, itemSet }) {
        Vue.set(state.itemSets, id, itemSet)
      },
      setObject (state, { id, object }) {
        Vue.set(state.objects, id, object)
      },
      setZone (state, { id, zone }) {
        Vue.set(state.zones, id, zone)
      },
      setFaction (state, { id, faction }) {
        Vue.set(state.factions, id, faction)
      },
      setEvent (state, { id, event }) {
        Vue.set(state.events, id, event)
      },
      setRace (state, { id, race }) {
        Vue.set(state.races, id, race)
      },
      setClassData (state, { id, classData }) {
        Vue.set(state.classes, id, classData)
      },
      setQuest (state, { id, quest }) {
        Vue.set(state.quests, id, quest)
      },
      setNews (state, { id, news }) {
        Vue.set(state.news, id, news)
      },
      setSpell (state, { id, spell }) {
        Vue.set(state.spells, id, spell)
      },
      toggleMobileSideNav (state) {
        Vue.set(state.ui, 'isMobileSideNavOpen', !state.ui.isMobileSideNavOpen)
      },
      setTableData (state, { tableData, pageInfo }) {
        const { currentPage } = pageInfo
        const data = {
          ...state.tableData,
          [state.route.name]: {
            ...state.tableData[state.route.name],
            [currentPage]: tableData
          }
        }

        const newTableData = data

        Vue.set(state, 'tableData', newTableData)
        Vue.set(state, 'pageInfo', pageInfo)
      },
      setPageInfo (state, pageInfo) {
        const info = { ...state.pageInfo, ...pageInfo }
        // console.log(info)
        Vue.set(state, 'pageInfo', info)
      },
      setRelatedTableIndex (state, index) {
        Vue.set(state, 'selectedRelatedTableIndex', index)
      },
      clearTableData (state, data) {
        Vue.set(state, 'tableData', data || BASE_TABLE_DATA)
        Vue.set(state, 'pageInfo', {})
      },
      changeFilters (state, filters) {
        Vue.set(state, 'filters', {
          ...BASE_FILTERS,
          ...state.filters,
          ...filters
        })
      },
      clearFilters (state) {
        Vue.set(state, 'filters', { ...BASE_FILTERS })
      },
      setLoading (state) {
        Vue.set(state, 'loading', true)
      },
      clearLoading (state) {
        Vue.set(state, 'loading', false)
      },
      setHighlightedId (state, id) {
        state.highlightedId = id
      }
    },
    getters: {
      [npcTypes.getters.npcs]: (state) => state.npcs,
      [npcTypes.getters.selectedNpc]: (state) => state.npcs[state.selectedNpcId],
      [npcTypes.getters.primarySpawnLocation]: (_, getters) => getters.selectedNpc?.locations[0],
      baseTypes (state) {
        return baseTypes(state.types, state.route.name)
      },
      shouldShowTooltip (state) {
        return Object.entries(state.tooltips.current).length > 0
      },
      subTypesForRoute (state, getters) {
        return getSubTypesForRoute(getters.baseTypes, state.route.path) || []
      },
      titleForRoute (state, getters) {
        return getMetaTitleForRoute(getters.baseTypes, state.route.path) || ''
      },
      tableDataForPage (state) {
        const routeName = state.route.name
        const page = state.pageInfo.currentPage || 1

        const items =
          (state.tableData[routeName] && state.tableData[routeName][page]) ?? []

        return items
      },
      meta (_, getters) {
        return { title: getters.metaTitle }
      },
      metaTitle (state, getters) {
        const test = getMetaForRoute(getters.baseTypes, state.route.path)
        const meta = test.join(' - ')
        const baseRouteName = prettifyRouteName(state.route.name)
        if (state.route.params.first) { return meta } else { return baseRouteName }
      }
    },
    modules: {
      location,
      itemSet,
      listView
    }
  })
}
