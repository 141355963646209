import React, { Fragment } from 'react';
import LabelSelect from '../../../components/site/labelselect';
import AttributeHeader from '../attributes/attributeHeader';
const disAllowedItemTypes = [
  'finger1',
  'finger2',
  'trinket1',
  'trinket2',
  'neck',
  'back'
];

const itemOptions = props => {
  return (
    <Fragment>
      <LabelSelect
        selectClass="item-rarity-label"
        key="rarity"
        name="Rarity"
        value={props.itemRaritiesValue}
        data={props.itemRaritiesData}
        handleSelectChange={props.handleItemRaritiesChange}
        multiple={true}
      />
      {!disAllowedItemTypes.includes(props.itemslotname) ? (
        <LabelSelect
          selectClass="item-type-label"
          key={props.itemTypesName + ' type'}
          name={props.itemTypesName + ' Type'}
          value={props.itemTypesValue}
          data={props.itemTypesData}
          handleSelectChange={props.handleItemTypesChange}
          multiple={true}
        />
      ) : null}
      <div>
        <h6>Search</h6>
        <input
          className="input-field w-1/2"
          onChange={props.handleItemSearch}
          type="text"
          ref={props.searchInputRef}
        />
      </div>
      <div className="col s12 m4 l2 input-field">
        <h6>{props.activeGroup} Stats</h6>
        <AttributeHeader
          handleAttributeClick={props.handleAttributeClick}
          attributesGroup={props.attributesGroup}
          activeGroup={props.activeGroup}
        />
      </div>
    </Fragment>
  );
};

export default itemOptions;
