import Atlas from '@/views/Atlas'
import GearPlanner from '@/views/GearPlanner'
import TooltipPageVue from '@/views/TooltipPage.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFoundPageVue from '../../../../lib/src/views/NotFoundPage.vue'
import Class from '../views/Class.vue'
import Classes from '../views/Classes.vue'
import Event from '../views/Event.vue'
import Events from '../views/Events.vue'
import Faction from '../views/Faction.vue'
import Factions from '../views/Factions.vue'
import Home from '../views/Home.vue'
import Item from '../views/Item.vue'
import ItemSet from '../views/ItemSet.vue'
import ItemSets from '../views/ItemSets.vue'
import Items from '../views/Items.vue'
import NPC from '../views/NPC.vue'
import NPCs from '../views/NPCs.vue'
import Object from '../views/Object.vue'
import Objects from '../views/Objects.vue'
import Quest from '../views/Quest.vue'
import Quests from '../views/Quests.vue'
import Race from '../views/Race.vue'
import Races from '../views/Races.vue'
import SearchPage from '../views/SearchPage.vue'
import Spell from '../views/Spell.vue'
import Spells from '../views/Spells.vue'
import Zone from '../views/Zone.vue'
import Zones from '../views/Zones.vue'

Vue.use(VueRouter)

export const createRouter = () => {
  const routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/events/:first?',
      name: 'events',
      component: Events
    },
    {
      path: '/event/:id?',
      name: 'event',
      component: Event
    },
    {
      path: '/tools/gearplanner/:id?',
      name: 'tools-gearplanner',
      component: GearPlanner
    },
    {
      path: '/tools/atlas',
      name: 'tools-atlas',
      component: Atlas
    },
    {
      path: '/item/:id',
      name: 'item',
      component: Item
      // beforeEnter: async (to, from, next) => {
      //  const item = await api.getItem(to.params.id)
      //  item === null ? next({ name: 'not-found' }) : next()
      // }
    },
    {
      path: '/item-set/:id',
      name: 'item-set',
      component: ItemSet
    },
    {
      path: '/quest/:id',
      name: 'quest',
      component: Quest
    },
    {
      path: '/npc/:id',
      name: 'npc',
      component: NPC
    },
    {
      path: '/zone/:id',
      name: 'zone',
      component: Zone
    },
    {
      path: '/npcs/:first?/:second?',
      name: 'npcs',
      component: NPCs
    },
    {
      path: '/zones/:first?/:second?',
      name: 'zones',
      component: Zones
    },
    {
      path: '/spell/:id',
      name: 'spell',
      component: Spell
    },
    {
      path: '/spells/:first?/:second?/:third?',
      name: 'spells',
      component: Spells
    },
    {
      path: '/object/:id',
      name: 'object',
      component: Object
    },
    {
      path: '/objects/:first?',
      name: 'objects',
      component: Objects
    },
    {
      path: '/items/:first?/:second?/:third?',
      name: 'items',
      component: Items
    },
    {
      path: '/search/:first?/:second?/:third?',
      name: 'Search',
      component: SearchPage
    },
    {
      path: '/item-sets/:first?',
      name: 'item-sets',
      component: ItemSets
    },
    {
      path: '/quests/:first?/:second?',
      name: 'quests',
      component: Quests
    },
    {
      path: '/factions/:first?',
      name: 'factions',
      component: Factions
    },
    {
      path: '/faction/:id',
      name: 'faction',
      component: Faction
    },
    {
      path: '/races/:first?',
      name: 'races',
      component: Races
    },
    {
      path: '/race/:id',
      name: 'race',
      component: Race
    },
    {
      path: '/classes/:first?',
      name: 'classes',
      component: Classes
    },
    {
      path: '/class/:id',
      name: 'class',
      component: Class
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundPageVue
      // beforeEnter: async (to, from, next) => {
      //  console.log(to, from, next)
      // }
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '../../../../lib/src/views/ContactUs.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () =>
        import(/* webpackChunkName: "register" */ '../views/Register.vue')
    },
    {
      path: '/forgot',
      name: 'forgot-password',
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'
        )
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: () =>
        import(/* webpackChunkName: "cookies" */ '../views/CookiePolicy.vue')
    },
    {
      path: '/terms',
      name: 'terms-and-conditions',
      component: () =>
        import(
          /* webpackChunkName: "terms-and-conditions" */ '../views/TermsAndConditions.vue'
        )
    },
    {
      path: '/privacy',
      name: 'privacy-policy',
      component: () =>
        import(
          /* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'
        )
    },
    {
      path: '/tools/tooltip',
      name: 'tools-tooltip',
      component: TooltipPageVue
    }
  ]
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })
}
