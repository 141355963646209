import React from 'react';

class StatusBar extends React.Component {
  render () {
    return <div className="generate-key-status">{statusBar(this.props)}</div>;
  }
}

const statusBar = props => {
  let statusBar = null;
  switch (props.warnStatus) {
    case 'notEqual': {
      statusBar = (
        <div className="error row">
          <i className="material-icons left">warning</i>
          <div className="left">
            Applied key has wrong class or spec. Your input key requires:{' '}
            {props.classAndSpec.toLowerCase()}
          </div>
        </div>
      );
      break;
    }
    case 'wrongData': {
      statusBar = (
        <div className="error row">
          <i className="material-icons left">warning</i>
          <div className="left">"{props.fieldValue}" is an invalid key.</div>
        </div>
      );
      break;
    }
    case 'success': {
      statusBar = (
        <div className="success row">
          <i className="material-icons left">check_circle</i>
          <div className="left">Weights have been successfuly applied!</div>
        </div>
      );
      break;
    }
    default:
      statusBar = null;
  }

  return statusBar;
};

export default StatusBar;
