import React from 'react';

const attributeRow = props => {
  const attValue = props.data ? props.data[props.attribute] : 0;
  return (
    <div className="attribute-row attribute">
      <div>
        {getReadableAttributeName(props.attributeVerbage, props.attribute)}
      </div>
      <div>
        {attValue}
        {checkAttributeIsPercentage(
          props.attributeVerbage.attributeExtra,
          props.attribute
        )}
      </div>
    </div>
  );
};

// Returns readable output name value for attributes
const getReadableAttributeName = (verbage, attribute) => {
  if (verbage[attribute]) {
    return verbage[attribute];
  } else {
    return attribute;
  }
};

// Returns percent if attribute is percentage
const checkAttributeIsPercentage = (attributeExtra, attribute) => {
  if (attributeExtra[attribute]) {
    return attributeExtra[attribute];
  } else {

  }
};

export default attributeRow;
