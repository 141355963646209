<template>
  <div class="px-2.5 py-2.5 text-lg font-bold dropdown ">
    <router-link
      :to="`/${link(name)}`"
      class="label z-20"
    >
      <span>{{ name }}</span>
      <img
        v-if="dropdownItems.length > 0"
        src="@/assets/img/icons/arrow_dropdown.svg"
        alt="Dropdown Arrow"
        class="dropdown-icon"
      >
    </router-link>
    <div
      v-if="dropdownItems.length > 0"
      class="z-50 absolute p-5 border rounded-md shadow-lg dropdown-container border-primary border-opacity-20 ring-1 ring-black ring-opacity-5 focus:outline-none bg-bg-500"
    >
      <router-link
        v-for="(item, i) in dropdownItems"
        :id="removeWhiteSpaceAndApostrophes(name + '-' + item.name)"
        :key="'item' + i"
        :to="`/${link(name)}/${link(item.name)}`"
        class="font-normal dropdown-item"
      >
        <span>{{ item.name }}</span>
        <img
          v-if="item.children && item.children.length > 0"
          src="@/assets/img/icons/arrow_dropdown.svg"
          alt="Dropdown Arrow"
          class="dropdown-icon"
        >
        <div
          v-if="item.children && item.children.length > 0"
          class="p-5 border rounded-md shadow-lg dropdown-container border-primary border-opacity-20 ring-1 ring-black ring-opacity-5 focus:outline-none bg-bg-500"
        >
          <router-link
            v-for="(second, j) in (item.children)"
            :id="removeWhiteSpaceAndApostrophes(name + '-' + item.name + '-' + second.name)"
            :key="'second' + j"
            :to="`/${link(name)}/${link(item.name)}/${link(second.name)}`"
            class="dropdown-item"
          >
            <span>{{ second.name }}</span>
            <img
              v-if="second.children"
              src="@/assets/img/icons/arrow_dropdown.svg"
              alt="Dropdown Arrow"
              class="dropdown-icon"
            >
            <div
              v-if="second.children"
              class="p-5 border rounded-md shadow-lg dropdown-container border-primary border-opacity-20 ring-1 ring-black ring-opacity-5 focus:outline-none bg-bg-500"
            >
              <router-link
                v-for="(third, k) in second.children"
                :id="removeWhiteSpaceAndApostrophes(name + '-' + item.name + '-' + second.name + '-' + third.name)"
                :key="'third' + k"
                :to="`/${link(name)}/${link(item.name)}/${link(second.name)}/${link(third.name)}`"
                class="dropdown-item"
              >
                <span>{{ third.name }}</span>
              </router-link>
            </div>
          </router-link>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    name: String,
    dropdownItems: Array
  },
  methods: {
    link (label) {
      return this.removeWhiteSpaceAndApostrophes(label).toLowerCase()
    },
    removeWhiteSpaceAndApostrophes (value) {
      if (value.length > 0) {
        const whiteSpaceRemoved = value.replace(/\s/g, '-')
        // const ampersandsRemoved = whiteSpaceRemoved.replace(/-&-/g, '-')
        const apostrophesRemoved = whiteSpaceRemoved.replace(/'/g, whiteSpaceRemoved.includes('Onyxia') || whiteSpaceRemoved.includes('Goro') ? '' : '-')
        return apostrophesRemoved
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
@import '~@/scss/mixins';

.dropdown {
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    > .label {
      color: $color-primary;

      .dropdown-icon {
        transform: rotate(-90deg);
      }
    }

    > .dropdown-container {
      display: flex;
    }
  }

  .label {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    top: 100%;
    left: 0;
    position: absolute;
    display: none;
    flex-direction: column;

    .dropdown-item {
      color: white;
      position: relative;
      padding: 5px 10px;
      display: flex;

      .dropdown-container {
        top: 0;
        left: 100%;
      }

      &:hover {
        > span {
          color: $color-primary;
        }

        > .dropdown-icon {
          transform: rotate(-90deg);
        }

        > .dropdown-container {
          display: flex;
        }
      }
    }
  }
}
</style>
