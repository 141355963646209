<template>
  <client-only placeholder="Loading...">
    <TemplateSinglePage>
      <div class="flex mx-auto max-w-7xl md:justify-between md:flex-row">
        <div>
          <h2 class="mb-2 text-3xl font-extrabold text-primary">
            Tooltip
          </h2>
          <p class="text-lg leading-7 md:max-w-lg">
            Tooltip script for WoW Classic DB. Simply embed this into your page, and every link will be able to display information from WoW Classic DB through their tooltips.
          </p>
          Example -> <a
            class="pt-2 ml-2 inline-flex text-primary"
            href="https://wowclassicdb.com/item/19019"
            target="_blank"
            rel="item=19019"
          >Test link</a>
        </div>
        <div>
          <div
            class="p-3.5 m-0 text-base rounded-sm bg-table-1 text-white max-w-sm"
          >
            <div
              v-if="tooltip"
              class="w-full"
              v-html="this.tooltip.tooltip"
            />
          </div>
        </div>
      </div>
      <div class="mx-auto mt-10 space-y-4 max-w-7xl">
        <h2 class="mb-2 text-3xl font-extrabold text-primary">
          Usage
        </h2>
        <p class="text-lg">
          Add this code to your <code class="p-1 mx-1 rounded-lg bg-table-text ">&lt;head></code> section:
        </p>

        <vue-code-highlight
          class="mx-auto text-lg"
          language="javascript"
        >
          <pre>
&lt;link rel="stylesheet" href="https://wowclassicdb.com/tooltip.min.css">
&lt;script>const wowdbTooltipConfig = { colorLinks: true, renameLinks: true }&lt;/script>
&lt;script src="https://wowclassicdb.com/tooltip.js">&lt;/script></pre>
        </vue-code-highlight>

        <p class="text-lg">
          Every link that points to <a
            class="underline text-primary"
            href="https://wowclassicdb.com"
            target="_blank"
          >https://wowclassicdb.com</a> will be transformed and open a tooltip on hover.
        </p>

        <h2 class="text-3xl font-extrabold text-primary">
          Configuration
        </h2>

        <p class="text-lg">
          You can pass options to the tooltip script via a
          <code class="p-1 mx-1 rounded-lg bg-table-text ">
            wowdbTooltipConfig
          </code> variable.
          Possible options and their defaults are:
        </p>

        <vue-code-highlight
          class="mx-auto text-lg"
          language="javascript"
        >
          <pre>
{
  renameLinks: true,  // Renames all WoW Classic DB links
  colorLinks: true,   // Colors all WoW Classic DB links
  iconizeLinks: true, // Adds an icon next to all WoW Classic DB links
  iconSize: 'small',  // Size of the icons
  lazyLoading: true,  // Enables lazy loading - disables all link formatting!
}</pre>
        </vue-code-highlight>

        <p class="text-lg">
          It's possible to overwrite the global config for an individual link by defining a data attribute <code class="p-1 mx-1 rounded-lg bg-table-text ">data-wowdb-[configKey]</code> like this:
        </p>
        <vue-code-highlight
          class="mx-auto text-lg"
          language="html"
        >
          <pre>
&lt;a href="..."
   data-wowdb-rename-link="false"
   data-wowdb-color-link="true"
   data-wowdb-iconize-link="true"
   data-wowdb-icon-size="large"
>
    Link to WoW Classic DB
&lt;/a></pre>
        </vue-code-highlight>

        <h2 class="text-3xl font-extrabold text-primary">
          Styling
        </h2>
        <p class="text-lg">
          Adjusting the style of the tooltips or links to fit your website is very simple. Just overwrite our class tags with your own styling. All class names with description can be found in the <a
            class="underline text-primary"
            href="https://wowclassicdb.com/tooltip.css"
          >tooltip stylesheet</a>.
        </p>
      </div>
    </templatesinglepage>
  </client-only>
</template>
<script>

import TemplateSinglePage from '@/components/TemplateSinglePage.vue'
import ClientOnly from 'vue-client-only'
import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'vue-code-highlight/themes/prism-twilight.css'
export default {
  components: { TemplateSinglePage, VueCodeHighlight, ClientOnly },
  data () {
    return {
      jQueryLoaded: false
    }
  },
  computed: {
    tooltip () {
      return this.$store.state.tooltips.item[19019]
    }
  },
  mounted () {
    this.fetchExampleTooltip()
  },
  metaInfo: {
    script: [
      { src: `${process.env.VUE_APP_BASE_URL}/tooltip.js` }
    ],
    title: 'Tooltip',
    meta: [
      { property: 'og:title', vmid: 'og:title', content: 'Tooltip' },
      { property: 'og:url', vmid: 'og:url', content: `${process.env.VUE_APP_BASE_URL}/tools/tooltip` },
      { property: 'og:description', vmid: 'og:description', content: 'Tooltip script for WoW Classic. Simply embed this into your page, and every link will be able to display information from the WoW Classic DB through their tooltips.' },
      { property: 'description', vmid: 'description', content: 'Tooltip script for WoW Classic. Simply embed this into your page, and every link will be able to display information from the WoW Classic DB through their tooltips.' }
    ]
  },
  methods: {
    fetchExampleTooltip () {
      return Promise.all([
        this.$store.dispatch('fetchTooltip', {
          id: 19019,
          type: 'item'
        })
      ])
    }
  }
}
</script>

<style scoped>
@import 'https://wowclassicdb.com/tooltip.min.css';
</style>
