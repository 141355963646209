<template>
  <TemplateSinglePage :show-loading="!faction">
    <div
      v-if="faction"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <h1 class="mb-2 font-sans text-3xl font-bold text-primary">
        {{ faction.name }}
      </h1>
      <span
        v-if="faction.description"
        class="block leading-relaxed text-primary max-w-sm line-clamp-4 break-words"
      >
        {{ faction.description }}
      </span>
      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'
export default {
  name: 'Faction',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.faction?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.faction?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.faction?.quests, 'quests', '', RelatedTableFilterOption.quests),
        setRelatedTable(this.faction?.npcs, 'npcs', '', RelatedTableFilterOption.npcs),
        setRelatedTable(this.faction?.items, 'items', '', RelatedTableFilterOption.items),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {
        expansion: this.faction.expansion,
        side: this.faction.side
      }
    },
    metaDescription () {
      return `View Faction ${this.faction?.name} from WoW Classic.`
    },
    faction () {
      return this.$store.state.factions[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchFaction().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.faction) this.fetchFaction()
  },
  mounted () { // Client side fetching
    this.fetchFaction()
  },
  methods: {
    fetchFaction () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchFaction', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>
