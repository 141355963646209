import React from 'react';
import M from 'materialize-css';

import Weights from './weights';
import StatusBar from './statusBar';
const classes = require('./../../../data/classes.json');
const specs = require('./../../../data/specs.json');

const calculatedImport = require('../../../data/weights/calculated.json');
const weightNamesData = require('../../../data/weights/names.json');
const weightDescriptions = require('../../../data/weights/descriptions.json');

class WeightsModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentOpenWindow: null,
      currentResetSpec: null,
      keyGenerated: false,
      fieldValue: '',
      warnStatus: '',
      classAndSpec: '',
      generatedKey: '',
      tooltips: []
    };
  }

  componentDidMount () {
    const currentOpenWindow =
      this.props.weightsData.REGULAR.CHARACTER_KEY === null
        ? 'TANK'
        : 'REGULAR';
    this.setState({
      currentOpenWindow
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.generateKeyStatus !== this.props.generateKeyStatus) {
      this.setState({
        keyGenerated: false
      });
    }

    if (prevProps.currentSpec !== this.props.currentSpec) {
      let currentOpenWindow = '';
      for (const key in this.props.weightsData) {
        if (
          this.props.weightsData[key].CHARACTER_KEY &&
          this.props.weightsData[key].CHARACTER_KEY !== null
        ) {
          currentOpenWindow = this.props.weightsData[key].CHARACTER_KEY.split(
            ', '
          )[2];
          break;
        }
      }
      this.setState({
        currentOpenWindow
      });
    }
    if (prevProps.activeGroup !== this.props.activeGroup) {
      const tabs = document.querySelectorAll('.tabs');
      M.Tabs.init(tabs);
    }

    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }

  render () {
    let weightsData = this.props.weightsData;
    const headers = ['base', 'tanking', 'melee', 'weapons', 'caster', 'resists'];
    let currentClassName = '';

    for (const classKey in classes.map) {
      if (classes.data[classKey].id === this.props.class) {
        currentClassName = classes.data[classKey].name;
      }
    }

    if (this.props.customWeights !== null) {
      for (const key in this.props.customWeights) {
        if (
          key === `${currentClassName.toUpperCase()}, ${this.props.currentSpec}`
        ) {
          weightsData = this.props.customWeights[key];
        }
      }
    }

    const cKey =
      this.state.currentOpenWindow &&
      weightsData[this.state.currentOpenWindow].CHARACTER_KEY
        ? weightsData[this.state.currentOpenWindow].CHARACTER_KEY
        : null;

    let calculatedFields = calculatedImport[cKey] || {};
    for (const key in calculatedImport) {
      if (key.toUpperCase() === cKey) {
        calculatedFields = calculatedImport[key];
      }
    }

    return (
      <div id="weights-modal" className="modal">
        <div className="modal-header">
          <div className="row">
            <div className="left">
              <h4>Weights</h4>
            </div>
            {this.props.weightsData.REGULAR.CHARACTER_KEY === null ? (
              <div className="right">
                <button
                  className={
                    'btn-small ' +
                    (this.state.currentOpenWindow === 'TANK'
                      ? 'active'
                      : 'weight-inactive')
                  }
                  onClick={this.handleClick.bind(this, 'TANK')}
                >
                  Tank Weights
                </button>
                <button
                  className={
                    'btn-small ' +
                    (this.state.currentOpenWindow === 'THREAT'
                      ? 'active'
                      : 'weight-inactive')
                  }
                  onClick={this.handleClick.bind(this, 'THREAT')}
                >
                  Threat Weights
                </button>
              </div>
            ) : null}
          </div>
          <hr />
        </div>
        <div className="modal-content">
          <div className="row">
            <StatusBar
              classAndSpec={this.state.classAndSpec}
              warnStatus={this.state.warnStatus}
              fieldValue={this.state.fieldValue}
            />
          </div>
          <div className="row">
            <div>
              <ul className="tabs">
                {headers.map((header, i) => {
                  return (
                    <li className="tab col s2" key={header + '-' + i}>
                      <a
                        href={'#weights-' + header}
                        className={
                          this.props.activeGroup === header ? 'active' : ''
                        }
                      >
                        <div className="weights-header">{header}</div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {headers.map((type, i) => {
              return (
                <div id={'weights-' + type} key={type + '-data-' + i}>
                  <Weights
                    data={weightNamesData[type]}
                    descriptions={weightDescriptions}
                    weightType={type}
                    weightsData={weightsData}
                    currentOpenWindow={this.state.currentOpenWindow}
                    handleOnChangeWaitsField={
                      this.props.handleOnChangeWaitsField
                    }
                    calculatedFields={calculatedFields}
                    classId={this.props.class}
                    cKey={cKey}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn-small left modal-close">Calculate</button>
          &nbsp;
          <button className="btn-small left" onClick={this.props.resetWeights}>
            Reset
          </button>
          <button className="btn-small right" onClick={this.generateKey}>
            Generate Key
          </button>
          &nbsp;
          <button
            onClick={this.applyWeights}
            className="btn-small right"
            disabled={
              this.state.fieldValue === null ||
              this.state.fieldValue.length <= 3
            }
          >
            Apply Key
          </button>
          &nbsp;
          <input
            placeholder="Input generated key"
            className="weightsKeyInput right"
            id="keyInput"
            value={this.state.fieldValue}
            onChange={e => {
              this.onInputFieldChange(e);
            }}
            onFocus={this.changeFieldValue}
          />
        </div>
      </div>
    );
  }

  generateKey = () => {
    let customWeights = {};
    const importWeights = {};
    // sellect current className
    let currentClassName = '';
    for (const key in classes.map) {
      if (classes.data[key].id === this.props.class) {
        currentClassName = classes.data[key].name;
      }
    }
    // find actual custom weights
    if (this.props.customWeights !== null) {
      for (const key in this.props.customWeights) {
        if (
          key === `${currentClassName.toUpperCase()}, ${this.props.currentSpec}`
        ) {
          customWeights = this.props.customWeights[key];
        }
      }
    }

    // what fields was changed
    if (Object.keys(customWeights).length !== 0) {
      const TANKWEIGHTS = {};
      const THREATWEIGHTS = {};
      const REGULARWEIGHTS = {};
      const types = ['TANK', 'THREAT', 'REGULAR'];
      for (let i = 0; i < types.length; i++) {
        for (const key in customWeights[types[i]].CHARACTER_WEIGHTS) {
          if (
            this.props.weightsData[types[i]].CHARACTER_WEIGHTS[key] !==
              customWeights[types[i]].CHARACTER_WEIGHTS[key] &&
            typeof customWeights[types[i]].CHARACTER_WEIGHTS[key] === 'string'
          ) {
            switch (i) {
              case 0:
                TANKWEIGHTS[key] =
                  customWeights[types[i]].CHARACTER_WEIGHTS[key];

                const name1 =
                  Object.keys(TANKWEIGHTS).length !== 0 ? 'TANK' : null;
                if (name1) {
                  importWeights[name1] = TANKWEIGHTS;
                }
                break;
              case 1:
                THREATWEIGHTS[key] =
                  customWeights[types[i]].CHARACTER_WEIGHTS[key];
                const name2 =
                  Object.keys(THREATWEIGHTS).length !== 0 ? 'THREAT' : null;
                if (name2) {
                  importWeights[name2] = THREATWEIGHTS;
                }
                break;
              case 2:
                REGULARWEIGHTS[key] =
                  customWeights[types[i]].CHARACTER_WEIGHTS[key];
                const name3 =
                  Object.keys(REGULARWEIGHTS).length !== 0 ? 'REGULAR' : null;
                if (name3) {
                  importWeights[name3] = REGULARWEIGHTS;
                }
                break;
              default:
                break;
            }
          }
        }
      }

      // find current CHARACTER_KEY
      let cKey = '';
      for (const weight in customWeights) {
        if (
          customWeights[weight].CHARACTER_KEY &&
          customWeights[weight].CHARACTER_KEY !== null
        ) {
          cKey = customWeights[weight].CHARACTER_KEY;
          break;
        }
      }

      // create line to convert "class(from 0 to 8)+spec(2numbrs)+weighttype+adjustWeights(adjust to this weighttype)"
      let modWeights = '';
      classes.data.map(key => {
        if (key.name.toUpperCase() === cKey.split(', ')[0]) {
          modWeights = modWeights + key.id;
        }
        return null;
      });
      specs.map(key => {
        if (key.name.toUpperCase() === cKey.split(', ')[1]) {
          if ((key.id + '').length === 1) {
            modWeights = modWeights + '0' + key.id;
          } else {
            modWeights = modWeights + key.id;
          }
        }
        return null;
      });
      // create line of numbrs
      const modWeightsNum = {};
      for (const key in importWeights) {
        const numKey = key === 'REGULAR' ? 0 : key === 'TANK' ? 1 : 2;
        modWeightsNum[numKey] = {};
        for (const i in importWeights[key]) {
          const index = this.props.attributeKeys.indexOf(i.toLowerCase());
          modWeightsNum[numKey][+index] = +importWeights[key][i];
        }
      }
      // encrypt and add string to clipboard
      modWeights = modWeights + JSON.stringify(modWeightsNum).replace(/"/g, '');
      const data = this.props.cyptoEncrypt(modWeights);
      if (window.isSecureContext) navigator.clipboard.writeText(data);
      this.setState({
        generatedKey: data,
        keyGenerated: true
      });
      return null;
    }
    this.setState(
      {
        keyGenerated: true
      },
      this.props.toast('Key generated and saved to clipboard')
    );
  };

  // applying weights from string
  applyWeights = () => {
    const str = this.state.fieldValue.trim();
    let value = this.props.cyptoDecrypt(str);

    if (typeof value === 'string' && value.match(/^./) !== null) {
      const heroClass = value.match(/^./)[0];
      value = value.replace(/^./, '');
      if (value.match(/^../) !== null) {
        const spec = value.match(/^../)[0];
        value = value.replace(/^../, '');

        // parse value obj from string
        const reg = /[,{].{1,2}[:]/g;
        const arrOfMatches = value.match(reg);
        function replaceNumb (str) {
          const reg = /\d+/;
          const newStr = str.match(reg)[0];
          return str.replace(reg, '"' + newStr + '"');
        }
        if (arrOfMatches === null) {
          this.setState({
            warnStatus: 'wrongData'
          });
          return null;
        }
        arrOfMatches.forEach(el => {
          value = value.replace(el, replaceNumb(el));
        });

        value = JSON.parse(value);

        if (typeof value === 'object') {
          function isNumber (heroClass) {
            return !isNaN(parseFloat(heroClass)) && !isNaN(heroClass - 0);
          }
          if (!isNumber(heroClass)) {
            this.setState({
              warnStatus: 'wrongData'
            });
            return null;
          } else if (this.props.class !== +heroClass) {
            let nameClass = '';
            classes.data.forEach(el => {
              if (el.id === +heroClass) {
                nameClass = el.name;
              }
            });
            let nameSpec = '';
            specs.forEach(el => {
              if (el.id === +spec) {
                nameSpec = el.name;
              }
            });
            let newNameClass = '';
            const namesSplit = nameClass.split('');
            namesSplit[0] = namesSplit[0].toUpperCase();
            namesSplit.forEach(el => {
              newNameClass = newNameClass + el;
            });
            nameClass = newNameClass;
            const classAndSpec = `${nameClass} ${nameSpec}`;
            this.setState({
              classAndSpec,
              warnStatus: 'notEqual'
            });
            return null;
          } else {
            let name = '';
            specs.forEach(el => {
              if (el.name.toUpperCase() === this.props.currentSpec) {
                name = el.id;
              }
            });
            if (name !== +spec) {
              let nameClass = '';
              classes.data.forEach(el => {
                if (el.id === +heroClass) {
                  nameClass = el.name;
                }
              });
              let nameSpec = '';
              specs.forEach(el => {
                if (el.id === +spec) {
                  nameSpec = el.name;
                }
              });
              let newNameClass = '';
              const namesSplit = nameClass.split('');
              namesSplit[0] = namesSplit[0].toUpperCase();
              namesSplit.forEach(el => {
                newNameClass = newNameClass + el;
              });
              nameClass = newNameClass;
              const classAndSpec = `${nameClass} ${nameSpec}`;
              this.setState({
                classAndSpec,
                warnStatus: 'notEqual'
              });
              return null;
            }
            this.setState(
              {
                warnStatus: 'success'
              },
              () => {
                this.props.adjustWeightsChangeFields(value);
              }
            );
          }
        } else {
          this.setState({
            warnStatus: 'wrongData'
          });
          return null;
        }
      } else {
        this.setState({
          warnStatus: 'wrongData'
        });
        return null;
      }
    } else {
      this.setState({
        warnStatus: 'wrongData'
      });
      return null;
    }
  };

  // change input
  onInputFieldChange = e => {
    this.setState({
      fieldValue: e.currentTarget.value,
      warnStatus: ''
    });
  };

  changeFieldValue = () => {
    this.setState({
      fieldValue: ''
    });
  };

  handleClick = value => {
    this.setState({
      currentOpenWindow: value
    });
  };
}

export default WeightsModal;
