import M from 'materialize-css';
import '../../../css/items.scss';

import React, { Component, Fragment } from 'react';
import ItemToolTip from '../itemtooltip.jsx';

const dbUrl = 'https://wowclassicdb.com/item/';
const mediaUrl = 'http://media.blizzard.com/wow/icons/56/';
const itemImagesImport = require('../../../data/itemNamesMap.json');
const classNameSourceLink = 'itemlistSourceLink';
const skillTypes = require('../../../data/additionalData/skillTypes.json');
const weaponTypes = require('../../../data/weights/classWeaponTypes.json');
const specProc = require('../../../data/additionalData/specProc.json');
const classesImport = require('../../../data/classes.json');

class ItemList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      names: {},
      location: {},
      newMap: new Map(),
      filterName: '',
      filterParams: false,
      sellectedColumn: null,
      itemValue: [],
      item: null,
      specProc: '',
      weightsBreakDown: {}
    };
    this.scrollerRef = React.createRef();
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.specChanged !== this.props.specChanged ||
      prevProps.finalFilter !== this.props.finalFilter
    ) {
      this.handleClick();
    }

    if (
      !!this.props.slotName &&
      this.props.currentItems[this.props.slotName] !== this.state.item
    ) {
      const item = this.props.currentItems[this.props.slotName];
      let itemValue = [];
      if (item) {
        const itemConvertStat = {};
        const itemStat = { ...item[12] };
        for (const key in itemStat) {
          itemConvertStat[this.props.attributeData.keys[key]] = itemStat[key];
        }
        const itemMap = new Map();
        itemMap.set(item, itemConvertStat);
        const itemValues = this.createWeightsValues(itemMap);
        itemValue = itemValues.weights;
        this.setState({
          item,
          itemValue
        });
      }
    }
    if (
      !!this.props.slotName &&
      prevProps.currentSpec !== this.props.currentSpec
    ) {
      const item = this.props.currentItems[this.props.slotName];
      let itemValue = [];
      if (item) {
        const itemConvertStat = this.getItemConvertStat(item);
        const itemMap = new Map();
        itemMap.set(item, itemConvertStat);
        const itemValues = this.createWeightsValues(itemMap);
        itemValue = itemValues.weights;
        this.setState({
          item,
          itemValue
        });
      }
    }
    if (prevProps.slotName !== this.props.slotName) {
      if (this.scrollerRef.current) {
        this.scrollerRef.current.scrollTop = 0;
      }
      this.setState({
        names: false,
        location: false
      });
    }

    const dropdowns = document.querySelectorAll('.dropdown-sort');
    M.Dropdown.init(dropdowns);
    if (prevProps !== this.props) {
      setTimeout(() => { // Replacement for $nextTick
        $dbTooltips.refreshLinks();
      }, 1)
    }
  }

  render () {
    let activeAttributes = this.props.groupAttributesData[
      this.props.activeGroup
    ];

    if (this.props.activeGroup === 'base') {
      activeAttributes = [
        'armor',
        'stamina',
        'defense',
        'dodge',
        'parry',
        'block_pct',
        'block_value_total',
        'proc'
      ];
    }
    activeAttributes = activeAttributes.filter(e => e !== 'mana');

    if (Object.keys(this.props.filteredItems).length === 0) {
      let itemMsg = 'Select inventory Slot';
      if (this.props.slotName) {
        itemMsg = 'No items found';
      }
      return (
        <div className="row">
          <div className="col s12 valign-wrapper">
            <i className="small material-icons left">error</i>
            <div className="left">{itemMsg}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="item-list">
        <div className="scroller" ref={this.scrollerRef}>
          <div className="table-responsive">
            <table>
              {this.createTable(
                [...this.state.newMap.values()],
                [...this.state.newMap.keys()],
                activeAttributes
              )}
            </table>
          </div>
        </div>
      </div>
    );
  }

  getItemConvertStat = item => {
    const itemConvertStat = {};
    const itemStat = { ...item[12] };
    for (const key in itemStat) {
      itemConvertStat[this.props.attributeData.keys[key]] = itemStat[key];
    }
    return itemConvertStat;
  };

  requestToWowHead = async (url, itemId) => {
    if (!url) return { [itemId]: null };

    return fetch(`https://www.classic.wowhead.com/tooltip/${url}`, {
      requiredStatus: 'ok'
    })
      .then(response => {
        const jsonResponce = response.json();
        return jsonResponce;
      })
      .then(response => {
        return { [itemId]: response.name };
      });
  };

  createArrayLocations = async items => {
    if (items.length !== 0) {
      const locations = [];
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (Array.isArray(item[0])) {
          item = item[0];
        }
        // let url;
        // if (item[14][1]) {
        //   url = `zone/${item[14][1]}`;
        // } else {
        //   url = false;
        // }
        //        const locationName = await this.requestToWowHead(url, item[0]);
        //      locations.push(locationName);
      }
      return locations;
    }
  };

  createArrayNames = async items => {
    if (items.length !== 0) {
      const names = [];
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (Array.isArray(item[0])) {
          item = item[0];
        }
        // let url;
        // if (item[14][2][0]) {
        //   url = `quest/${item[14][2][0]}`;
        // } else if (item[14][3][0]) {
        //   url = `npc/${item[14][3][0]}`;
        // } else {
        //   url = false;
        // }
        //        const itemName = await this.requestToWowHead(url, item[0]);
        //      names.push(itemName);
      }
      return names;
    }
  };

  createRow = (item, td, index) => {
    if (this.props.handleItemSelect) {
      return (
        <tr
          key={index}
          className={
            !!this.props.currentItems[this.props.slotName] &&
            item[1] === this.props.currentItems[this.props.slotName][1]
              ? 'modal-item modal-item-current'
              : 'modal-item'
          }
          onClick={this.props.handleItemSelect.bind(
            this,
            item,
            this.props.slotName
          )}
        >
          {td}
        </tr>
      );
    }
  };

  // filter items in table
  handleClick = fieldName => {
    let newMap = new Map();
    let filterParams = this.state.filterParams;
    let name = fieldName;
    if (!name) {
      if (
        this.props.currentSpec === 'PROTECTION' ||
        this.props.currentSpec === 'FURY TANK' ||
        this.props.currentSpec === 'BEAR'
      ) {
        name = 'value ';
      } else {
        name = 'value';
      }
    }

    for (let i = 0; i < this.props.filteredItems.length; i++) {
      newMap.set(this.props.filteredItems[i], this.props.attrs[i]);
    }
    let sellectedColumn = null;
    let sortedMap = [...newMap];

    const weightValues = this.createWeightsValues(newMap);
    const weights = weightValues.weights;
    const weightsBreakDown = weightValues.weightsBreakDown;
    const procBehavior = this.setProcBehavior();

    if (weights[0]) {
      sortedMap = sortedMap.map((el, i) => {
        if (Array.isArray(el[0][0])) {
          el[0].forEach((item, index) => {
            item[17] = (
              +weights[0][i][index] + this.getProcBehavior(item, procBehavior)
            ).toFixed(2);
          });
          return el;
        }
        el[0][17] = (
          +weights[0][i] + this.getProcBehavior(el[0], procBehavior)
        ).toFixed(2);
        return el;
      });
    } else {
      sortedMap = sortedMap.map((el, i) => {
        if (Array.isArray(el[0][0])) {
          el[0].forEach((item, index) => {
            item[17] = (
              +weights[1][i][index] + this.getProcBehavior(item, procBehavior)
            ).toFixed(2);
            item[18] = (
              +weights[2][i][index] +
              this.getProcBehavior(item, +procBehavior + 1)
            ).toFixed(2);
          });
          return el;
        }
        el[0][17] = (
          +weights[1][i] + this.getProcBehavior(el[0], procBehavior)
        ).toFixed(2);
        el[0][18] = (
          +weights[2][i] + this.getProcBehavior(el[0], +procBehavior + 1)
        ).toFixed(2);
        return el;
      });
    }
    if (name) {
      sellectedColumn = name;
      // toggle
      if (name === this.state.filterName) {
        filterParams = !filterParams;
      }
      if (name !== this.state.sellectedColumn) {
        filterParams = false;
      }
      if (!fieldName) {
        filterParams = false;
      }
      // filter arrays of random values
      let count = 0;
      for (const [itemName, attribute] of sortedMap) {
        if (Array.isArray(itemName[0])) {
          sortedMap[count] = this.filterMapItems(itemName, attribute, name);
        }
        count++;
      }
      // standard filter

      this.filterFields(sortedMap, name, filterParams);
      newMap = new Map(sortedMap);
    }

    newMap = new Map(sortedMap);
    // this.createArrayNames([...newMap.keys()]).then(response => {
    //   this.setState({
    //     names: response
    //   });
    // });
    // this.createArrayLocations([...newMap.keys()]).then(response => {
    //   this.setState({
    //     location: response
    //   });
    // });

    this.setState({
      sellectedColumn,
      newMap,
      weights,
      weightsBreakDown,
      filterName: name,
      filterParams
    });
  };

  createLocation = (locationId, source, index, itemId) => {
    if (locationId !== 0) {
      const location = this.state.location ? this.state.location : false;
      let currentLocation = '';

      if (location && location[index]) {
        if (location[index][itemId]) {
          currentLocation = location[index][itemId];
        }
      }
      const curentLocationId = `zone/${locationId}`;
      const locationUrl = `https://wowclassicdb.com/${curentLocationId}`;

      return (
        <a
          href={locationUrl}
          className={classNameSourceLink}
          data-wowhead={curentLocationId}
        >
          {currentLocation}
        </a>
      );
    } else {
      return <span className={classNameSourceLink}>{source}</span>;
    }
  };

  getHeaderNames = tableThName => {
    let headerNames = ['name', 'value', 'source'];
    if (this.props.weightsData.REGULAR.CHARACTER_KEY === null) {
      headerNames = ['name', 'tank', 'threat', 'value ', 'source'];
    }
    if (tableThName.includes('proc')) {
      headerNames.push('proc');
    }
    headerNames.push('stats');
    return headerNames;
  };

  createTable = (attrs, items, tableThName = []) => {
    if (attrs.length !== 0 && Object.keys(items).length !== 0) {
      const headerNames = this.getHeaderNames(tableThName);
      const weightsBreakdown = this.state.weightsBreakDown;
      const selectedItemValues = this.state.itemValue;
      const procBehavior = this.setProcBehavior(tableThName);
      const row = [];
      let td = [];

      items.forEach((item, index) => {
        const isArray = Array.isArray(item[0]);
        if (isArray) {
          const index = items.indexOf(item);
          attrs[index] = attrs[index][0];
          item = item[0];
        }
        const weightsBreakdownByItem = weightsBreakdown[item[0]];

        headerNames.forEach(cellName => {
          if (cellName !== 'stats') {
            const cellData = {
              item,
              cellName,
              headerNames,
              selectedItemValues,
              procBehavior,
              isArray,
              weightsBreakdownByItem
            };
            const tableCell = this.buildTableCell(cellData);
            td.push(tableCell);
          } else {
            if (attrs[index]) {
              const stats = [];
              let cnt = 0;
              const statKeys = Object.keys(attrs[index]);
              statKeys.forEach(statKey => {
                if (tableThName.includes(statKey)) {
                  let className = '';
                  if (statKey === this.state.filterName) { className = 'sorted-stat'; }
                  if (cnt > 0) {
                    stats.push(<span key={statKey + ',' + index}>{', '}</span>);
                  }
                  stats.push(
                    <span key={statKey + ' ' + index} className={className}>
                      +{attrs[index][statKey] + ' ' + this.getVerbage(statKey)}
                    </span>
                  );
                  cnt++;
                }
              });
              td.push(
                <td colSpan="2" className="item-attr-td" key={cellName}>
                  {stats}
                </td>
              );
            }
          }
        });
        row.push(this.createRow(item, td, index));
        td = [];
      });

      const sortColumns = tableThName.filter(
        column => !['offhand_skill', 'main_hand_skill'].includes(column)
      );

      return (
        <Fragment>
          <thead className="fixedHeader">
            <tr className="header">
              {headerNames.map(cellName => {
                if (this.state.sellectedColumn === cellName) {
                  return (
                    <th
                      key={cellName}
                      onClick={() => {
                        this.handleClick(cellName);
                      }}
                    >
                      <div
                        onClick={() => {
                          this.handleClick(cellName);
                        }}
                        className="itemlist-column selected-column valign-wrapper"
                      >
                        {this.getVerbage(cellName)}
                        <i className="material-icons">
                          {this.state.filterParams === false
                            ? 'arrow_drop_down'
                            : 'arrow_drop_up'}
                        </i>
                      </div>
                    </th>
                  );
                }
                if (cellName === 'offhand_skill') {
                  return <th key={cellName}></th>;
                }
                return (
                  <th key={cellName}>
                    <div
                      onClick={() => {
                        if (cellName !== 'stats') {
                          this.handleClick(cellName);
                        }
                      }}
                      className="itemlist-column valign-wrapper"
                    >
                      {cellName === 'stats' ? (
                        <div className="valign-wrapper">
                          <div className="left right-pad">
                            {this.props.activeGroup +
                              ' ' +
                              this.getVerbage(cellName)}
                          </div>
                          <div
                            className={
                              'attribute-img left ' + this.props.activeGroup
                            }
                          ></div>{' '}
                        </div>
                      ) : (
                        this.getVerbage(cellName)
                      )}
                    </div>
                  </th>
                );
              })}
              <td className="sort-td">
                <ul
                  id="dropdown-sort"
                  className="dropdown-content right"
                  onClick={this.dropDownSortClick}
                >
                  {sortColumns.map(key => (
                    <li key={'saved-setup-' + key}>
                      <a id={key} href={'#' + key}>
                        {this.getVerbage(key)}
                      </a>
                    </li>
                  ))}
                </ul>
                <a
                  className="btn-small dropdown-sort right"
                  href="#!"
                  data-target="dropdown-sort"
                >
                  Sort
                  {!['value', 'value ', 'threat', 'tank', 'source'].includes(
                    this.state.filterName
                  )
                    ? 'ed by: ' + this.getVerbage(this.state.filterName)
                    : ' Stats'}
                  <i className="material-icons right">menu</i>
                </a>
              </td>
            </tr>
          </thead>
          <tbody className="scrollContent">{row}</tbody>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  buildTableCell = cellData => {
    let tableCell;
    switch (cellData.cellName) {
      // item name & icon
      case 'name':
        if (cellData.isArray) {
          tableCell = this.getNameWithSpread(cellData.cellName, cellData.item);
        } else {
          tableCell = this.getName(cellData.cellName, cellData.item);
        }
        break;

      // source location links
      case 'source':
        tableCell = this.getSourceValue(cellData.item, cellData.name);
        break;

      // tank weight values for prot wars, feral druids, prot paladins
      case 'value':
      case 'tank':
        tableCell = (
          <td key={cellData.cellName}>
            {this.getvalue(
              cellData.item,
              cellData.headerNames,
              cellData.selectedItemValues,
              cellData.weightsBreakdownByItem
            )}
          </td>
        );
        break;

      case 'threat':
        tableCell = (
          <td key={cellData.cellName}>
            {this.getThreatValue(
              cellData.item,
              cellData.headerNames,
              cellData.selectedItemValues,
              cellData.weightsBreakdownByItem
            )}
          </td>
        );
        break;

      case 'value ':
        tableCell = (
          <td key={cellData.cellName}>
            {this.getTotalValue(
              cellData.item,
              cellData.headerNames,
              cellData.selectedItemValues,
              cellData.weightsBreakdownByItem
            )}
          </td>
        );
        break;

      case 'skill':
        tableCell = (
          <td key={cellData.cellName}>{this.getHandValue(cellData.item)}</td>
        );
        break;

      case 'ranged_skill':
        tableCell = (
          <td key={cellData.cellName}>{this.getRangedValue(cellData.item)}</td>
        );
        break;

      case 'proc':
        tableCell = (
          <td key={cellData.cellName}>
            {this.getProcValue(cellData.item, cellData.procBehavior)}
          </td>
        );
        break;

      default:
      // do something
    }
    return tableCell;
  };

  getSourceValue = (item, index) => {
    let id;
    const locationId = item[14][1];
    if (item[14][2][0]) {
      id = `quest=${item[14][2][0]}`;
    } else if (item[14][3][0]) {
      id = `npc=${item[14][3][0]}`;
    }

    const itemLootNames = this.state.names ? this.state.names : false;
    let itemLootname = '';

    if (itemLootNames && itemLootNames[index]) {
      itemLootname = itemLootNames[index][item[0]];
    }
    const itemHref = `https://www.classic.wowhead.com/${id}`;

    const sourceValue = (
      <td
        onMouseOver={() => {
          this.props.updateTooltip(null);
        }}
        key={item[0] + 'source'}
        className="source"
      >
        <div className="source__tooltipe-wrapper">
          {this.createLocation(
            locationId,
            this.props.source[item[14][0]],
            index,
            item[0]
          )}
          <a href={itemHref} className={classNameSourceLink} data-wowhead={id}>
            {itemLootname}
          </a>
        </div>
      </td>
    );

    return sourceValue;
  };

  getvalue = (item, names, selectedItemValues, weightsBreakdownByItem) => {
    if (item[17] || item[0][17]) {
      const value = item[17] && !Array.isArray(item[17]) ? item[17] : item[0][17];
      const itemBreakDown =
        weightsBreakdownByItem && !Array.isArray(weightsBreakdownByItem[0])
          ? weightsBreakdownByItem[0]
          : weightsBreakdownByItem[0][0];
      let difference = null;
      let valueOfnumsClass = null;

      if (selectedItemValues[0] && !names.includes('tank')) {
        difference = (value - +selectedItemValues[0][0]).toFixed(1);
        valueOfnumsClass = +difference > 0 ? 'positiveNum' : 'negativeNum';
        if (+difference === 0) {
          difference = null;
        }
        difference = difference <= 0 ? difference : '+' + difference;
      } else if (selectedItemValues[1] && names.includes('tank')) {
        difference = (value - +selectedItemValues[1][0]).toFixed(1);
        valueOfnumsClass = +difference > 0 ? 'positiveNum' : 'negativeNum';
        if (+difference === 0) {
          difference = null;
        }
        difference = difference <= 0 ? difference : '+' + difference;
      }

      return this.getTableCellOutput(
        value,
        valueOfnumsClass,
        difference,
        item,
        itemBreakDown
      );
    }
  };

  getThreatValue = (
    item,
    names,
    selectedItemValues,
    weightsBreakdownByItem
  ) => {
    if (item[18] || item[0][18]) {
      const value = item[18] && !Array.isArray(item[18]) ? item[18] : item[0][18];
      const itemBreakDown =
        weightsBreakdownByItem && !Array.isArray(weightsBreakdownByItem[1])
          ? weightsBreakdownByItem[1]
          : weightsBreakdownByItem[1][0];
      let difference = null;
      let valueOfnumsClass = null;
      if (selectedItemValues[2] && names.includes('tank')) {
        difference = (value - +selectedItemValues[2][0]).toFixed(1);
        valueOfnumsClass = +difference > 0 ? 'positiveNum' : 'negativeNum';
        if (+difference === 0) {
          difference = null;
        }
        difference = difference <= 0 ? difference : '+' + difference;
      }

      return this.getTableCellOutput(
        value,
        valueOfnumsClass,
        difference,
        item,
        itemBreakDown
      );
    }
  };

  getHandValue = item => {
    let resultStr = '';
    const rangedFilter = [45, 46, 226];
    if (!!item[12] && !Array.isArray(item[12])) {
      if (!!item[12][47] && !rangedFilter.includes(item[12][47])) {
        resultStr = `${item[12][46]} ${skillTypes[item[12][47]]}`;
      }
      if (!!item[12][53] && !rangedFilter.includes(item[12][53])) {
        resultStr = resultStr + ` ${item[12][52]} ${skillTypes[item[12][53]]}`;
      }
      if (!!item[12][55] && !rangedFilter.includes(item[12][55])) {
        resultStr = resultStr + ` ${item[12][54]} ${skillTypes[item[12][55]]}`;
      }
    } else {
      if (!!item[0][12][47] && !rangedFilter.includes(item[0][12][47])) {
        resultStr = `${item[0][12][46]} ${skillTypes[item[0][12][47]]}`;
      }
      if (!!item[0][12][53] && !rangedFilter.includes(item[0][12][53])) {
        resultStr =
          resultStr + ` ${item[0][12][52]} ${skillTypes[item[0][12][53]]}`;
      }
      if (!!item[0][12][55] && !rangedFilter.includes(item[0][12][55])) {
        resultStr =
          resultStr + ` ${item[0][12][54]} ${skillTypes[item[0][12][55]]}`;
      }
    }
    return resultStr;
  };

  getRangedValue = item => {
    let resultStr = '';
    const rangedFilter = [45, 46, 226];
    if (!!item[12] && !Array.isArray(item[12])) {
      if (!!item[12][47] && rangedFilter.includes(item[12][47])) {
        resultStr = `${item[12][46]} ${skillTypes[item[12][47]]}`;
      }
      if (!!item[12][53] && rangedFilter.includes(item[12][53])) {
        resultStr = resultStr + ` ${item[12][52]} ${skillTypes[item[12][53]]}`;
      }
      if (!!item[12][55] && rangedFilter.includes(item[12][55])) {
        resultStr = resultStr + ` ${item[12][54]} ${skillTypes[item[12][55]]}`;
      }
    } else {
      if (!!item[0][12] && !Array.isArray(item[0][12])) {
        if (!!item[0][12][47] && rangedFilter.includes(item[0][12][47])) {
          resultStr = `${item[0][12][46]} ${skillTypes[item[0][12][47]]}`;
        }
        if (!!item[0][12][53] && rangedFilter.includes(item[0][12][53])) {
          resultStr =
            resultStr + ` ${item[0][12][52]} ${skillTypes[item[0][12][53]]}`;
        }
        if (!!item[0][12][55] && rangedFilter.includes(item[0][12][55])) {
          resultStr =
            resultStr + ` ${item[0][12][54]} ${skillTypes[item[0][12][55]]}`;
        }
      }
    }
    return resultStr;
  };

  getTotalValue = (item, names, selectedItemValues, weightsBreakdownByItem) => {
    let threatVal, val;
    threatVal = val = 0;
    const itemBreakDown = this.getGroupedWeightBreakdownTotal(
      weightsBreakdownByItem
    );

    if (item[18] || item[0][18]) {
      threatVal = item[18] && !Array.isArray(item[18]) ? item[18] : item[0][18];
    }
    if (item[17] || item[0][17]) {
      val = item[17] && !Array.isArray(item[17]) ? item[17] : item[0][17];
    }
    const value = (+threatVal + +val).toFixed(1);
    let difference = null;
    let valueOfnumsClass = null;
    if (
      selectedItemValues[1] &&
      selectedItemValues[2] &&
      names.includes('tank')
    ) {
      difference = (
        value -
        (+selectedItemValues[2][0] + +selectedItemValues[1])
      ).toFixed(1);
      valueOfnumsClass = +difference > 0 ? 'positiveNum' : 'negativeNum';
      if (+difference === 0) {
        difference = null;
      }
      difference = difference <= 0 ? difference : '+' + difference;
    }

    return this.getTableCellOutput(
      value,
      valueOfnumsClass,
      difference,
      item,
      itemBreakDown
    );
  };

  getGroupedWeightBreakdownTotal = weightsBreakdownByItem => {
    const groupedBreakdown = weightsBreakdownByItem.reduce(function (acc, attr) {
      Object.keys(attr).forEach(key => {
        if (acc[key]) {
          acc[key] = parseFloat(attr[key]) + parseFloat(acc[key]);
        } else {
          acc[key] = parseFloat(attr[key]);
        }
      });
      return acc;
    }, {});

    return groupedBreakdown;
  };

  getToolTipStr = (itemBreakDown, id) => {
    let tooltipStr = '';
    Object.keys(itemBreakDown).forEach((attr, cnt) => {
      tooltipStr +=
        (cnt > 0 ? ', ' : '') +
        parseFloat(parseFloat(itemBreakDown[attr]).toFixed(1)) +
        ' ' +
        this.getVerbage(attr);
    });
    return tooltipStr;
  };

  getTableCellOutput = (
    value,
    valueOfnumsClass,
    difference,
    id,
    itemBreakDown
  ) => {
    const itemId = id[0];

    const tooltip = itemBreakDown
      ? this.getToolTipStr(itemBreakDown, itemId)
      : '';

    let returnVal = (
      <Fragment>
        <span>{parseFloat(value)}</span>
        <span className={valueOfnumsClass}>{difference}</span>
      </Fragment>
    );
    if (tooltip.length > 0) {
      returnVal = (
        <div
          className="tooltipped"
          data-position="bottom"
          data-tooltip={tooltip}
        >
          {returnVal}
        </div>
      );
    }
    return returnVal;
  };

  dropDownSortClick = e => {
    let ddId = e.target.id;
    if (ddId) {
      if (this.state.filterName === ddId) ddId = 'value';
      this.handleClick(ddId);
    }
  };

  getProcValue = (item, procBehavior) => {
    let result = 0;
    switch (procBehavior) {
      case '78':
      case '85':
        let tank = 0;
        let threat = 0;
        if (item[12] && !Array.isArray(item[12])) {
          if (
            item[12][procBehavior] &&
            !Array.isArray(item[12][procBehavior])
          ) {
            tank = item[12][procBehavior];
          }
          if (
            item[12][+procBehavior + 1] &&
            !Array.isArray(item[12][+procBehavior + 1])
          ) {
            threat = item[12][+procBehavior + 1];
          }
        } else if (item[0][12] && !Array.isArray(item[0][12])) {
          if (item[0][12][procBehavior]) {
            tank = item[0][12][procBehavior];
          }
          if (item[0][12][+procBehavior + 1]) {
            threat = item[0][12][+procBehavior + 1];
          }
        }
        result = tank + threat;
        break;
      default:
        if (item[12] && !Array.isArray(item[12])) {
          if (
            item[12][procBehavior] &&
            !Array.isArray(item[12][procBehavior])
          ) {
            result = item[12][procBehavior];
          }
        } else if (item[0][12] && !Array.isArray(item[0][12])) {
          if (item[0][12][procBehavior]) {
            result = item[0][12][procBehavior];
          }
        }
        break;
    }
    return result > 0 ? result.toFixed(2) : '';
  };

  getNameWithSpread = (name, item) => {
    // check for sets
    const itemId = item[15];
    let currentSetId = '';
    if (this.props.sets[itemId]) {
      currentSetId = itemId;
    }

    // end
    const id = item[0];
    return (
      <td
        onMouseOver={this.handleonMouseOver.bind(this, item)}
        key={name}
        className={'item-name-td item-rarity-' + item[7]}
      >
        <ItemToolTip
          item={item}
          color={item[7]}
          currentSetId={currentSetId}
          sets={this.props.sets}
          itemUrl={dbUrl + item[0]}
          itemSrc={mediaUrl + itemImagesImport[item[6]].toLowerCase() + '.jpg'}
          slotName={this.props.slotName}
        >
          <div className="left">{item[1]}</div>
          <i
            className="material-icons left"
            onClick={() => {
              this.flatItems(id, 'flat');
            }}
          >
            add
          </i>
        </ItemToolTip>
      </td>
    );
  };

  getName = (name, item) => {
    const itemId = item[15];
    let currentSetId = '';
    if (this.props.sets[itemId]) {
      currentSetId = itemId;
    }
    const id = item[0];
    if (item[16] !== 0) {
      return (
        <td
          onMouseOver={this.handleonMouseOver.bind(this, item)}
          key={name}
          className={'item-name-td item-rarity-' + item[7]}
        >
          <ItemToolTip
            item={item}
            color={item[7]}
            currentSetId={currentSetId}
            sets={this.props.sets}
            itemUrl={dbUrl + item[0]}
            itemSrc={
              mediaUrl + itemImagesImport[item[6]].toLowerCase() + '.jpg'
            }
            slotName={this.props.slotName}
          >
            <div className="left">{item[1]}</div>
            <i
              className="material-icons left"
              onClick={() => {
                this.flatItems(id, 'unflat');
              }}
            >
              remove
            </i>
          </ItemToolTip>
        </td>
      );
    } else {
      return (
        <td
          onMouseOver={this.handleonMouseOver.bind(this, item)}
          key={name}
          className={'item-name-td item-rarity-' + item[7]}
        >
          <ItemToolTip
            item={item}
            color={item[7]}
            currentSetId={currentSetId}
            sets={this.props.sets}
            itemUrl={dbUrl + item[0]}
            itemSrc={
              mediaUrl + itemImagesImport[item[6]].toLowerCase() + '.jpg'
            }
            slotName={this.props.slotName}
          >
            <div className="left">{item[1]}</div>
          </ItemToolTip>
        </td>
      );
    }
  };

  handleonMouseOver = (item, e) => {
    if (e.target.nodeName !== 'A') return;
    if (this.props.tooltipContainer) {
      this.props.updateTooltip(item[1]);
    }
  };

  flatItems = (id, status) => {
    let newMap = this.flat(this.state.newMap, id, status);
    const weightValues = this.createWeightsValues(newMap);
    const weights = weightValues.weights;
    let arrMap = [...newMap];

    if (weights[0]) {
      arrMap = arrMap.map((el, i) => {
        if (Array.isArray(el[0][0])) {
          el[0].forEach((item, index) => {
            item[17] = weights[0][i][index];
          });
          return el;
        }
        el[0][17] = weights[0][i];
        return el;
      });
    } else {
      arrMap = arrMap.map((el, i) => {
        if (Array.isArray(el[0][0])) {
          el[0].forEach((item, index) => {
            item[17] = weights[1][i][index];
            item[18] = weights[2][i][index];
          });
          return el;
        }
        el[0][17] = weights[1][i];
        el[0][18] = weights[2][i];
        return el;
      });
    }
    newMap = new Map(arrMap);

    this.setState({
      newMap
    });
  };

  flat = (map, id, status) => {
    const result = [];
    if (status === 'flat') {
      [...map].forEach(item => {
        if (Array.isArray(item[0][0]) && item[0][0][0] === id) {
          item[0].forEach((i, index) => {
            result.push([i, item[1][index]]);
          });
        } else {
          result.push(item);
        }
      });
      return new Map(result);
    } else if (status === 'unflat') {
      let indexForMap = null;
      const temp = [[], []];
      [...map].forEach((item, index) => {
        if (item[0][0] === id) {
          if (!indexForMap) {
            indexForMap = index + '';
            result.push('piska');
          }
          temp[0].push(item[0]);
          temp[1].push(item[1]);
        } else {
          result.push(item);
        }
      });
      result[parseInt(indexForMap, 10)] = temp;
      return new Map(result);
    } else {
      return map;
    }
  };

  getVerbage = attribute => {
    const resist = [
      'shadow_res_value',
      'frost_res_value',
      'fire_res_value',
      'arcane_res_value',
      'nature_res_value',
      'holy_res_value'
    ];
    if (resist.includes(attribute)) {
      const result = attribute.split('_');
      return `${result[0]} ${result[1]}`;
    }

    if (attribute === 'heal_pwr_min') return 'heal';
    if (attribute === 'attack_pwr_1') return 'AP';
    if (attribute === 'main_hand_skill') return 'skill';
    if (attribute === 'weapon_speed') return 'speed';
    if (attribute === 'offhand_skill') return '';
    if (attribute === 'dps_offhand') return 'DPS';
    if (attribute === 'spell_pwr_fire') return 'spell power';

    if (attribute === 'RWeapon_Speed') return 'speed';
    if (attribute === 'Range_DPS') return 'DPS';

    if (this.props.attributeData.verbage[attribute]) {
      return this.props.attributeData.verbage[attribute];
    } else {
      return attribute;
    }
  };

  // create weights
  getWeightsForWeapons = item => {
    const currentItem = { ...item };
    let baseType = '';
    const subType = currentItem.item_subtype;
    if (!currentItem.weapon_speed) return currentItem;
    const twoHeand = [1, 5, 6, 8, 10, 20];
    const oneHand = [0, 4, 7, 13, 15];
    const ranged = [2, 3, 18];
    const wands = [19];

    if (twoHeand.includes(subType)) baseType = 'Two-Hand';
    if (oneHand.includes(subType)) baseType = 'One-Hand';
    if (ranged.includes(subType)) baseType = 'Ranged';
    if (wands.includes(subType)) baseType = 'Wand';
    if (baseType === '') return currentItem;

    const baseSpeed = {
      'Two-Hand': 2000,
      'One-Hand': 1300,
      Ranged: 1500,
      Wand: 1200
    };
    const dmg1mn = currentItem.dmg_min_1 || 0;
    const dmg1mx = currentItem.dmg_max_1 || 0;
    const dmg2mn = currentItem.dmg_max_2 || 0;
    const dmg2mx = currentItem.dmg_min_2 || 0;
    const ws = currentItem.weapon_speed || 1;
    if (this.props.slotName === 'mainhand') {
      currentItem.DPS =
        (dmg1mn + dmg1mx + dmg2mx + dmg2mn) / 2 / (ws / 1000);
      currentItem.weapon_speed = (ws - baseSpeed[baseType]) / 100;
    }
    if (this.props.slotName === 'offhand') {
      currentItem.DPS_Offhand =
        (dmg1mn + dmg1mx + dmg2mx + dmg2mn) / 2 / (ws / 1000);
      currentItem.WeapSpeed_OH = (ws - baseSpeed[baseType]) / 100;
      currentItem.weapon_speed = 0;
    }
    if (this.props.slotName === 'ranged') {
      currentItem.Range_DPS =
        (dmg1mn + dmg1mx + dmg2mx + dmg2mn) / 2 / (ws / 1000);
      currentItem.RWeapon_Speed = (ws - baseSpeed[baseType]) / 100;
      currentItem.weapon_speed = 0;
    }
    return currentItem;
  };

  createWeightsValues = sortedMap => {
    const itemsAttributes = this.createItemAttrsArray(
      [...sortedMap.keys()],
      [...sortedMap.values()]
    );

    const weights = [];
    const weightsTypes = ['REGULAR', 'TANK', 'THREAT'];
    const weightsBreakDown = {};
    const itemArray = [...sortedMap.keys()];
    let selectedWeights = { ...this.props.weightsData };

    if (this.props.customWeights !== null) {
      for (const key in this.props.customWeights) {
        if (key.includes(this.props.currentSpec)) {
          selectedWeights = { ...this.props.customWeights[key] };
        }
      }
    }

    for (const key in weightsTypes) {
      const currentCharacterInfo = selectedWeights[weightsTypes[key]];

      if (
        currentCharacterInfo.CHARACTER_KEY !== null &&
        itemsAttributes.length !== 0
      ) {
        const characterWeights = {};
        for (const characterWeightsKey in currentCharacterInfo.CHARACTER_WEIGHTS) {
          characterWeights[characterWeightsKey.toUpperCase()] =
            currentCharacterInfo.CHARACTER_WEIGHTS[characterWeightsKey];
        }

        weights[key] = itemsAttributes.map((item, counter) => {
          const itemId = Array.isArray(item)
            ? itemArray[counter][0][0]
            : itemArray[counter][0];

          if (!(itemId in weightsBreakDown)) weightsBreakDown[itemId] = [];
          if (item.item_subtype === 23) {
            item.weapon_speed = 0;
          }
          if (item.item_type === 2) {
            item = this.getWeightsForWeapons(item);
          }

          if (Array.isArray(item)) {
            const arrOfWeights = [];

            item.forEach(randomPropItemAttrs => {
              if (randomPropItemAttrs.item_subtype === 23) {
                randomPropItemAttrs.weapon_speed = 0;
              }
              if (randomPropItemAttrs.item_type === 2) {
                randomPropItemAttrs = this.getWeightsForWeapons(
                  randomPropItemAttrs
                );
              }
              let value = 0;
              const tempVerboseArrayMap = {};

              Object.keys(randomPropItemAttrs).forEach(key => {
                if (key === 'weapon_1_skill') {
                  const types = weaponTypes[this.props.class];
                  if (!types.includes(item.skill_1_type)) return;
                }
                if (
                  randomPropItemAttrs.hasOwnProperty('Range_DPS') &&
                  key === 'weapon_1_skill'
                ) { return; }

                if (
                  !this.filterSpellWeights(
                    this.props.currentSpec,
                    randomPropItemAttrs,
                    key,
                    this.props.class
                  )
                ) { return; }

                const factor = characterWeights[key.toUpperCase()] || 0;

                if (factor > 0 && randomPropItemAttrs[key] > 0) {
                  tempVerboseArrayMap[key] = (
                    factor * +randomPropItemAttrs[key]
                  ).toFixed(1);
                }

                value += factor * +randomPropItemAttrs[key] || 0;
              });

              arrOfWeights.push(value.toFixed(1));
              //              arrOfBreakdown.push(tempVerboseArrayMap);
            });

            //            weightsBreakDown[itemId].push(arrOfBreakdown);

            return arrOfWeights;
          }

          let value = 0;
          const tempVerboseMap = {};

          Object.keys(item).forEach(key => {
            if (key === 'weapon_1_skill') {
              const types = weaponTypes[this.props.class];
              if (!types.includes(item.skill_1_type)) return;
            }
            if (item.hasOwnProperty('Range_DPS') && key === 'weapon_1_skill') { return; }
            if (
              !this.filterSpellWeights(
                this.props.currentSpec,
                item,
                key,
                this.props.class
              )
            ) { return; }

            const factor = characterWeights[key.toUpperCase()] || 0;

            if (factor > 0 && item[key] > 0) {
              tempVerboseMap[key] = (factor * +item[key]).toFixed(1);
            }
            value += factor * +item[key] || 0;
          });
          weightsBreakDown[itemId].push(tempVerboseMap);
          return value.toFixed(1);
        });
      }
    }

    return { weights: weights, weightsBreakDown: weightsBreakDown };
  };

  filterSpellWeights = (spec, item, key, heroClass) => {
    if (item.hasOwnProperty('spell_pwr_type') && key === 'spell_pwr_min') {
      const currentKey = this.props.attributeData.keys[item.spell_pwr_type];
      let valuedPowers = [];
      classesImport.data.forEach(el => {
        if (el.id === heroClass) {
          valuedPowers = { ...el.splPwr };
        }
      });
      let powers = [];
      for (const i in valuedPowers) {
        if (i === spec) {
          powers = valuedPowers[i];
        }
      }
      return powers.includes(currentKey);
    }
    return true;
  };

  createItemAttrsArray (filteredItems, filteredVals) {
    const itemsAttributes = [];
    filteredItems.forEach((item, index) => {
      if (Array.isArray(item[0])) {
        const arrOfAttrs = [];
        item.forEach((randomPropItem, i) => {
          const itemStats = filteredVals[index][i];
          itemStats.item_subtype = randomPropItem[5];
          itemStats.item_type = randomPropItem[4];
          arrOfAttrs.push(itemStats);
        });
        itemsAttributes.push(arrOfAttrs);
        return;
      }
      const itemStats = filteredVals[index];
      itemStats.item_subtype = item[5];
      itemStats.item_type = item[4];
      itemsAttributes.push(itemStats);
    });
    return itemsAttributes;
  }

  setProcBehavior () {
    for (const behavior in specProc) {
      if (specProc[behavior].includes(this.props.currentSpec)) {
        return behavior;
      }
    }
    return 'none';
  }

  getProcBehavior (item, procIndex) {
    if (item[12] && !Array.isArray(item[12])) {
      if (item[12][procIndex]) {
        return item[12][procIndex];
      }
    }
    return 0;
  }

  filterFields = (sortArr, name, filterParams) => {
    // by name
    if (name === 'name') {
      if (filterParams) {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          if (a[0][1] > b[0][1]) return 1;
          else return -1;
        });
      } else {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          if (a[0][1] < b[0][1]) return 1;
          else return -1;
        });
      }
      // main hand skill sort (main sort)
    } else if (name === 'skill') {
      if (filterParams) {
        sortArr.sort((a, b) => {
          a = a[0];
          b = b[0];
          if (Array.isArray(a[0])) {
            a = a[0];
          }
          if (Array.isArray(b[0])) {
            b = b[0];
          }
          a = JSON.parse(JSON.stringify(a))[12];
          b = JSON.parse(JSON.stringify(b))[12];
          let valueA = 0;
          let valueB = 0;
          if (Object.keys(a).includes('46')) {
            if (![45, 46, 226].includes(a['47'])) {
              valueA += a['46'];
            }
            if (Object.keys(a).includes('52')) {
              if (![45, 46, 226].includes(a['53'])) {
                valueA += a['52'];
              }
              if (Object.keys(a).includes('54')) {
                if (![45, 46, 226].includes(a['55'])) {
                  valueA += a['54'];
                }
              }
            }
          }
          if (Object.keys(b).includes('46')) {
            if (![45, 46, 226].includes(b['47'])) {
              valueB += b['46'];
            }
            if (Object.keys(b).includes('52')) {
              if (![45, 46, 226].includes(b['53'])) {
                valueB += b['52'];
              }
              if (Object.keys(b).includes('54')) {
                if (![45, 46, 226].includes(b['55'])) {
                  valueB += b['54'];
                }
              }
            }
          }
          return valueA - valueB;
        });
      } else {
        sortArr.sort((a, b) => {
          a = a[0];
          b = b[0];
          if (Array.isArray(a[0])) {
            a = a[0];
          }
          if (Array.isArray(b[0])) {
            b = b[0];
          }
          a = JSON.parse(JSON.stringify(a))[12];
          b = JSON.parse(JSON.stringify(b))[12];
          let valueA = 0;
          let valueB = 0;
          if (Object.keys(a).includes('46')) {
            if (![45, 46, 226].includes(a['47'])) {
              valueA += a['46'];
            }
            if (Object.keys(a).includes('52')) {
              if (![45, 46, 226].includes(a['53'])) {
                valueA += a['52'];
              }
              if (Object.keys(a).includes('54')) {
                if (![45, 46, 226].includes(a['55'])) {
                  valueA += a['54'];
                }
              }
            }
          }
          if (Object.keys(b).includes('46')) {
            if (![45, 46, 226].includes(b['47'])) {
              valueB += b['46'];
            }
            if (Object.keys(b).includes('52')) {
              if (![45, 46, 226].includes(b['53'])) {
                valueB += b['52'];
              }
              if (Object.keys(b).includes('54')) {
                if (![45, 46, 226].includes(b['55'])) {
                  valueB += b['54'];
                }
              }
            }
          }
          return valueB - valueA;
        });
      }
    }
    // ranged skill filter(main)
    else if (name === 'ranged_skill') {
      if (filterParams) {
        sortArr.sort((a, b) => {
          a = a[0];
          b = b[0];
          if (Array.isArray(a[0])) {
            a = a[0];
          }
          if (Array.isArray(b[0])) {
            b = b[0];
          }
          a = JSON.parse(JSON.stringify(a))[12];
          b = JSON.parse(JSON.stringify(b))[12];
          let valueA = 0;
          let valueB = 0;
          if (Object.keys(a).includes('46')) {
            if ([45, 46, 226].includes(a['47'])) {
              valueA += a['46'];
            }
            if (Object.keys(a).includes('52')) {
              if ([45, 46, 226].includes(a['53'])) {
                valueA += a['52'];
              }
              if (Object.keys(a).includes('54')) {
                if ([45, 46, 226].includes(a['55'])) {
                  valueA += a['54'];
                }
              }
            }
          }
          if (Object.keys(b).includes('46')) {
            if ([45, 46, 226].includes(b['47'])) {
              valueB += b['46'];
            }
            if (Object.keys(b).includes('52')) {
              if ([45, 46, 226].includes(b['53'])) {
                valueB += b['52'];
              }
              if (Object.keys(b).includes('54')) {
                if ([45, 46, 226].includes(b['55'])) {
                  valueB += b['54'];
                }
              }
            }
          }
          return valueA - valueB;
        });
      } else {
        sortArr.sort((a, b) => {
          a = a[0];
          b = b[0];
          if (Array.isArray(a[0])) {
            a = a[0];
          }
          if (Array.isArray(b[0])) {
            b = b[0];
          }
          a = JSON.parse(JSON.stringify(a))[12];
          b = JSON.parse(JSON.stringify(b))[12];
          let valueA = 0;
          let valueB = 0;
          if (Object.keys(a).includes('46')) {
            if ([45, 46, 226].includes(a['47'])) {
              valueA += a['46'];
            }
            if (Object.keys(a).includes('52')) {
              if ([45, 46, 226].includes(a['53'])) {
                valueA += a['52'];
              }
              if (Object.keys(a).includes('54')) {
                if ([45, 46, 226].includes(a['55'])) {
                  valueA += a['54'];
                }
              }
            }
          }
          if (Object.keys(b).includes('46')) {
            if ([45, 46, 226].includes(b['47'])) {
              valueB += b['46'];
            }
            if (Object.keys(b).includes('52')) {
              if ([45, 46, 226].includes(b['53'])) {
                valueB += b['52'];
              }
              if (Object.keys(b).includes('54')) {
                if ([45, 46, 226].includes(b['55'])) {
                  valueB += b['54'];
                }
              }
            }
          }
          return valueB - valueA;
        });
      }
    } else if (name === 'value') {
      if (filterParams) {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return a[0][17] - b[0][17];
        });
      } else {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return b[0][17] - a[0][17];
        });
      }
    } else if (name === 'value ') {
      if (filterParams) {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return +a[0][17] + +a[0][18] - (+b[0][17] + +b[0][18]);
        });
      } else {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return +b[0][17] + +b[0][18] - (+a[0][17] + +a[0][18]);
        });
      }
    } else if (name === 'proc') {
      const procBehavior = this.setProcBehavior();
      sortArr.sort((a, b) => {
        const arg1 =
          this.getProcValue(procBehavior, a) !== ''
            ? this.getProcValue(procBehavior, a)
            : 0;
        const arg2 =
          this.getProcValue(procBehavior, b) !== ''
            ? this.getProcValue(procBehavior, b)
            : 0;
        return filterParams ? arg1 - arg2 : arg2 - arg1;
      });
    } else if (name === 'tank' || name === 'threat') {
      const fieldKey = name === 'tank' ? 17 : 18;
      if (filterParams) {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return a[0][fieldKey] - b[0][fieldKey];
        });
      } else {
        sortArr.sort((a, b) => {
          if (Array.isArray(a[0][0])) {
            a = a[0];
          }
          if (Array.isArray(b[0][0])) {
            b = b[0];
          }
          return b[0][fieldKey] - a[0][fieldKey];
        });
      }
    } else {
      // other
      if (filterParams) {
        sortArr.sort((a, b) => {
          let arg1, arg2;
          arg1 = 0;
          arg2 = 0;

          if (typeof a[1][0] === 'object' && a[1][0][name]) {
            arg1 = a[1][0][name];
          }
          if (typeof b[1][0] === 'object' && b[1][0][name]) {
            arg2 = b[1][0][name];
          }
          if (a[1][name]) {
            arg1 = a[1][name];
          }
          if (b[1][name]) {
            arg2 = b[1][name];
          }
          return arg1 - arg2;
        });
      } else {
        sortArr.sort((a, b) => {
          let arg1, arg2;
          arg1 = 0;
          arg2 = 0;
          if (typeof a[1][0] === 'object' && a[1][0][name]) {
            arg1 = a[1][0][name];
          }
          if (typeof b[1][0] === 'object' && b[1][0][name]) {
            arg2 = b[1][0][name];
          }
          if (a[1][name]) {
            arg1 = a[1][name];
          }
          if (b[1][name]) {
            arg2 = b[1][name];
          }
          return arg2 - arg1;
        });
      }
    }
  };

  filterMapItems = (filtredItem, filtredAttrs, name) => {
    let randomValueMap = new Map();
    for (let i = 0; i < filtredItem.length; i++) {
      randomValueMap.set(filtredItem[i], filtredAttrs[i]);
    }
    const randomValueArr = [...randomValueMap];

    if (name === 'name') {
      randomValueArr.sort((a, b) => {
        if (a[0][1] > b[0][1]) return 1;
        else return -1;
      });
    } else if (name === 'tank' || name === 'threat' || name === 'value ') {
      const fieldKey = name === 'tank' ? 17 : 18;
      if (name !== 'value ') {
        randomValueArr.sort((a, b) => {
          return b[0][fieldKey] - a[0][fieldKey];
        });
      } else {
        randomValueArr.sort((a, b) => {
          return +b[0][17] + +b[0][18] - (+a[0][17] + +a[0][18]);
        });
      }
    } else if (name === 'value') {
      randomValueArr.sort((a, b) => {
        let arg1 = 0;
        let arg2 = 0;
        if (a[0][17]) {
          arg1 = a[0][17];
        }
        if (b[0][17]) {
          arg2 = b[0][17];
        }
        return arg2 - arg1;
      });
    } else {
      randomValueArr.sort((a, b) => {
        let arg1 = 0;
        let arg2 = 0;
        if (a[1][name]) {
          arg1 = a[1][name];
        }
        if (b[1][name]) {
          arg2 = b[1][name];
        }
        return arg2 - arg1;
      });
    }
    randomValueMap = new Map(randomValueArr);
    filtredItem = [...randomValueMap.keys()];
    filtredAttrs = [...randomValueMap.values()];
    return [filtredItem, filtredAttrs];
  };
}

export default ItemList;
