<template>
  <div
    id="tooltip"
    class="p-2 space-y-2 text-left min-w-max"
  >
    <h3>{{ faction.name }}</h3>
    <p
      v-if="faction.description"
      class="block leading-relaxed text-tooltip-misc w-72 line-clamp-4 break-words"
    >
      {{ faction.description }}
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'FactionTooltip',
  props: {
    faction: {
      type: Object,
      required: true
    }
  }
})
</script>
<style lang="postcss" scoped>

  #tooltip {
    top: 5000px;
    left: 5000px;
  }
</style>
