<template>
  <div
    id="app"
  >
    <Navbar v-if="onLandingPage" />

    <transition
      name="fade-custom"
      mode="out-in"
    >
      <router-view :key="$route.fullPath" />
    </transition>

    <Footer
      id="footer"
    />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import 'reflect-metadata'
import Navbar from '../../../lib/src/components/Navbar'
import npcMixin from '../../../lib/src/mixins/npc-mixin'
import './assets/css/tailwind.css'

export default {
  name: 'App',
  components: {
    Footer,
    Navbar
  },
  metaInfo: {
    title: 'WoW Classic Database',
    titleTemplate: (chunk) => chunk ? `${chunk} - WoW Classic DB` : 'WoW Classic DB',
    meta: [
      { property: 'description', vmid: 'description', content: 'The go-to database for WoW Classic Seasons of Mastery, Classic Era, and more!' },
      { property: 'og:site_name', vmid: 'og:site_name', content: 'WoW Classic DB' },
      { property: 'og:title', vmid: 'og:title', content: 'WoW Classic Items, Quests and more' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', vmid: 'og:url', content: 'https://wowclassicdb.com/' },
      { property: 'og:description', vmid: 'og:description', content: 'The go-to database for WoW Classic Seasons of Mastery, Classic Era, and more!' },
      { property: 'og:image', vmid: 'og:image', content: 'https://cdn.wowclassicdb.com/misc/logo.png' },
      { property: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  mixins: [
    npcMixin
  ],
  computed: {
    onLandingPage () {
      return this.$route.name === 'home' || this.$route.name === 'not-found'
    }
  },
  watch: {
    $route: function (to, from) {
      this.$store.commit('clearRelatedTable')
      this.$store.commit('clearTooltip')
      this.$store.commit('setHighlightedId', null, { root: true })
      if (to.path !== from.path) {
        this.$store.dispatch('resetListView')
      }

      const isDifferentPath = to.path !== from.path
      if (isDifferentPath) {
        this.$store.commit('clearFilters')
        this.$store.dispatch('setFiltersFromRoute')

        this.$store.commit('clearTooltip')
        this.$store.commit('setHighlightedId', null, { root: true })

        this.resetSelectedNpc()
      }
    }
  },
  mounted () {
    this.$store.dispatch('setFiltersFromRoute')

    this.$store.commit('clearTooltip')
    this.$store.commit('setHighlightedId', null, { root: true })
  }
}
</script>

<style lang="scss">

body {
  @apply bg-bg-500;
}

// Ad styling
#stickyfooter {
  background-color: #0000008c !important;
}

.fade-custom-enter-active,
.fade-custom-leave-active {
  transition: opacity 0.25s;
}

.fade-custom-enter,
.fade-custom-leave-to {
  opacity: 0;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 0.9em;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    @apply text-primary;
  }
}

h2 {
  font-family: BeaufortforLOL-Bold, sans-serif;
  font-size: 1.25em;
}

.dropdown-icon {
  vertical-align: middle;
  width: 17px;

  &.reverse {
    transform: rotate(180deg);
  }

  &.hide {
    visibility: hidden;
  }
}

.body-container {
  // height: 100vh;
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Tooltip Stylings */
.tooltip-img {
  margin-right: 5px;
  width: 25px;
  object-fit: cover;
}

.item-name {
  font-size: 1.1em;
}

.q-poor {
  color: #9d9d9d;
}

.q-common {
  color: white;
}

.q-uncommon {
  color: #1eff00;
}

.q-rare {
  color: #0070dd;
}

.q-epic {
  color: #a335ee;
}

.q-legendary {
  color: #ff8000;
}

.q-artifact {
  color: #e6cc80;
}

.misc {
  color: #ffd100;
}

.white-wrap {
  white-space: break-spaces;
}
</style>
