import React from 'react';

const slotName = props => {
  return (
    <div className={'left item-slot-name item-rarity-' + props.rarity}>
      {props.name}
    </div>
  );
};

export default slotName;
