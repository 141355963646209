<template>
  <TemplateSinglePage :show-loading="!quest">
    <Breadcrumbs />
    <SingleQuest
      v-if="quest"
      :quest="quest"
    />

    <RelatedTableFilterButtonBase :filter-options="filterOptions" />
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import SingleQuest from '@/components/SingleQuest'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import coreMixin from '../../../../lib/src/mixins/core-mixin'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Quest',
  components: {
    TemplateSinglePage,
    SingleQuest,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  mixins: [
    coreMixin
  ],
  metaInfo () {
    return {
      title: this.quest?.name,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.quest?.name },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'description', vmid: 'description', content: this.metaDescription },
        { property: 'og:description', vmid: 'og:description', content: this.metaDescription }
      ]
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.quest?.events, 'events', '', RelatedTableFilterOption.events),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    metaDescription () {
      return `View Quest ${this.quest?.name} from WoW Classic. ${this.quest?.objectives}`
    },
    quest () {
      return this.$store.state.quests[this.$route.params.id]
    },
    tooltip () {
      return this.$store.state.tooltips.quest[this.$route.params.id]
    }
  },
  serverPrefetch () { // Server side fetching
    return this.fetchQuest()
  },
  mounted () { // Cient side fetching
    if (!this.quest) this.fetchQuest()
  },
  methods: {
    fetchQuest () {
      const page = this.$store.state.pageInfo?.currentPage
      return this.$store.dispatch('fetchQuest', { id: this.$route.params.id, page: page })
    }
  }
}
</script>
