import axios from 'axios'
const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  json: true
})
export default {
  async execute (method, url) {
    return client({ method, url })
      .then(req => {
        return req.data
      })
      .catch(e => {
        // console.error(`ERROR: ${e}`)
      })
  },
  fetchRelatedTableData (type, id, name, page) {
    // Fetch a specific related table for any type of entity
    return this.execute('get', `${type}/${id}/${name}?p=${page || 1}`)
  },

  getSearchResultsData (type, page = 1, limit, query) {
    return this.execute('get', `/search/result/${type}?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  async getItem (id) {
    const data = await this.execute('get', `/item/${id}`)
    return data
  },
  async getFaction (id) {
    const data = await this.execute('get', `/faction/${id}`)
    return data
  },
  getEventNpcs (id, page) {
    return this.execute('get', `/event/${id}/npcs?p=${page || 1}`)
  },
  getEventObjects (id, page) {
    return this.execute('get', `/event/${id}/objects?p=${page || 1}`)
  },
  getEventQuests (id, page) {
    return this.execute('get', `/event/${id}/quests?p=${page || 1}`)
  },
  async getEvent (id) {
    const data = await this.execute('get', `/event/${id}`)
    return data
  },
  getFactionNpcs (id, page) {
    // console.log(id, page)
    return this.execute('get', `/faction/${id}/npcs?p=${page || 1}`)
  },
  getFactionQuests (id, page) {
    return this.execute('get', `/faction/${id}/quests?p=${page || 1}`)
  },
  getFactionItems (id, page) {
    return this.execute('get', `/faction/${id}/items?p=${page || 1}`)
  },
  getItemDroppedBy (id, page) {
    return this.execute('get', `/item/${id}/dropped-by?p=${page || 1}`)
  },
  getItemCreatedBy (id, page) {
    return this.execute('get', `/item/${id}/created-by?p=${page || 1}`)
  },
  getItemReagentFor (id, page) {
    return this.execute('get', `/item/${id}/reagent-for?p=${page || 1}`)
  },
  getItemSameModelAs (id, page) {
    return this.execute('get', `/item/${id}/same-model-as?p=${page || 1}`)
  },
  getItemToolFor (id, page) {
    return this.execute('get', `/item/${id}/tool-for?p=${page || 1}`)
  },
  getItemSkinnedBy (id, page) {
    return this.execute('get', `/item/${id}/skinned-by?p=${page || 1}`)
  },
  getItemPickpocketedBy (id, page) {
    return this.execute('get', `/item/${id}/pickpocketed-by?p=${page || 1}`)
  },
  getItemFishedFrom (id, page) {
    return this.execute('get', `/item/${id}/fished-from?p=${page || 1}`)
  },
  getItemFishedInZone (id, page) {
    return this.execute('get', `/item/${id}/fished-in-zone?p=${page || 1}`)
  },
  getItemContainedIn (id, page) {
    return this.execute('get', `/item/${id}/contained-in?p=${page || 1}`)
  },
  getItemRaces (id, page) {
    return this.execute('get', `/item/${id}/races?p=${page || 1}`)
  },
  getItemClasses (id, page) {
    return this.execute('get', `/item/${id}/classes?p=${page || 1}`)
  },
  getItemLoot (id, page) {
    return this.execute('get', `/item/${id}/loot?p=${page || 1}`)
  },
  getItemLootedFrom (id, page) {
    return this.execute('get', `/item/${id}/looted-from?p=${page || 1}`)
  },
  getItemDisenchanting (id, page) {
    return this.execute('get', `/item/${id}/disenchanting?p=${page || 1}`)
  },
  getItemDisenchantedFrom (id, page) {
    return this.execute('get', `/item/${id}/disenchanted-from?p=${page || 1}`)
  },
  getItemUnlocks (id, page) {
    return this.execute('get', `/item/${id}/unlocks?p=${page || 1}`)
  },
  getItemProspectedFrom (id, page) {
    return this.execute('get', `/item/${id}/prospected-from?p=${page || 1}`)
  },
  getItemSoldBy (id, page) {
    return this.execute('get', `/item/${id}/sold-by?p=${page || 1}`)
  },
  getItemRewardFromQuests (id, page) {
    return this.execute('get', `/item/${id}/reward-from-quests?p=${page || 1}`)
  },
  getItemRequiredSourceForQuests (id, page) {
    return this.execute('get', `/item/${id}/required-source-for-quests?p=${page || 1}`)
  },
  getItemRequiredForQuests (id, page) {
    return this.execute('get', `/item/${id}/required-for-quests?p=${page || 1}`)
  },
  async getRace (id, page) {
    const data = await this.execute('get', `/race/${id}`)
    return data
  },
  async getRaceClasses (id, page) {
    return this.execute('get', `/race/${id}/classes?p=${page || 1}`)
  },
  async getRaceQuests (id, page) {
    return this.execute('get', `/race/${id}/quests?p=${page || 1}`)
  },
  async getClassData (id, page) {
    const data = await this.execute('get', `/class/${id}`)
    return data
  },
  async getClassRaces  (id, page) {
    return this.execute('get', `/class/${id}/races?p=${page || 1}`)
  },
  async getClassQuests (id, page) {
    return this.execute('get', `/class/${id}/quests?p=${page || 1}`)
  },
  async getItemSet (id) {
    const data = await this.execute('get', `/item-set/${id}`)
    return data
  },
  async getQuest (id) {
    const data = await this.execute('get', `/quest/${id}`)
    return data
  },
  async getZone (id) {
    const data = await this.execute('get', `/zone/${id}`)
    return data
  },
  getZoneSubLocations (id, page) {
    return this.execute('get', `/zone/${id}/sub-locations?p=${page || 1}`)
  },
  getZoneQuestRewards (id, page) {
    return this.execute('get', `/zone/${id}/quest-rewards?p=${page || 1}`)
  },
  getZoneFishing (id, page) {
    return this.execute('get', `/zone/${id}/fishing?p=${page || 1}`)
  },
  getZoneNpcs (id, page) {
    // console.log(id, page)
    return this.execute('get', `/zone/${id}/npcs?p=${page || 1}`)
  },
  getZoneQuests (id, page) {
    return this.execute('get', `/zone/${id}/quests?p=${page || 1}`)
  },
  getZoneObjects (id, page) {
    return this.execute('get', `/zone/${id}/objects?p=${page || 1}`)
  },
  async getSpell (id) {
    const data = await this.execute('get', `/spell/${id}`)
    return data
  },
  getSpellRaces (id, page) {
    return this.execute('get', `/spell/${id}/races?p=${page || 1}`)
  },
  getSpellClasses (id, page) {
    return this.execute('get', `/spell/${id}/classes?p=${page || 1}`)
  },
  getSpellCastedBy (id, page) {
    return this.execute('get', `/spell/${id}/casted-by?p=${page || 1}`)
  },
  getSpellTaughtBy (id, page) {
    return this.execute('get', `/spell/${id}/taught-by?p=${page || 1}`)
  },
  getSpellUsedBy (id, page) {
    return this.execute('get', `/spell/${id}/used-by?p=${page || 1}`)
  },
  getSpells (page = 1, first) {
    const url = ['spells', first].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  async getNPC (id) {
    const data = await this.execute('get', `/npc/${id}`)
    return data
  },
  async getObject (id) {
    const data = await this.execute('get', `/object/${id}`)
    return data
  },
  getObjectRequiredForQuests (id, page) {
    return this.execute('get', `/object/${id}/required-for-quests?p=${page || 1}`)
  },
  getObjectStarts (id, page) {
    return this.execute('get', `/object/${id}/starts-quests?p=${page || 1}`)
  },
  getObjectEnds (id, page) {
    return this.execute('get', `/object/${id}/ends-quests?p=${page || 1}`)
  },
  getObjectContains (id, page) {
    return this.execute('get', `/object/${id}/contains?p=${page || 1}`)
  },
  getObjectSameModelAs (id, page) {
    return this.execute('get', `/object/${id}/same-model-as?p=${page || 1}`)
  },
  getObjectFishing (id, page) {
    return this.execute('get', `/object/${id}/fishing?p=${page || 1}`)
  },
  getNPCStartsQuests (id, page) {
    return this.execute('get', `/npc/${id}/starts-quests?p=${page || 1}`)
  },
  getNPCObjectiveOf (id, page) {
    return this.execute('get', `/npc/${id}/objective-of?p=${page || 1}`)
  },
  getNPCTeaches (id, page) {
    return this.execute('get', `/npc/${id}/trains?p=${page || 1}`)
  },
  getNPCEndsQuests (id, page) {
    return this.execute('get', `/npc/${id}/ends-quests?p=${page || 1}`)
  },
  getNPCSells (id, page) {
    return this.execute('get', `/npc/${id}/sells?p=${page || 1}`)
  },
  getNPCDrops (id, page) {
    return this.execute('get', `/npc/${id}/drops?p=${page || 1}`)
  },
  getNPCSkinning (id, page) {
    return this.execute('get', `/npc/${id}/skinning?p=${page || 1}`)
  },
  getNPCPickpocketing (id, page) {
    return this.execute('get', `/npc/${id}/pickpocketing?p=${page || 1}`)
  },
  getNPCSpells (id, page) {
    return this.execute('get', `/npc/${id}/spells?p=${page || 1}`)
  },
  getSpellRanks (id, page) {
    return this.execute('get', `/spell/${id}/ranks?p=${page || 1}`)
  },
  getSpellUsedByItem (id, page) {
    return this.execute('get', `/spell/${id}/used-by-item?p=${page || 1}`)
  },
  getTypes (type = 'item') {
    return this.execute('get', `/${type}/types`)
  },
  getItems (page = 1, first, second, third) {
    const url = ['items', first, second, third].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getItemSets (page = 1, first) {
    const url = ['item-sets', first].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getQuests (page = 1, first, second) {
    const url = ['quests', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getNPCs (page = 1, first, second) {
    const url = ['npcs', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getObjects (page = 1, first, second) {
    const url = ['objects', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getTooltip (id, type = 'item') {
    return this.execute('get', `/tooltips/${type}/${id}`)
  },
  getSearchSuggestions (query, limit) {
    return this.execute('get', `/search/suggestion?q=${query}&limit=${limit || 4}`)
  },
  getNews (limit = 3) {
    return this.execute('get', `/news?limit=${limit}`)
  }
}
