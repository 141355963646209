<template>
  <TemplateSinglePage :show-loading="isLoading">
    <div v-if="selectedNpc">
      <div class="flex overflow-hidden">
        <div class="flex flex-col flex-1 w-0 overflow-hidden">
          <main
            class="relative flex-1 focus:outline-none"
            tabindex="-1"
          >
            <Breadcrumbs />
            <div class="py-8 xl:py-10">
              <div class="px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-12">
                <div class="xl:col-span-12 ">
                  <div class="w-full xl:pb-6">
                    <div class="flex w-full space-x-6 space-y-2">
                      <img
                        id="mapImage"
                        v-lazy="npc.imageUrl"
                        class="object-contain w-40 h-40 m-auto"
                        :alt="`Image of ${npc.name}`"
                      >

                      <div class="w-full">
                        <h1 class="text-2xl font-bold leading-6 text-white">
                          {{ npc.name }}
                        </h1>
                        <h2
                          v-if="npc.title"
                          class="text-gray-100"
                        >
                          {{ npc.title }}
                        </h2>
                        <div>
                          <p class="mt-1 text-sm font-medium text-gray-200">
                            Level {{ npc.maxLevel }}
                            <span v-if="npc.npcRank">{{ npc.npcRank.name }}</span>
                            {{ npc.npcType.name }}
                          </p>
                          <p
                            v-if="npc.primarySpawnLocation"
                            class="text-sm text-gray-300"
                          >
                            Found in
                            <router-link
                              :to="`/zone/${npc.primarySpawnLocation.id}`"
                              class="font-medium text-primary"
                            >
                              {{ npc.primarySpawnLocation.name }}
                            </router-link>
                          </p>
                        </div>
                        <div class="mt-2 space-y-2">
                          <div class="flex items-center space-x-2">
                            <template v-if="npc.boss">
                              <svg
                                class="w-5 h-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </template>
                            <template v-else>
                              <svg
                                class="w-5 h-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                />
                              </svg>
                            </template>
                            <span
                              class="text-sm font-medium text-gray-200"
                            >Boss</span>
                          </div>
                          <div class="flex items-center space-x-2">
                            <template v-if="npc.civilian">
                              <svg
                                class="w-5 h-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </template>
                            <template v-else>
                              <svg
                                class="w-5 h-5 text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                />
                              </svg>
                            </template>
                            <span
                              class="text-sm font-medium text-gray-200"
                            >Civilian</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="npc.locations && npc.locations.length"
                    class="py-3 mt-6 space-y-6 xl:pb-0 sm:mt-4"
                  >
                    <h2 class="p-0 mx-0 mt-0 mb-3.5 font-sans text-xl font-bold text-white ">
                      Location
                    </h2>
                    <div
                      class="max-w-3xl "
                    >
                      <WorldMapContainer
                        :markers="markers"
                        :locations="locations"
                      />
                    </div>
                  </div>
                  <quick-facts
                    class="text-white mb-4"
                    :data="quickFactsData"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <RelatedTableFilterButtonBase
        v-if="filterOptions"
        :filter-options="filterOptions"
      />
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import WorldMapContainer from '@/components/MapContainer.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import npcMixin from '../../../../lib/src/mixins/npc-mixin'
import { NPC } from '../../../../lib/src/models/NPC'

export default {
  name: 'NPC',
  components: {
    TemplateSinglePage,
    WorldMapContainer,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFacts
  },
  mixins: [npcMixin],
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.selectedNpc?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.selectedNpc?.name })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.generateOGDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.generateOGDescription })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:image', vmid: 'og:image', content: `https://cdn.wowclassicdb.com/npcs/${this.selectedNpc?.id}.png` })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    generateOGDescription () {
      let description = 'View NPC '
      if (this.selectedNpc?.maxLevel) { description += `Level ${this.selectedNpc.maxLevel} ` }
      if (this.selectedNpc?.npcRank) { description += `${this.selectedNpc.npcRank.name} ` }
      if (this.selectedNpc?.npcType) { description += `${this.selectedNpc.npcType.name} ` }
      description += ' from WoW Classic.'
      return description
    },

    npc () {
      if (this.selectedNpc) {
        return new NPC({
          npc: this.selectedNpc
        })
      }
      return null
    },

    quickFactsData () {
      return {
        droppedGold: (this.selectedNpc.minLootGold + this.selectedNpc.maxLootGold) / 2,
        type: this.selectedNpc.npcType?.name,
        minHealth: this.selectedNpc.minHealth,
        mana: this.selectedNpc.mana,
        maxHealth: this.selectedNpc.maxHealth,
        faction: this.selectedNpc.faction,
        side: this.selectedNpc.faction?.side
      }
    },

    markers () {
      const markers = []
      this.selectedNpc.npcSpawns.forEach((spawn) => {
        markers.push({
          x: spawn.posX,
          y: spawn.posY,
          location: spawn.location?.id > 0 ? spawn.location.id : this.selectedNpc.locations[0].id,
          id: spawn.id,
          label: this.selectedNpc.name
        })
      })
      return markers
    },

    locations () {
      const locations = []
      if (this.selectedNpc.npcSpawns.length) {
        this.selectedNpc.npcSpawns.forEach((spawn, i) => {
          if (spawn.location?.id && !locations.find(l => l?.id === spawn.location?.id)) {
            locations.push(spawn.location)
          } else {
            if (!locations.find(l => l?.id === this.selectedNpc.locations[i]?.id)) {
              locations.push(this.selectedNpc.locations[i])
            }
          }
        })
      } else {
        for (const location of this.selectedNpc.locations) {
          locations.push(location)
        }
      }
      return locations
    },

    filterOptions () {
      return this.npc.relatedTableFilterOptions
    },
    isLoading () {
      return this.selectedNpc?.id?.toString() !== this.$route.params.id
    }

  },
  serverPrefetch () {
    // Server side fetching
    return this.fetchNPC({ id: this.$route.params.id, page: this.$store.state.pageInfo?.currentPage })
  },
  mounted () {
    this.setSelectedNpcId({ id: this.$route.params.id })
    // Cient side fetching
    this.fetchNPC({ id: this.$route.params.id, page: this.$store.state.pageInfo?.currentPage })
  }
}
</script>
