export default {
  actions: {
    filterLocationsByEasternKingdoms: 'filterLocationsByEasternKingdoms',
    filterLocationsByKalimdor: 'filterLocationsByKalimdor',
    filterLocationsByAll: 'filterLocationsByAll',
    filterLocationsByName: 'filterLocationsByName'
  },

  getters: {
    locations: 'locations',
    locationById: 'locationById',
    locationByName: 'locationByName',
    locationsWithIds: 'locationsWithIds',
    allLocationIds: 'allLocationIds',
    locationsInEasternKingdoms: 'locationsInEasternKingdoms',
    locationsInKalimdor: 'locationsInKalimdor',
    locationIdsInEasternKingdoms: 'locationIdsInEasternKingdoms',
    locationIdsInKalimdor: 'locationIdsInKalimdor',
    locationsInDungeons: 'locationsInDungeons',
    locationIdsInDungeons: 'locationIdsInDungeons',
    locationsInRaids: 'locationsInRaids',
    locationIdsInRaids: 'locationIdsInRaids',
    locationsInBattlegrounds: 'locationsInBattlegrounds',
    locationIdsInBattlegrounds: 'locationIdsInBattlegrounds'
  }
}
