export const atlas = (type, value) => {
  return (dispatch) => {
    return dispatch(setAtlas(type, value));
  };
};

const setAtlas = (type, data) => {
  switch (type) {
    case "SET_MAPVIEW":
    case "SET_MAP":
    case "SET_AREA":
      return {
        type: type,
        payload: data,
      };

    default:
      break;
  }
};
