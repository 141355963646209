import React from 'react';
import WeightsLabel from './label';

class Weights extends React.Component {
  render () {
    const props = this.props;
    if (!props.weightsData || !props.currentOpenWindow) return null;

    const weightDataAttributes = formatWeightDataTypeAttributes(
      props.weightType,
      props.classId,
      props.data
    );

    return (
      <div className="weights-wrapper">
        {weightDataAttributes.map((key, i) => {
          const weightValue =
            props.currentOpenWindow &&
            props.weightsData[props.currentOpenWindow] &&
            props.weightsData[props.currentOpenWindow].CHARACTER_WEIGHTS
              ? props.weightsData[props.currentOpenWindow].CHARACTER_WEIGHTS[
                key
                ]
              : '';

          const weightDesc = props.descriptions[key]
            ? props.descriptions[key]
            : null;

          return (
            <div key={key}>
              <WeightsLabel
                description={weightDesc}
                tooltipKey={key}
                key={key + 'tooltip'}
                weightsData={props.weightsData}
                id={`Basic${i}`}
              />
              {props.calculatedFields.hasOwnProperty(key) ? (
                <div className="derived-weight" key={key}>
                  {weightValue}
                </div>
              ) : (
                <input
                  key={key}
                  type="number"
                  id={key}
                  value={weightValue}
                  onChange={props.handleOnChangeWaitsField.bind(
                    this,
                    props.currentOpenWindow,
                    key,
                    props.cKey
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const formatWeightDataTypeAttributes = (weightType, classId, data) => {
  let newData = data;

  switch (weightType) {
    case 'base': {
      if (classId === 0 || classId === 8) {
        const removeFromData = ['Mana'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      }
      break;
    }
    case 'caster': {
      if (classId === 0 || classId === 8) {
        const removeFromData = ['MP5'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      }
      break;
    }
    case 'tanking': {
      if (classId === 1 || classId === 2 || classId === 5 || classId === 6) {
        const removeFromData = ['Parry', 'Block_Value_Total', 'Block_Pct'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      } else if (classId === 7 || classId === 8) {
        const removeFromData = ['Block_Value_Total', 'Block_Pct'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      }

      break;
    }
    case 'weapons': {
      if (classId !== 0 && classId !== 7 && classId !== 8) {
        // for offhand
        const removeFromData = ['DPS_Offhand', 'WeapSpeed_OH'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      } else if (classId === 2 || classId === 3 || classId === 4) {
        // for ranged
        const removeFromData = ['Range_DPS', 'RWeapon_Speed'];
        newData = newData.filter(attr => !removeFromData.includes(attr));
      }
      break;
    }
    default:
      break;
  }

  return newData;
};

export default Weights;
