import React from 'react';

const attributeHeader = props => {
  return (
    <div className="attribute-row attribute-header">
      {props.attributesGroup.map(group => (
        <div
          className={
            'tooltipped attribute-img ' +
            group +
            (props.activeGroup === group ? ' active-group' : '')
          }
          onClick={props.handleAttributeClick}
          key={'attribute-img-' + group}
          id={group}
          data-position="bottom"
          data-tooltip={group}
        />
      ))}
    </div>
  );
};

export default attributeHeader;
