import React, { Fragment } from 'react';
import ItemSlot from './itemSlot';

const disableEnchantSlot = [2, 6, 11, 0, 15, 21, 17];

const doll = props => {
  return (
    <Fragment>
      {props.itemSlotData.map((itemSlot, index) => {
        return (
          <ItemSlot
            name={props.itemSlotNames[itemSlot.id]}
            slot={props.itemSlots[itemSlot.id]}
            updateTooltip={props.updateTooltip}
            itemSlot={itemSlot}
            sets={props.sets}
            disableEnchant={disableEnchantSlot.includes(itemSlot.id)}
            enchants={props.characterInfo.curentEnchants}
            data={itemSlot}
            key={index + '-' + itemSlot.name}
            handleClick={props.handleItemSlotClick}
            handleEnchantClick={props.handleEnchantClick}
            resetCurrentItem={props.resetCurrentItem}
            item={
              props.characterInfo.items[itemSlot.name]
                ? props.characterInfo.items[itemSlot.name]
                : []
            }
            currentItem={props.characterInfo.modalData.itemslotname}
          />
        );
      })}
    </Fragment>
  );
};

export default doll;
