import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { atlas } from "./actions/atlasActions";
import ImageMap from "./ui/imageMap";

import "../gearplanner/css/materialize.scss";
import "../gearplanner/css/main.scss";

import "./css/index.scss";

export class Atlas extends Component {
  componentDidMount() {
    this.props.atlas("SET_MAP", "Azeroth");
  }

  render() {
    const atlasState = this.props.state;

    return (
      <Fragment>
        <div className="container">
          <div className="atlas-bg row">
            <div className="row">
              <div className="col s12">
                <div className="atlas-nav">
                  <div className="breadcrumb-holder">
                    {atlasState.breadCrumb.map((crumb) => {
                      return (
                        <div
                          key={"breadcrumb-" + crumb}
                          className="breadcrumb left"
                          id={crumb}
                          onClick={this.breadcrumbClick}
                        >
                          {crumb}
                        </div>
                      );
                    })}
                  </div>
                  {atlasState.mapSatellite && (
                    <div className="right-align">
                      <div className="switch">
                        <label>
                          Map
                          <input
                            type="checkbox"
                            value={atlasState.mapView}
                            checked={
                              atlasState.mapView === "satellite" ? true : false
                            }
                            onChange={this.setMapView}
                          />
                          <span className="lever" />
                          Satellite
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <div className="cwl-map-grid">
                  <ImageMap
                    state={atlasState}
                    onClick={this.onMapClick}
                    enterArea={this.enterArea}
                    leaveArea={this.leaveArea}
                    zoomOut={this.zoomOut}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  setMapView = () => {
    let mapView = this.props.state.mapView;
    if (mapView === "map") {
      this.props.atlas("SET_MAPVIEW", "satellite");
    } else {
      this.props.atlas("SET_MAPVIEW", "map");
    }
  };

  zoomOut = (e) => {
    e.preventDefault();
    if (e.nativeEvent.which === 3) {
      this.props.atlas("SET_MAP", "zoom-out");
    }
  };
  onMapClick = (area) => {
    this.props.atlas("SET_MAP", area.name);
  };
  breadcrumbClick = (e) => {
    this.props.atlas("SET_MAP", e.target.id);
  };
  enterArea = (area) => {
    this.props.atlas("SET_AREA", area);
  };
  leaveArea = () => {
    this.props.atlas("SET_AREA", null);
  };
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, { atlas })(Atlas);
