<template>
  <TemplateSinglePage>
    <div id="react-gearplanner" />
  </TemplateSinglePage>
</template>

<script>
import TemplateSinglePage from '@/components/TemplateSinglePage.vue'
import { createElement } from 'react'
import { createRoot } from 'react-dom/client'

export default {
  name: 'GearPlanner',
  components: {
    TemplateSinglePage
  },
  mounted () {
    this.attachTooltipScript()

    const domContainer = document.querySelector('#react-gearplanner')
    const root = createRoot(domContainer)
    // Load client-side only
    if (typeof window !== 'undefined') {
      const Character = require('@/components/gearplanner').default
      root.render(createElement(Character, { match: this.$route }))
    }
  },
  metaInfo: {
    link: [
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' },
      { rel: 'canonical', href: `${process.env.VUE_APP_BASE_URL}/tools/gearplanner` }
    ],
    script: [
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js' },
      { src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js' }
    ],
    title: 'WoW Classic Gear Planner & Equipment Upgrades',
    meta: [
      { property: 'og:title', vmid: 'og:title', content: 'WoW Classic Gear Planner & Equipment Upgrades' },
      { property: 'og:url', vmid: 'og:url', content: `${process.env.VUE_APP_BASE_URL}/tools/gearplanner` },
      { property: 'og:description', vmid: 'og:description', content: 'Gear Planner for WoW Classic. Plan your character\'s gear, including weighted stats and all phases.' },
      { property: 'description', vmid: 'description', content: 'Gear Planner for WoW Classic. Plan your character\'s gear, including weighted stats and all phases.' }
    ]
  },
  methods: {
    attachTooltipScript () {
      let intervals = 0

      // Wait until jQuery is ready or timed out (5s)
      const jQueryInterval = setInterval(() => {
        if (typeof $ !== 'undefined' || intervals >= 500) {
          // Attach script tag manually
          const tag = document.createElement('script')
          tag.setAttribute('src', 'https://wowclassicdb.com/tooltip.js')
          document.head.appendChild(tag)

          clearInterval(jQueryInterval)
        }

        intervals++
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'https://wowclassicdb.com/tooltip.min.css';
</style>
