import React, { Component } from 'react';
import M from 'materialize-css';

class selectList extends Component {
  componentDidMount () {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
  }

  render () {
    let value = this.props.settings.value;
    if (this.props.settings.data.length === 1 && this.props.settings.multiple) {
      value = this.props.settings.value[0];
    }

    if (!this.props.settings || !this.props.settings.data) return <select />;
    return (
      <select
        className={
          this.props.settings.selectClass
            ? this.props.settings.selectClass
            : 'browser-default'
        }
        value={value}
        onChange={this.props.settings.handleSelectChange}
        multiple={
          !!(this.props.settings.data.length > 1 && this.props.settings.multiple)
        }
      >
        {this.props.settings.data.map(data => (
          <option key={data.id + '-' + data.name} value={data.id}>
            {data.name}
          </option>
        ))}
      </select>
    );
  }
}

export default selectList;
