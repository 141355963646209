<template>
  <TemplateSinglePage :show-loading="!item">
    <div
      v-if="item"
      class="w-full h-full"
    >
      <Breadcrumbs />
      <h1
        id="item-name"
        class="hidden mb-8 text-2xl font-bold md:block text-primary"
      >
        {{ item.name.trim() }}
      </h1>

      <item-overview
        :item="item"
        :misc-info="miscInfo"
      />

      <RelatedTableFilterButtonBase :filter-options="filterOptions" />
    </div>
  </TemplateSinglePage>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ItemOverview from '@/components/ItemOverview.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage.vue'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Item',
  components: {
    TemplateSinglePage,
    ItemOverview,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    return {
      title: this.item?.name,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.item?.name },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:image', vmid: 'og:image', content: `https://cdn.wowclassicdb.com/icons/${this.item?.icon.name}.jpg` },
        { property: 'og:description', vmid: 'og:description', content: this.metaDescription },
        { property: 'description', vmid: 'description', content: this.metaDescription }
      ]
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    metaDescription () {
      return `View ${this.item?.itemQuality?.name} ${this.item?.itemSubType?.name || this.item?.itemSlot?.name} ${this.item?.name} from WoW Classic.`
    },
    filterOptions () {
      return [

        setRelatedTable(this.item?.soldBy, 'soldBy', '', RelatedTableFilterOption.soldBy),
        setRelatedTable(this.item?.loot, 'loot', '', RelatedTableFilterOption.loot),
        setRelatedTable(this.item?.races, 'races', '', RelatedTableFilterOption.races),
        setRelatedTable(this.item?.classes, 'classes', '', RelatedTableFilterOption.classes),
        setRelatedTable(this.item?.lootedFrom, 'lootedFrom', '', RelatedTableFilterOption.lootedFrom),
        setRelatedTable(this.item?.disenchanting, 'disenchanting', '', RelatedTableFilterOption.disenchanting),
        setRelatedTable(this.item?.disenchantedFrom, 'disenchantedFrom', '', RelatedTableFilterOption.disenchantedFrom),
        setRelatedTable(this.item?.unlocks, 'unlocks', '', RelatedTableFilterOption.unlocks),
        setRelatedTable(this.item?.reagentFor, 'reagentFor', '', RelatedTableFilterOption.reagentFor),
        setRelatedTable(this.item?.toolFor, 'toolFor', '', RelatedTableFilterOption.toolFor),
        setRelatedTable(this.item?.droppedBy, 'droppedBy', '', RelatedTableFilterOption.droppedBy),
        setRelatedTable(this.item?.containedIn, 'containedIn', '', RelatedTableFilterOption.containedIn),
        setRelatedTable(this.item?.fishedFrom, 'fishedFrom', '', RelatedTableFilterOption.fishedFrom),
        setRelatedTable(this.item?.fishedInZone, 'fishedInZone', '', RelatedTableFilterOption.fishedIn),
        setRelatedTable(this.item?.pickpocketedBy, 'pickpocketedBy', '', RelatedTableFilterOption.pickpocketedBy),
        setRelatedTable(this.item?.skinnedBy, 'skinnedBy', '', RelatedTableFilterOption.skinnedBy),
        setRelatedTable(this.item?.rewardFromQuests, 'rewardFromQuests', '', RelatedTableFilterOption.rewardFromQuest),
        setRelatedTable(this.item?.createdBy, 'createdBy', '', RelatedTableFilterOption.createdBy),
        setRelatedTable(this.item?.requiredForQuests, 'requiredForQuests', '', RelatedTableFilterOption.objectiveOf),
        setRelatedTable(this.item?.requiredSourceForQuests, 'requiredSourceForQuests', '', RelatedTableFilterOption.objectiveOfSource),
        setRelatedTable(this.item?.canBePlacedIn, 'canBePlacedIn', '', RelatedTableFilterOption.canBePlacedIn),
        setRelatedTable(this.item?.canContain, 'canContain', '', RelatedTableFilterOption.canContain),
        setRelatedTable(this.item?.sameModelAs, 'sameModelAs', '', RelatedTableFilterOption.sameModelAs),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ]
        .filter(Boolean)
    },
    item () {
      return this.$store.state.items[this.$route.params.id]
    },

    miscInfo () {
      const miscInfo = []

      if (this.item.miscInfoLocation) {
        const secondary = this.item.miscInfoLocationSecondary
          ? ` and ${this.item.miscInfoLocationSecondary.name}`
          : ''

        miscInfo.push(
          `Found in ${this.item.miscInfoLocation.name}${secondary}`
        )
      }
      if (this.item.miscInfoMob) {
        miscInfo.push(`Looted from ${this.item.miscInfoMob}`)
      }
      if (this.item.miscInfoDescription1) {
        const subDesc = this.item.miscInfoSubDescription
          ? ` (${this.item.miscInfoSubDescription})`
          : ''
        miscInfo.push(
          `${
            this.item.miscInfoDescription1.startsWith('Sold')
              ? ''
              : 'Correlated to '
          }${this.item.miscInfoDescription1}${subDesc}`
        )
      }
      if (this.item.miscInfoMinLevel > 1) {
        miscInfo.push(`Minimum Level ${this.item.miscInfoMinLevel}`)
      }

      return miscInfo
    }
  },
  serverPrefetch () {
    // Server side fetching
    return this.fetchItem()
  },
  mounted () {
    if (!this.item) this.fetchItem()
  },

  methods: {
    fetchItem () {
      this.$store.commit('setLoading')
      const page = this.$store.state.pageInfo?.currentPage
      return Promise.all([
        this.$store.dispatch('fetchItem', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}
</script>
