import React, { Component } from 'react';

class ItemToolTip extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    let rand = '';
    if (this.props.item && this.props.item[25]) {
      rand = `&rand=${this.props.item[25]}`;
    }
    if (!this.props.itemUrl || !this.props.itemSrc) return null;
    let pcsText = '';
    if (this.props.sets && this.props.currentSetId) {
      this.props.sets[this.props.currentSetId].map(el => {
        pcsText = pcsText + el + ':';
        return null;
      });
    }

    return (
      <a
        className={`${'item-rarity-' + this.props.color} ${
          this.props.classAdd
        }`}
        href={this.props.itemUrl}
        data-wowdb-rename-link="false"
        data-wowhead={`ench=${this.props.enchantId}&pcs=${pcsText}${rand}`}
        rel="external nofollow"
      >
        {/* <img
          alt={this.props.slotName}
          className="item-icon left"
          src={this.props.itemSrc}
        /> */}
        {this.props.children}
      </a>
    );
  }
}

export default ItemToolTip;
