import React, { Component } from "react";
import ImageMapper from "react-image-mapper";

class ImageMap extends Component {
  render() {
    const imageMapState = this.props.state;
    const imageMap = {
      name: imageMapState.mapKey,
      areas: imageMapState.mapImgMap
    };

    if (!imageMapState.mapKey) {
      return false;
    } else {
      return (
        <div className="cwl-map-presenter" onContextMenu={this.props.zoomOut}>
          <ImageMapper
            key={imageMapState.mapKey}
            src={imageMapState.mapUrl}
            map={imageMap}
            width={830}
            height={imageMapState.mapHeight}
            imgWidth={imageMapState.mapImgWidth}
            onClick={area => this.props.onClick(area)}
            onMouseEnter={area => this.props.enterArea(area)}
            onMouseLeave={area => this.props.leaveArea(area)}
          />
          {imageMapState.hoveredArea && imageMap.areas.length > 0 && (
            <span
              className="tooltip"
              style={{ ...this.getTipPosition(imageMapState.hoveredArea) }}
            >
              {imageMapState.hoveredArea && imageMapState.hoveredArea.name}
            </span>
          )}
        </div>
      );
    }
  }

  getTipPosition(area) {
    return { top: `${-35 + area.center[1]}px`, left: `${area.center[0]}px` };
  }
}

export default ImageMap;
