// CSS
import M from 'materialize-css';
import './css/character.scss';
import './css/main.scss';
import './css/materialize.scss';

// components
import React, { Component } from 'react';
import Attributes from './components/character/attributes';
import CharacterInfo from './components/character/characterinfo';
import CharacterDoll from './components/character/doll';
import EnchantModal from './components/character/enchantModal';
import ItemOptions from './components/character/itemtable/itemOptions';
import WeightsModal from './components/character/weights/';
// import AdjustWeightModal from "./components/character/adjustWeightModal";
// import CustomTooltip from "./components/character/customTooltip";
import { createBrowserHistory } from 'history';
import ItemList from './components/character/itemtable/itemList';
import LoadSetup from './components/character/loadSetup';
import { formatName } from './formatName';

// data
const racesImport = require('./data/races.json');
const classesImport = require('./data/classes.json');
const patchesImport = require('./data/patches.json');
const specsImport = require('./data/specs.json');
const buffsImport = require('./data/buffs.json');
const baseLevelImport = require('./data/level.json');
const enchantsImport = require('./data/additionalData/enchant.json');
const crypto = require('crypto');
const cryptoKey = 'vanillaismyfavoriteflavor';
const names = require('./data/weights/name-map.json');

const weightsImport = require('./data/weights/weights.json');
const dependentImport = require('./data/weights/dependent.json');
const nameMapImport = require('./data/weights/name-map.json');
const calculatedImport = require('./data/weights/calculated.json');

const itemData = require('./data/items.json');
const attributeData = require('./data/attributes.json');

const mathParser = require('./data/weights/math-parser');
const setBonuses = require('./data/additionalData/setBonuses.json');
const randomPropertys = require('./data/additionalData/randomProperty.json');
const history = createBrowserHistory();

// items
const itemsImport = {
  head: require('./data/items/head.json'),
  neck: require('./data/items/neck.json'),
  shoulder: require('./data/items/shoulder.json'),
  back: require('./data/items/back.json'),
  chest: require('./data/items/chest.json'),
  wrist: require('./data/items/wrist.json'),
  hands: require('./data/items/hands.json'),
  waist: require('./data/items/waist.json'),
  legs: require('./data/items/legs.json'),
  feet: require('./data/items/feet.json'),
  tabard: require('./data/items/tabard.json'),
  finger: require('./data/items/finger.json'),
  trinket: require('./data/items/trinket.json'),
  mainhand: require('./data/items/mainhand.json'),
  offhand: require('./data/items/offhand.json'),
  ranged: require('./data/items/ranged.json')
};

// Itemslot Data
const itemSlotsMap = itemData.slotsMap;
const itemSlotsNameMap = itemData.slotsNameMap;

const raceBitMask = {
  0: 1,
  1: 4,
  2: 8,
  3: 64,
  4: 2,
  5: 16,
  6: 32,
  7: 128
};

const characterInfo = {
  name: '',
  class: 0,
  race: 0,
  spec: 0,
  lvl: 60,
  phase: 6,
  buffs: [],
  mob: {},
  consumes: {},
  items: {},
  enchants: [],
  curentEnchants: {},
  itemSlots: {},
  modalData: {},
  sumTotals: {},
  percentTotals: {},
  attributes: {},
  itemTypes: {
    armor: [],
    mainhand: [],
    offhand: [],
    ranged: []
  }
};
class CharacterView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      gearSetupName: '',
      buffs: [],
      slotName: '',
      isSumm: true,
      modal: false,
      races: racesImport.data,
      spec: specsImport,
      lvl: this.fillArrLevel(),
      characterInfo: characterInfo,
      sets: {},
      specChanged: false,
      currentSpec: null,
      customWeights: null,
      generateKeyStatus: false,
      activeGroup: 'tanking',
      currentOpenWeightTab: 'TANK',
      activeAttributes: attributeData.groupAttributes.tanking,
      weights: {
        WEAPON_TYPE: null, // weapon type 'mainhand' 'offhand' ...
        DPS_CALCULATED: new Set(),
        EXTENDED_NAME_MAP: null,
        TANK: {
          CHARACTER_KEY: null,
          CHARACTER_WEIGHTS: null
        },
        THREAT: {
          CHARACTER_KEY: null,
          CHARACTER_WEIGHTS: null
        },
        REGULAR: {
          CHARACTER_KEY: null,
          CHARACTER_WEIGHTS: null
        }
      },
      armortypes: itemData.armorTypes,
      talents: [{ id: 0, name: 'soon?™' }],
      props: props,
      el: null,
      itemToolTipcomparedName: '',
      itemToolTipMarkup: {},
      itemToolTipSlotName: '',
      itemToolTipAttr: {},
      // ItemOptions
      itemOptions: {
        itemTypes: [],
        itemTypesValue: [],
        common: [],
        search: '',
        rarities: itemData.rarities.map((rarity) => {
          return rarity.id;
        })
      },
      // ItemList
      filteredItems: [],
      source: itemData.source,
      checkedSource: itemData.source,
      attrs: [],
      items: {},
      filteredSourceItems: {},
      filteredAttributes: {},
      rarity: 0,
      stateAttributes: {},
      itemType: [],
      finalFilter: false,
      savedGearSetups: this.getSavedGearSetups()
    };
    this.resetItems = this.resetItems.bind(this);
  }

  componentDidMount () {
    const observer = new MutationObserver(this.mutationObserverCallback);
    observer.observe(document.getElementsByTagName('body')[0], {
      childList: true
    });

    if (
      this.state.props &&
      this.state.props.match &&
      this.state.props.match.params &&
      this.state.props.match.params.id
    ) {
      this.importLink(this.state.props.match.params.id);
    } else {
      this.setClass(this.state.characterInfo.class);
    }

    const collapsible = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collapsible);
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, { onCloseEnd: this.calculateCurrentItemsValues });
    const tabs = document.querySelectorAll('.tabs');
    M.Tabs.init(tabs);
    const dropdowns = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(dropdowns);
  }

  render () {
    return (
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col s12 gear-planner-options">
              <h4>
                <LoadSetup
                  savedGearSetups={this.state.savedGearSetups}
                  decryptSavedGearSetups={this.decryptSavedGearSetups}
                  handleSave={this.handleSave}
                  delete={this.delete}
                  getSaveName={this.getSaveName}
                />
              </h4>
            </div>
          </div>
            <CharacterInfo
              races={this.state.races}
              classes={classesImport.data}
              specs={this.state.spec}
              levels={this.state.lvl}
              phases={patchesImport}
              buffs={buffsImport.data}
              characterInfo={this.state.characterInfo}
              handleRaceChange={this.handleRaceChange}
              handleClassChange={this.handleClassChange}
              handleLvlChange={this.handleLvlChange}
              handlePhaseChange={this.handlePhaseChange}
              handleSpecChange={this.handleSpecChange}
              handleBuffChange={this.handleBuffChange}
              handleSetName={this.setName}
            />
        </div>
        <div className="tools-bg row character-planner">
          <div className="row">
            <div className="col s12 m8">
              <div className="row">
                <div className="col s12 gear-planner-options">
                  <h4>
                    <div className="left">Your Gear</div>
                    <button
                      className="btn-small right"
                      onClick={this.shareLink}
                    >
                      Copy Link
                      <i className="material-icons right">link</i>
                    </button>
                    <button
                      className="btn-small tooltipped right"
                      data-position="left"
                      data-tooltip="Reset all gear & enchants"
                      onClick={() => (
                        this.resetItems(), this.toast('All items reset')
                      )}
                    >
                     Reset
                     <i className="material-icons right">refresh</i>
                    </button>
                  </h4>
                </div>
              </div>
              <hr />
              <CharacterDoll
                itemSlotData={itemData.slots}
                itemSlotNames={itemData.slotsNameMap}
                itemSlots={itemData.slotsMap}
                itemSlotsImport={itemData.slots}
                handleItemSlotClick={this.handleItemSlotClick}
                updateTooltip={this.updateTooltip}
                sets={this.state.sets}
                characterInfo={this.state.characterInfo}
                resetCurrentItem={this.resetCurrentItem}
                handleEnchantClick={this.handleEnchantClick}
              />
            </div>
            <div className="col s12 m4">
              <h4>{this.state.activeGroup} Stats</h4>
              <hr />
              <Attributes
                data={this.state.characterInfo.attributes}
                activeGroup={this.state.activeGroup}
                handleAttributeClick={this.handleAttributeClick}
                groupAttributesData={attributeData.groupAttributes}
                attributeVerbage={attributeData.verbage}
              />
            </div>
          </div>
          {/* <CustomTooltip state={this.state} addBuffs={this.addBuffs} /> */}
          <EnchantModal
            slotName={this.state.slotName}
            enchants={this.state.characterInfo.enchants}
            handleSetEnchant={this.handleSetEnchant}
            handleRemoveEnchant={this.handleRemoveEnchant}
            currentEnchants={this.state.characterInfo.curentEnchants}
          />
        </div>
        <div className="tools-bg row character-planner">
          <div className="row">
            <div className="col s12">
              <h4>
                Find Items
                {this.state.characterInfo.modalData.itemslotname
                  ? ' -  ' + this.state.characterInfo.modalData.itemslotname
                  : ''}
              </h4>
              <hr />
              {this.state.characterInfo.modalData &&
              this.state.characterInfo.modalData.itemslotname ? (
                  <div>
                    <div className="row">
                      <ItemOptions
                        itemRaritiesData={itemData.rarities}
                        itemRaritiesValue={this.state.itemOptions.rarities}
                        handleItemRaritiesChange={this.handleItemRaritiesChange}
                        itemTypesData={this.state.itemOptions.itemTypes}
                        itemTypesName={
                          this.state.characterInfo.modalData.itemTypeName
                        }
                        itemTypesValue={this.state.itemOptions.itemTypesValue}
                        handleItemTypesChange={this.handleItemTypesChange}
                        handleItemSearch={this.handleItemSearch}
                        itemslotname={
                          this.state.characterInfo.modalData.itemslotname
                        }
                        handleAttributeClick={this.handleAttributeClick}
                        attributesGroup={Object.keys(
                          attributeData.groupAttributes
                        )}
                        activeGroup={this.state.activeGroup}
                      />
                      <div className="col s12 m4 l2 input-field">
                        <h6>
                        Weights{' '}
                          <i
                            className="tiny material-icons tooltipped"
                            data-position="right"
                            data-tooltip="Weights are custom multipliers for an item's total attributes"
                          >
                          info_outline
                          </i>
                        </h6>
                        <button
                          className="btn-small text-center modal-trigger"
                          href="#weights-modal"
                        >
                          <i className="material-icons">edit</i>
                        Adjust
                        </button>
                      </div>
                    </div>
                    <ItemList
                      attributeData={attributeData}
                      groupAttributesData={attributeData.groupAttributes}
                      activeGroup={this.state.activeGroup}
                      tooltipContainer={this.state.el}
                      updateTooltip={this.updateTooltip}
                      sets={this.state.sets}
                      allAttributes={this.state.characterInfo.attributes}
                      handleItemSelect={this.handleItemSelect}
                      slotName={this.state.characterInfo.modalData.itemslotname}
                      filteredItems={this.state.filteredItems}
                      finalFilter={this.state.finalFilter}
                      attrs={this.state.attrs}
                      source={this.state.source}
                      filteredSourceItems={this.state.filteredSourceItems}
                      weightsData={this.state.weights}
                      specChanged={this.state.specChanged}
                      customWeights={this.state.customWeights}
                      currentSpec={this.state.currentSpec}
                      currentItems={this.state.characterInfo.items}
                      class={this.state.characterInfo.class}
                      spec={this.state.characterInfo.spec}
                    />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col s12 valign-wrapper">
                      <i className="small material-icons left">error</i>
                      <div className="left">Choose an item slot to begin</div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="character-planner">
          <WeightsModal
            handleOnChangeWaitsField={this.handleOnChangeWaitsField}
            weightsData={this.state.weights}
            resetWeights={this.resetWeights}
            calculateCurrentItemsValues={this.calculateCurrentItemsValues}
            customWeights={this.state.customWeights}
            currentSpec={this.state.currentSpec}
            class={this.state.characterInfo.class}
            generateKeyStatus={this.state.generateKeyStatus}
            cyptoEncrypt={this.cyptoEncrypt}
            cyptoDecrypt={this.cyptoDecrypt}
            adjustWeightsChangeFields={this.adjustWeightsChangeFields}
            attributeKeys={attributeData.keys}
            groupAttributesData={attributeData.groupAttributes}
            activeGroup={this.state.activeGroup}
            toast={this.toast}
          />
        </div>
      </div>
    );
  }

  toast = (html) => {
    M.toast({ html: html });
  };

  /* ENCHANTS */
  handleEnchantClick = (slotName) => {
    const state = Object.assign({}, this.state);
    if (state.characterInfo.items[slotName]) {
      const itemInSlot = state.characterInfo.items[slotName];
      const itemInSlotId = itemInSlot[8];
      // if Miscellaneous
      if (itemInSlot[4] === 4 && itemInSlot[5] === 0) return;
      state.characterInfo.enchants = this.filterEnchants(itemInSlotId);
      this.setState(state);
    }
  };

  filterEnchants = (slotId) => {
    const result = [];

    let enchantsBySlot = enchantsImport.map[slotId];
    if (slotId === 17) {
      enchantsBySlot = enchantsBySlot.concat(enchantsImport.map[13]);
    }

    if (enchantsBySlot) {
      enchantsBySlot.map((enchantSpellId) => {
        const thisEnchantData = enchantsImport.data[enchantSpellId];
        if (
          thisEnchantData[3] <= this.state.characterInfo.lvl &&
          thisEnchantData[2] <= this.state.characterInfo.phase
        ) {
          if (
            thisEnchantData[4] !== '' &&
            thisEnchantData[4] !== this.state.characterInfo.class
          ) {
            return result;
          }
          result.push(thisEnchantData);
        }
      });
    }

    return result;
  };

  handleRemoveEnchant = () => {
    this.setState((state) => {
      const slotName = state.slotName;
      const curentEnchants = state.characterInfo.curentEnchants;
      if (curentEnchants[slotName]) {
        delete curentEnchants[slotName];
        if (
          typeof this.props !== 'undefined' &&
          typeof history !== 'undefined'
        ) {
          history.push(
              '/tools/gearplanner/' +
              this.getLink()
          );
        }
      } else {
        return state;
      }
      return state;
    }, this.sumAttributes);
  };

  handleSetEnchant = (enchant, event) => {
    event.preventDefault();
    const state = Object.assign({}, this.state);
    const slotName = state.slotName;
    const curentEnchants = state.characterInfo.curentEnchants;
    if (
      curentEnchants.hasOwnProperty(slotName) &&
      curentEnchants[slotName][1] === enchant[1]
    ) {
      this.setState({ enchantModal: false });
    } else {
      state.characterInfo.curentEnchants = {
        ...curentEnchants,
        [slotName]: enchant
      };
      if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
        history.push('/tools/gearplanner/' + this.getLink());
      }
      this.setState(state, this.sumAttributes);
    }
  };

  /* ITEM OPTIONS */
  handleItemRaritiesChange = (e) => {
    const state = Object.assign({}, this.state);
    const rarityType = [...e.target.options]
      .filter((o) => o.selected)
      .map((o) => parseInt(o.value));

    state.itemOptions.rarities = rarityType;
    this.setState(state, () => this.filterItems());
  };

  handleItemTypesChange = (e) => {
    const state = Object.assign({}, this.state);
    const itemTypes = [...e.target.options]
      .filter((o) => o.selected)
      .map((o) => parseInt(o.value));

    state.itemOptions.common = itemTypes;
    state.itemOptions.itemTypesValue = itemTypes;
    this.setState(state, () => this.filterItems());
  };

  getItemTypes = (itemTypeData, itemslotname) => {
    if (!itemTypeData) {
      return [];
    } else {
      const itemTypeFilterData = [];
      itemTypeData.forEach((id) => {
        itemTypeFilterData.push({
          id: id,
          name: this.getItemTypeName(itemslotname, id)
        });
      });
      return itemTypeFilterData;
    }
  };

  adjustWeightToggle = () => {
    this.setState((prevState) => ({
      modalAdjustWeight: !prevState.modal
    }));
  };

  getItemTypeName = (slotname, id) => {
    switch (slotname) {
      case 'mainhand':
        return itemData.weaponTypes[slotname][id];
      case 'offhand':
        return itemData.weaponTypes[slotname][id];
      case 'ranged':
        return itemData.weaponTypes[slotname][id];
      default:
        return itemData.armorTypes[id];
    }
  };

  validClassItemByBitMask = (itemClassBitMask, classBitMask) => {
    let isValid = false;
    let bitMask = [1024, 256, 128, 64, 16, 8, 4, 2, 1];
    let total = itemClassBitMask - classBitMask;
    bitMask = bitMask.filter((id) => id !== classBitMask);
    bitMask.forEach((element) => {
      if (total >= element) {
        total = total - element;
      }
    });
    if (total === 0) isValid = true;
    return isValid;
  };

  validRaceItemByBitMask = (itemRaceBitMask, raceBitMask) => {
    let isValid = false;
    let bitMask = [128, 64, 32, 16, 8, 4, 2, 1];
    let total = itemRaceBitMask - raceBitMask;
    bitMask = bitMask.filter((id) => id !== raceBitMask);
    bitMask.forEach((element) => {
      if (total >= element) {
        total = total - element;
      }
    });
    if (total === 0) isValid = true;
    return isValid;
  };

  /**
   * Filter items by patch number
   * PatchNum must be greater than when item was added
   * Patchnum must be less than when item was removed
   */
  filterItemsByPatchAndClass = (items) => {
    if (!items) return [];
    const filterItems = [];

    const currentPhase = this.state.characterInfo.modalData.phase;
    const currentLevel = this.state.characterInfo.modalData.characterLevel;

    const classBitMask = parseInt(
      this.state.characterInfo.modalData.classbitmask,
      10
    );
    const raceBitMask = parseInt(
      this.state.characterInfo.modalData.racebitmask,
      10
    );

    // if (this.state.characterInfo.modalData.itemTypeName && this.state.characterInfo.modalData.itemTypeName === "armor") {
    //   tempItems = subTypeArr;
    // } else {
    //   tempItems = this.state.items;
    // }

    items.filter((items) => {
      const phase = items[2];
      const itemMinLevel = items[13];
      const itemClassBitMask = parseInt(items[10], 10);
      const itemRaceBitMask = parseInt(items[11], 10);

      // Filter items by patch they were available
      if (
        currentPhase >= phase &&
        itemMinLevel <= currentLevel &&
        // items[7] > 1 &&
        this.validClassItemByBitMask(itemClassBitMask, classBitMask) &&
        this.validRaceItemByBitMask(itemRaceBitMask, raceBitMask)
      ) {
        filterItems.push(items);
      }
      return null;
    });

    return this.sortdesc(filterItems, 9);
  };

  handleItemSearch = (event) => {
    const stateAttributes = Object.assign({}, this.state);
    stateAttributes.itemOptions.search = event.target.value;
    this.setState(stateAttributes, () => this.filterItems());
  };

  clearSearch = () => {
    const stateAttributes = Object.assign({}, this.state);
    this.searchInputRef.current.value = '';
    stateAttributes.search = '';
    this.setState(stateAttributes, () => this.filterItems());
  };

  filterAttr = (items) => {
    const attrs = [];
    let tempAttres = [];
    // const state = this.props.cState;
    items.forEach((item) => {
      let attributes = {};
      if (Array.isArray(item[0])) {
        item.forEach((randomItem) => {
          Object.keys(randomItem[12]).map(
            (key) => (attributes[attributeData.keys[key]] = randomItem[12][key])
          );

          // state.characterInfo.sumTotals[this.state.characterInfo.modalData.itemslotname] = attributes;
          tempAttres.push(attributes);
          // (this.props.summ(true, state));
          attributes = {};
        });
        attrs.push(tempAttres);
        tempAttres = [];
      } else {
        Object.keys(item[12]).map(
          (key) => (attributes[attributeData.keys[key]] = item[12][key])
        );

        // state.characterInfo.sumTotals[this.state.characterInfo.modalData.itemslotname] = attributes;
        attrs.push(attributes); // (this.props.summ(true, state));
      }

      /* Object.keys(item[12]).map(
            key => (attributes[attributeData.keys[key]] = item[12][key])
          );
          state.characterInfo.sumTotals[this.state.characterInfo.modalData.itemslotname] = attributes;
          attrs.push(this.props.summ(true, state)); */
    });
    return attrs;
  };

  createRandomItem = (items) => {
    const superItems = [...items];
    let randomItems = [];
    superItems.forEach((item, index) => {
      if (item[16] && randomPropertys[`${item[16]}`]) {
        randomPropertys[`${item[16]}`].forEach((randomItem) => {
          const itemC = [...item];
          const attrC = { ...item[12] };
          itemC[1] = `${item[1]} ${randomItem[0]}`;
          itemC[12] = Object.assign(attrC, randomItem[1]);
          itemC[25] = randomItem[2] + '';
          randomItems.push(itemC);
        });
        superItems[index] = randomItems;
        randomItems = [];
      }
    });
    return superItems;
  };

  sort = (items, field) => {
    return items.sort((a, b) =>
      a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
    );
  };

  sortdesc = (items, field) => {
    return items.sort((a, b) =>
      a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0
    );
  };

  /* END ITEM OPTIONS */

  changeOpenTab = (newOpenTab) => {
    this.setState({
      currentOpenWeightTab: newOpenTab
    });
  };

  cyptoEncrypt = (data) => {
    var cipher = crypto.createCipher('aes-256-ctr', cryptoKey);
    return cipher.update(data, 'utf8', 'hex') + cipher.final('hex');
  };

  mutationObserverCallback = (data, observ) => {
    for (const key in data) {
      if (
        data[key].addedNodes.length !== 0 &&
        data[key].addedNodes[0].nodeName === 'DIV'
      ) {
        const clases = data[key].addedNodes[0].classList;
        if (clases.value === 'wowhead-tooltip') {
          observ.disconnect();
          this.setState({ el: data[0].addedNodes[0] });
          return data[0].addedNodes[0];
        }
      }
    }
  };

  handleAttributeClick = (event) => {
    const attributeGroup = event.target.id;
    this.setState({
      activeGroup: attributeGroup,
      activeAttributes: attributeData.groupAttributes[attributeGroup]
    });
  };

  cyptoDecrypt = (data) => {
    try {
      var cipher = crypto.createDecipher('aes-256-ctr', cryptoKey);
      return cipher.update(data, 'hex', 'utf8') + cipher.final('utf8');
    } catch (error) {
      return false;
    }
  };

  fillArrLevel = () => {
    const result = [];
    for (let i = 60; i >= 1; i--) {
      result.push({
        id: i,
        name: `${i}`
      });
    }
    return result;
  };

  importLink = (params) => {
    let stateAttributes = Object.assign({}, this.state);
    const decryptedParams = this.cyptoDecrypt(params);
    const parsed = JSON.parse(decryptedParams);

    if (parsed && parsed.length > 0) {
      stateAttributes.characterInfo.class = parsed[0];
      stateAttributes.characterInfo.race = parsed[1];
      stateAttributes.races = this.getRacesByClass(
        classesImport.data[parsed[0]].races
      );
      stateAttributes.characterInfo.phase = parsed[2];
      stateAttributes.characterInfo.spec = parsed[3];
      stateAttributes.spec = this.getSpecsByClass(
        classesImport.data[parsed[0]].specs
      );
      stateAttributes.characterInfo.lvl = parsed[4];
      stateAttributes.characterInfo.name = parsed[7] ? parsed[7] : '';

      parsed[5].map((item) => {
        const slotId = item[0];
        const itemId = item[1];
        const randomIndex = item[2];

        if (itemId !== 0 || itemId !== '') {
          const itemArr = this.getItem(slotId, itemId);

          if (itemArr.length > 0) {
            const slotName = itemSlotsNameMap[slotId];
            // get tooltip markup

            if (itemArr[0][16] !== 0) {
              const randomItem =
                randomPropertys[String(itemArr[0][16])][randomIndex];
              const itemC = [...itemArr[0]];
              const attrC = { ...itemC[12] };
              itemC[1] = `${itemC[1]} ${randomItem[0]}`;
              itemC[12] = Object.assign(attrC, randomItem[1]);
              itemC[25] = randomItem[2] + '';
              itemArr[0] = itemC;
              stateAttributes.characterInfo.items[slotName] = itemArr[0];
            } else {
              stateAttributes.characterInfo.items[slotName] = itemArr[0];
            }
            // set to items array
            //this.requestToWowHeadTooltip(itemId, slotName, itemArr[0]);

            // set to sumtotals
            if (itemArr[0][12]) {
              const attributes = {};
              Object.keys(itemArr[0][12]).map(
                (key) =>
                  (attributes[attributeData.keys[key]] = itemArr[0][12][key])
              );
              stateAttributes = this.pullSetbonuses(stateAttributes);
              stateAttributes.characterInfo.sumTotals[slotName] = attributes;
            }
          }
        }
        return null;
      });

      parsed[6].map((item) => {
        const slotId = item[0];
        const enchantId = item[1];
        if (enchantId !== 0 || enchantId !== '') {
          const enchantArr = this.getEnchants(enchantId);
          if (enchantArr.length > 0) {
            const slotName = itemSlotsNameMap[slotId];
            // set enchant
            stateAttributes.characterInfo.curentEnchants[slotName] =
              enchantArr[0];
          }
        }
        return null;
      });
      stateAttributes = this.setSpec(
        this.state.characterInfo.spec,
        stateAttributes
      );
      this.setDefaultItemTypes(
        stateAttributes,
        stateAttributes.characterInfo.class
      );
      this.updateState(
        stateAttributes,
        (() => {
          this.setRace(parsed[1]);
        })()
      );
    }
  };

  getEnchants = (enchantId) => {
    return enchantsImport.data[enchantId]
      ? [enchantsImport.data[enchantId]]
      : [];
  };

  getItem = (slotId, itemId) => {
    const items = itemsImport[itemSlotsMap[slotId]];
    const filterItems = [];
    if (items) {
      items.filter((item) => {
        if (item[0] === itemId) {
          filterItems.push(item);
        }
        return null;
      });
    }
    return filterItems;
  };

  getLink = () => {
    const shareLink = [];
    shareLink.push(this.state.characterInfo.class);
    shareLink.push(this.state.characterInfo.race);
    shareLink.push(this.state.characterInfo.phase);
    shareLink.push(this.state.characterInfo.spec);
    shareLink.push(this.state.characterInfo.lvl);
    const tempArr = [];
    const ench = [];

    itemData.slots.map((itemSlot) => {
      const itemArr = [];
      const enchantsArr = [];
      let randomIndex = -1;

      const itemId = this.state.characterInfo.items[itemSlot.name]
        ? this.state.characterInfo.items[itemSlot.name][0]
        : 0;
      const enchantId = this.state.characterInfo.curentEnchants[itemSlot.name]
        ? this.state.characterInfo.curentEnchants[itemSlot.name][5]
        : 0;

      const itemInSlot = this.state.characterInfo.items[itemSlot.name];
      if (Array.isArray(itemInSlot) && itemInSlot[16] !== 0) {
        const name = itemInSlot[1].match(/of\s.+$/)[0];
        const propArr = randomPropertys[String(itemInSlot[16])];
        propArr.forEach((item, index) => {
          if (item.includes(name)) randomIndex = index;
        });
      }

      if (enchantId !== 0) {
        enchantsArr.push(itemSlot.id);
        enchantsArr.push(enchantId);
        ench.push(enchantsArr);
      }
      if (itemId !== 0) {
        itemArr.push(itemSlot.id);
        itemArr.push(itemId);
        itemArr.push(randomIndex);
        tempArr.push(itemArr);
      }

      return null;
    });

    shareLink.push(tempArr);
    shareLink.push(ench);
    shareLink.push(this.state.characterInfo.name);
    const encryptedShareLink = this.cyptoEncrypt(JSON.stringify(shareLink));
    return encryptedShareLink;
  };

  setName = (e) => {
    const value = formatName(e.target.value).replace(/[^a-zA-Z0-9 -]/g, '');
    const stateAttributes = Object.assign({}, this.state);
    stateAttributes.characterInfo.name = value;
    this.setState(stateAttributes);
  };

  decryptSavedGearSetups = (savedGearSetup) => {
    return JSON.parse(this.cyptoDecrypt(savedGearSetup));
  };

  getSavedGearSetups = () => {
    return localStorage.getItem('cwl-gear-planner');
  };

  getSaveName = () => {
    let saveName = this.state.characterInfo.name;
    if (!saveName) {
      saveName =
        'p' +
        this.state.characterInfo.phase +
        '-' +
        racesImport.map[this.state.characterInfo.race] +
        '-' +
        classesImport.map[this.state.characterInfo.class];
    }
    return saveName;
  };

  handleSave = () => {
    const savedGearSetups = this.getSavedGearSetups();
    let newSetups = {};

    if (savedGearSetups) {
      newSetups = JSON.parse(this.cyptoDecrypt(savedGearSetups));
    }
    const saveLink = this.getLink();
    const saveName = this.getSaveName();
    newSetups[saveName] = saveLink;

    if (newSetups) {
      const savedGearSetups = this.cyptoEncrypt(JSON.stringify(newSetups));
      localStorage.setItem('cwl-gear-planner', savedGearSetups);
      this.setState(
        { savedGearSetups: savedGearSetups },
        this.toast('Saved gear set: ' + this.getSaveName().toUpperCase())
      );
    }
  };

  delete = () => {
    const savedGearSetups = this.getSavedGearSetups();
    // Get the current saved setups and decrypt them
    if (savedGearSetups) {

    const currentSetups = JSON.parse(this.cyptoDecrypt(savedGearSetups));
    
    const saveName = this.getSaveName();

    if (currentSetups && currentSetups[saveName]) {
      delete currentSetups[saveName];
      const savedGearSetups = this.cyptoEncrypt(JSON.stringify(currentSetups));
      localStorage.setItem('cwl-gear-planner', savedGearSetups);
      this.setState(
        { savedGearSetups: savedGearSetups },
        this.toast('Deleted gear set: ' + this.getSaveName().toUpperCase())
      );
    }
  }
  };

  handleSetGearSetupName = (e) => {
    const value = formatName(e.target.value).replace(/[^a-zA-Z0-9 -]/g, '');
    this.setState({ gearSetupName: value });
  };

  shareLink = () => {
    var copyText = document.createElement('input');
    document.body.appendChild(copyText);
    copyText.value = window.location.href;
    copyText.select();
    document.execCommand('copy');
    document.body.removeChild(copyText);
    this.toast('Link copied to clipboard');
  };

  updateTooltip = (name) => {
    // this.setState(({ itemToolTipcomparedName }) => {
    //   if (itemToolTipcomparedName !== name) {
    //     return { itemToolTipcomparedName: name };
    //   }
    // });
  };

  updateAttrToTooltip = (attrs, items) => {
    const itemToolTipAttr = {};
    items.forEach((item, index) => {
      if (Array.isArray(item[0])) {
        item.forEach((itemIn, indexIn) => {
          itemToolTipAttr[itemIn[1]] = { ...attrs[index][indexIn] };
        });
      } else {
        itemToolTipAttr[item[1]] = { ...attrs[index] };
      }
    });
    this.setState({ itemToolTipAttr });
  };

  resetWeights = () => {
    const customWeights = { ...this.state.customWeights };
    if (this.state.customWeights !== null) {
      for (const key in customWeights) {
        if (key.split(', ')[1] === this.state.currentSpec) {
          delete customWeights[key];
        }
      }
    }
    this.setState(
      {
        customWeights,
        specChanged: !this.state.specChanged
      },
      this.toast('Weights reset to default values')
    );
  };

  calculateCurrentItemsValues = () => {
    this.setState(
      {
        specChanged: !this.state.specChanged
      },
      this.toast('Calculated values')
    );
  };

  adjustWeightsChangeFields = (values) => {
    let calcs = null;
    const customWeights = { ...this.state.customWeights };

    let curClassName = '';
    classesImport.data.forEach((el) => {
      if (el.id === this.state.characterInfo.class) {
        curClassName = el.name.toUpperCase();
      }
    });

    const customWeightsKey = `${curClassName}, ${this.state.currentSpec}`;

    customWeights[customWeightsKey] = JSON.parse(
      JSON.stringify(this.state.weights)
    );

    for (const val in values) {
      let valueKey = '';
      switch (+val) {
        case 0:
          valueKey = 'REGULAR';
          break;
        case 1:
          valueKey = 'TANK';
          break;
        case 2:
          valueKey = 'THREAT';
          break;
        default:
          break;
      }

      const currentCustomWeights = JSON.parse(
        JSON.stringify(customWeights[customWeightsKey])
      );

      for (const key in values[val]) {
        let funKey = '';
        for (const name in names) {
          if (name.toUpperCase() === attributeData.keys[key].toUpperCase()) {
            funKey = name;
          }
        }

        currentCustomWeights[valueKey].CHARACTER_WEIGHTS[funKey] =
          values[val][key];
        for (const key in calculatedImport) {
          if (
            key.toUpperCase() ===
            currentCustomWeights[valueKey].CHARACTER_KEY.toUpperCase()
          ) {
            calcs = calculatedImport[key];
          }
        }
        Object.keys(calcs).forEach((field) => {
          const evaulated = mathParser(
            calcs[field],
            currentCustomWeights[valueKey].CHARACTER_WEIGHTS
          );
          currentCustomWeights[valueKey].CHARACTER_WEIGHTS[field] = evaulated;
        });
        customWeights[customWeightsKey] = currentCustomWeights;
      }

      this.setState({
        generateKeyStatus: !this.state.generateKeyStatus,
        customWeights
      });
    }
  };

  handleOnChangeWaitsField = (type, key, cKey, event) => {
    const value = event.target.value;

    const keys = cKey.split(', ');
    const specialisation = `${keys[0]}, ${keys[1]}`;

    let customWeights = {};
    if (
      this.state.customWeights !== null &&
      this.state.customWeights[specialisation]
    ) {
      customWeights = JSON.parse(JSON.stringify(this.state.customWeights));
    } else if (this.state.customWeights !== null) {
      customWeights = JSON.parse(JSON.stringify(this.state.customWeights));
      customWeights[specialisation] = JSON.parse(
        JSON.stringify(this.state.weights)
      );
    } else {
      customWeights[specialisation] = JSON.parse(
        JSON.stringify(this.state.weights)
      );
    }

    const currentCustomWeights = customWeights[specialisation];

    if (key === 'Duration' && type === 'TANK') {
      currentCustomWeights.TANK.CHARACTER_WEIGHTS[key] = value;
      currentCustomWeights.THREAT.CHARACTER_WEIGHTS[key] = value;
    } else if (key === 'Duration' && type === 'THREAT') {
      currentCustomWeights.TANK.CHARACTER_WEIGHTS[key] = value;
      currentCustomWeights.THREAT.CHARACTER_WEIGHTS[key] = value;
    } else {
      currentCustomWeights[type].CHARACTER_WEIGHTS[key] = value;
    }

    let calcs = null;
    for (const key in calculatedImport) {
      if (
        key.toUpperCase() ===
        currentCustomWeights[type].CHARACTER_KEY.toUpperCase()
      ) {
        calcs = calculatedImport[key];
      }
    }

    Object.keys(calcs).forEach((field) => {
      const evaulated = mathParser(
        calcs[field],
        currentCustomWeights[type].CHARACTER_WEIGHTS
      );

      currentCustomWeights[type].CHARACTER_WEIGHTS[field] = evaulated;
    });
    customWeights[specialisation] = currentCustomWeights;

    this.setState({
      generateKeyStatus: !this.state.generateKeyStatus,
      customWeights,
      currentEditSpec: cKey
    });

    return null;
  };

  // function for update states Bonuses
  pullSetbonuses = (stateAttributes) => {
    const activeItems = { ...stateAttributes.characterInfo.items };
    const locatStateAttributes = stateAttributes;

    let newSet = new Set();
    for (const i in activeItems) {
      if (activeItems[i][15] !== 0) {
        newSet.add(activeItems[i][15]);
      }
    }

    newSet = [...newSet];
    newSet.forEach((id) => {
      const setItemsId = [];
      for (const key in activeItems) {
        if (activeItems[key][15] === id) {
          setItemsId.push(activeItems[key][0]);
          locatStateAttributes.sets[activeItems[key][15]] = setItemsId;
        }
      }
    });
    return locatStateAttributes;
  };

  handleItemSelect = (item, name, event) => {
    event.preventDefault();
    if (event.target.className === 'spread__button') return;
    let stateAttributes = Object.assign({}, this.state);

    if (item[12] && item[12][88]) {
      const id = item[0];
      const keys = Object.keys(stateAttributes.characterInfo.items);
      for (const key of keys) {
        if (id === stateAttributes.characterInfo.items[key][0]) return;
      }
    }

    // check for attributes
    if (item[12]) {
      const attributes = {};
      Object.keys(item[12]).map(
        (key) => (attributes[attributeData.keys[key]] = item[12][key])
      );
      stateAttributes.characterInfo.sumTotals[name] = attributes;
    }

    if (name === 'mainhand') {
      const twohand = [1, 5, 6, 8, 10, 20];
      if (twohand.indexOf(item[5]) !== -1) {
        delete stateAttributes.characterInfo.items.offhand;
      }
    }
    if (name === 'offhand') {
      const twohand = [1, 5, 6, 8, 10, 20];
      const mainhand = stateAttributes.characterInfo.items.mainhand;
      if (mainhand && twohand.indexOf(mainhand[5]) !== -1) {
        delete stateAttributes.characterInfo.items.mainhand;
      }
    }

    // set item
    stateAttributes.characterInfo.items[name] = item;

    // add setBonuse information to state
    stateAttributes = this.pullSetbonuses(stateAttributes);

    // delete enchants if Item_Subtype_ID === Miscellaneous
    if (item[4] === 4 && (item[5] === 0 || item[5] === 23)) {
      delete stateAttributes.characterInfo.curentEnchants[name];
    }

    //    this.requestToWowHeadTooltip(item[0], name, item);

    this.updateState(stateAttributes, this.sumAttributes());

    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  //requestToWowHeadTooltip = async (id, slotName, item) => {
  //  let rand = '';
  //  if (item && item[25]) {
  //    rand = `&rand=${item[25]}`;
  //  }
  //  return fetch(`https://classic.wowhead.com/tooltip/item/${id}${rand}`)
  //    .then((response) => {
  //      const jsonResponce = response.json();
  //      return jsonResponce;
  //    })

  //    .then((response) => {
  //      this.setState({
  //        itemToolTipMarkup: {
  //          ...this.state.itemToolTipMarkup,
  //          [slotName]: response.tooltip
  //        }
  //      });
  //    });
  //};

  // get weapon, offhand and ranged types
  getCharacterInfoByKey = (key) => {
    return classesImport.data[this.state.characterInfo.class][key];
  };

  handleItemSlotClick = (event) => {
    event.preventDefault();
    if (event.currentTarget && event.currentTarget.id) {
      const slotName = event.currentTarget.id.split('_')[0];
      const slotId = event.currentTarget.id.split('_')[1];
      const itemTypeName = this.getSlotFilterType(slotId);
      const modalData = {};
      const items = itemsImport[itemSlotsMap[slotId]];

      modalData.characterLevel = this.state.characterInfo.lvl;
      modalData.itemslotname = slotName;
      modalData.itemslot = slotId;
      modalData.phase = this.state.characterInfo.phase;
      modalData.items = items;
      modalData.classbitmask =
        classesImport.data[this.state.characterInfo.class].bitmask;
      modalData.racebitmask = raceBitMask[this.state.characterInfo.race];
      modalData.itemTypeName = itemTypeName;
      modalData.handleItemSelect = this.handleItemSelect;

      if (itemTypeName === 'armor') {
        modalData.itemTypeData = this.getCharacterInfoByKey('armortypes');
      } else {
        if (slotName === 'mainhand') {
          modalData.itemTypeData = this.getCharacterInfoByKey(
            'meleeweapontypes'
          );
        } else if (slotName === 'offhand') {
          modalData.itemTypeData = this.getCharacterInfoByKey(
            'offhandweapontypes'
          );
        } else if (slotName === 'ranged') {
          modalData.itemTypeData = this.getCharacterInfoByKey(
            'rangedweapontypes'
          );
        }
      }

      const stateObj = Object.assign({}, this.state);
      stateObj.slotName = itemData.slotsMap[slotId];
      stateObj.slotId = slotId;
      stateObj.characterInfo.modalData = modalData;
      stateObj.itemToolTipSlotName = slotName;
      stateObj.itemOptions.itemTypes = this.getItemTypes(
        modalData.itemTypeData,
        slotName
      );

      stateObj.itemOptions.itemTypesValue = this.getItemTypesValueDefault(
        stateObj.itemOptions.itemTypes,
        slotName
      );
      this.setState(stateObj, () => this.filterItems());
    }
  };

  getItemTypesValueDefault = (itemTypes, slotName) => {
    let itemTypesValueDefault = [];
    const spec = this.state.characterInfo.spec;

    if (
      itemData.specItemDefaults[spec] &&
      itemData.specItemDefaults[spec][slotName]
    ) {
      itemTypesValueDefault = itemData.specItemDefaults[spec][slotName];
    } else {
      itemTypesValueDefault = itemTypes.map((itemType) => {
        return itemType.id;
      });
    }

    return itemTypesValueDefault;
  };

  // setItems = () => {
  //   // Filter items between patches
  //   let filterItemsByPatch = this.filterItemsByPatchAndClass(
  //     this.state.characterInfo.modalData.items
  //   );
  //   const checkedSource = { ...this.state.checkedSource };

  //   const filtredItemsBySource = filterItemsByPatch.filter(item => {
  //     if (checkedSource[item[14][0]]) {
  //       return item;
  //     }
  //     return null;
  //   });
  //   filterItemsByPatch = filtredItemsBySource;

  //   //Добавить по лвлу
  //   let stateObj = Object.assign({}, this.state);
  //   stateObj["items"] = filterItemsByPatch;
  //   stateObj["filteredItems"] = filterItemsByPatch;
  //   this.setState(stateObj, () => this.filterItems());
  // };

  filterItems = () => {
    const state = Object.assign({}, this.state);
    const rarityArr = [];
    const searchArr = [];
    const subTypeArr = [];
    let tempItems = itemsImport[this.state.slotName];
    const rarities = this.state.itemOptions.rarities;
    const itemTypes = this.state.itemOptions.itemTypesValue;
    const itemSearch = this.state.itemOptions.search;
    // Filter items by patch & class
    let filterItemsByPatchAndClass = this.filterItemsByPatchAndClass(tempItems);

    const checkedSource = { ...this.state.checkedSource };

    // Filter items by patch & class
    const filtredItemsBySource = filterItemsByPatchAndClass.filter((item) => {
      if (checkedSource[item[14][0]]) {
        return item;
      }
      return null;
    });
    filterItemsByPatchAndClass = filtredItemsBySource;
    state.items = filterItemsByPatchAndClass;
    tempItems = filterItemsByPatchAndClass;
    if (tempItems && itemTypes && itemTypes.length !== 0) {
      tempItems.filter((items) => {
        itemTypes.forEach((id) => {
          if (id === items[5]) {
            subTypeArr.push(items);
          }
          return null;
        });
        return null;
      });
      tempItems = subTypeArr;
    } else if (
      itemTypes &&
      itemTypes.length === 0 &&
      this.state.characterInfo.modalData.itemTypeName
    ) {
      tempItems = [];
    }

    if (tempItems && rarities.length !== 0) {
      tempItems.filter((items) => {
        rarities.forEach((id) => {
          if (parseInt(items[7], 10) === parseInt(id, 10)) {
            rarityArr.push(items);
          }
        });
        return null;
      });
      tempItems = rarityArr;
    } else {
      tempItems = [];
    }

    if (tempItems && itemSearch !== '') {
      tempItems.filter((items) => {
        // check if item name matches search value
        if (items[1].toLowerCase().indexOf(itemSearch.toLowerCase()) !== -1) {
          searchArr.push(items);
        }
        return null;
      });
      tempItems = searchArr;
    }

    if (tempItems) {
      state.filteredSourceItems = tempItems;
      state.filteredItems = this.createRandomItem(tempItems);
      state.attrs = this.filterAttr(state.filteredItems);

      state.attrs = this.addBuffs(state.attrs);
      state.finalFilter = !state.finalFilter;

      //      this.updateAttrToTooltip(state.attrs, state.filteredItems);
      this.setState(state);
    }
  };

  // Used for determining what to use for filtering
  getSlotFilterType = (slotId) => {
    let name = 'armor';
    const armorSlotIds = ['1', '3', '5', '6', '7', '8', '9', '10'];
    const weaponSlotIds = ['13', '19'];
    const rangedSlotIds = ['20'];

    if (armorSlotIds.indexOf(slotId) !== -1) {
      name = 'armor';
    } else if (weaponSlotIds.indexOf(slotId) !== -1) {
      name = 'weapon';
    } else if (rangedSlotIds.indexOf(slotId) !== -1) {
      name = 'ranged';
    }
    return name;
  };

  /**
   * The onchange method from class selector
   */
  handleClassChange = (event) => {
    if (!event.target.value) return;
    // set selected class
    this.setClass(parseInt(event.target.value, 10));

    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  handleLvlChange = (event) => {
    if (!event.target.value) return;
    const level = parseInt(event.target.value, 10);

    this.setLevel(level);
    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  handleSpecChange = (event) => {
    if (!event.target.value) return;
    // set selected spec
    this.setSpec(parseInt(event.target.value, 10));

    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  handleBuffChange = (e) => {
    const stateObj = Object.assign({}, this.state);
    const buffs = [...e.target.options]
      .filter((o) => o.selected)
      .map((o) => parseInt(o.value));
    stateObj.characterInfo.buffs = buffs;
    this.updateState(stateObj, this.sumAttributes());
  };

  resetItems = () => {
    let stateObj = Object.assign({}, this.state);
    stateObj = this.clearCharacterInfo(stateObj);
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
    this.updateState(stateObj, this.setRace(stateObj.characterInfo.race));
  };

  clearCharacterInfo = (obj) => {
    obj.itemToolTipMarkup = {};
    obj.characterInfo.itemSlots = {};
    obj.characterInfo.sumTotals = {};
    obj.characterInfo.modalData = {}; // { armortype: classesImport.data[obj.characterInfo.class]["armortypes"][0] };
    obj.characterInfo.items = {};
    obj.characterInfo.curentEnchants = [];
    return obj;
  };

  setDefaultItemTypes = (state, classId) => {
    state.characterInfo.itemTypes.armor =
      classesImport.data[classId].armortypes;
    state.characterInfo.itemTypes.mainhand =
      classesImport.data[classId].meleeweapontypes;
    state.characterInfo.itemTypes.offhand =
      classesImport.data[classId].offhandweapontypes;
    state.characterInfo.itemTypes.ranged =
      classesImport.data[classId].rangedweapontypes;
  };

  setClass = (classId) => {
    let stateObj = Object.assign({}, this.state);

    // reset character information
    stateObj = this.clearCharacterInfo(stateObj);

    stateObj.characterInfo.class = classId;
    // set default armor and weapon type
    this.setDefaultItemTypes(stateObj, classId);

    // set default armortype for

    // stateObj.characterInfo.modalData.armortype = classesImport.data[classId]["armortypes"][0];
    // get races by selected class
    stateObj.races = this.getRacesByClass(classesImport.data[classId].races);
    // get specs by selected class
    stateObj.spec = this.getSpecsByClass(classesImport.data[classId].specs);
    // посмотреть внимательно !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    stateObj = this.setSpec(stateObj.spec[0].id, stateObj);
    // set to state
    stateObj.characterInfo.itemSlots = itemData.slots;

    this.updateState(stateObj, this.setRace(stateObj.races[0].id));
  };

  setSpec = (specId, stateObj) => {
    if (!stateObj) {
      const stateObj = { ...this.state };
      stateObj.characterInfo.spec = specId;
      const currentClassName =
        classesImport.data[this.state.characterInfo.class].name;
      const currentSpec = specsImport[stateObj.characterInfo.spec].name;
      stateObj.currentSpec = currentSpec.toUpperCase();
      stateObj.weights = this.makeWeights(currentClassName, currentSpec);
      stateObj.specChanged = !stateObj.specChanged;
      stateObj.activeGroup = specsImport[stateObj.characterInfo.spec].activeGroup;
      this.setState(stateObj);
      return null;
    }
    stateObj.characterInfo.spec = specId;
    const currentClassName =
      classesImport.data[this.state.characterInfo.class].name;
    const currentSpec = specsImport[stateObj.characterInfo.spec].name;
    stateObj.currentSpec = currentSpec.toUpperCase();
    stateObj.weights = this.makeWeights(currentClassName, currentSpec);
    stateObj.specChanged = !stateObj.specChanged;
    stateObj.activeGroup = specsImport[stateObj.characterInfo.spec].activeGroup;

    return stateObj;
  };

  makeWeights = (pClass, spec, weaponType) => {
    const baseFills = weightsImport.filter(
      (weight) =>
        weight.Class.toUpperCase() === pClass.toUpperCase() &&
        weight.Spec.toUpperCase() === spec.toUpperCase()
    );
    if (!baseFills) return 1;

    const state = this.state.weights;
    state.TANK = {
      CHARACTER_KEY: null,
      CHARACTER_WEIGHTS: null
    };
    state.REGULAR = {
      CHARACTER_KEY: null,
      CHARACTER_WEIGHTS: null
    };
    state.THREAT = {
      CHARACTER_KEY: null,
      CHARACTER_WEIGHTS: null
    };
    baseFills.forEach((base) => {
      if (weaponType) this.groupWeapon(weaponType, base);
      const pointType = base.Point_Type;
      state[pointType.toUpperCase()].CHARACTER_KEY = [
        pClass.toUpperCase(),
        spec.toUpperCase(),
        pointType.toUpperCase()
      ].join(', ');
      state[pointType.toUpperCase()].CHARACTER_WEIGHTS = base || {};
    });

    state.WEAPON_TYPE = weaponType || 'One-Hand';
    state.EXTENDED_NAME_MAP = Object.assign(
      dependentImport[state.WEAPON_TYPE],
      nameMapImport
    );
    return state;
  };

  groupWeapon = (weaponType, weights) => {
    const fields = [
      'DPS',
      'Weapon_Speed',
      'DPS_Offhand',
      'WeapSpeed_OH',
      'Range_DPS',
      'RWeapon_Speed'
    ];
    const map = {
      'Two-Hand': ['DPS', 'Weapon_Speed'],
      'One-Hand': ['DPS_Offhand', 'WeapSpeed_OH'],
      Ranged: ['Range_DPS', 'RWeapon_Speed']
    };

    fields.forEach((field) => {
      if (map[weaponType].includes(field)) {
        weights[field.includes('DPS') ? 'DPS' : 'Weapon_Speed'] =
          weights[field];
      }
    });

    fields.slice(2).forEach((field) => {
      delete weights[field];
    });

    return weights;
  };

  /**
    After class is chosen, determine which races are per class
  */
  getRacesByClass = (classRaces) => {
    const allRaces = racesImport.data;
    const newRaces = allRaces.filter((race) => classRaces.includes(race.id));
    return newRaces;
  };

  /**
    After class is chosen, determine which specs are per class
  */
  getSpecsByClass = (classSpecs) => {
    const allSpecs = specsImport;
    const newSpecs = allSpecs.filter((spec) => classSpecs.includes(spec.id));
    return newSpecs;
  };

  /**
   * The onchange method from race selector
   */
  handleRaceChange = (event) => {
    if (!event.target.value) return;
    // set selected class
    this.setRace(parseInt(event.target.value, 10));
    //this.resetItems();
    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  setRace = (raceId) => {
    const stateObj = Object.assign({}, this.state);
    // set Race
    stateObj.characterInfo.race = raceId;
    // get race data
    const raceData = racesImport.data[raceId];
    const raceName = racesImport.data[raceId].name;
    // Set Base attributes
    if (
      raceData.attributes &&
      raceData.attributes.class &&
      raceData.attributes.class[this.state.characterInfo.class]
    ) {
      stateObj.characterInfo.sumTotals.baseAttributes =
        baseLevelImport[raceName][this.state.characterInfo.class][
          this.state.characterInfo.lvl - 1
        ];
      // raceData.attributes.class[this.state.characterInfo.class];
    } else {
      this.resetItems()
    }
    // set race to state
    this.updateState(stateObj, this.sumAttributes());
  };

  setLevel = (lvl) => {
    let stateObj = Object.assign({}, this.state);
    stateObj.characterInfo.lvl = lvl;

    stateObj = this.resetItemsByLevel(stateObj, lvl);
    stateObj = this.resetEnchantByLevel(stateObj, lvl);

    const raceName = this.getRaceNameById(stateObj.characterInfo.race);
    stateObj.characterInfo.sumTotals.baseAttributes =
      baseLevelImport[raceName][this.state.characterInfo.class][
        this.state.characterInfo.lvl - 1
      ];

    // reset modalData
    stateObj.characterInfo.modalData = {};

    this.updateState(stateObj, this.sumAttributes());
  };

  handlePhaseChange = (event) => {
    if (!event.target.value) return;
    let stateObj = Object.assign({}, this.state);
    const phase = Number(event.target.value);
    stateObj = this.resetItemsByPhase(stateObj, phase);
    stateObj = this.resetEnchantByPhase(stateObj, phase);

    stateObj.characterInfo.phase = phase;

    // reset modalData
    stateObj.characterInfo.modalData = {};

    this.updateState(stateObj, this.sumAttributes());

    // update the url
    if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
      history.push('/tools/gearplanner/' + this.getLink());
    }
  };

  resetCurrentItem = (name) => {
    const stateObj = Object.assign({}, this.state);
    if (name) {
      const itemId = stateObj.characterInfo.items[name][0];
      for (const i in stateObj.sets) {
        if (stateObj.sets[i].includes(itemId)) {
          const index = stateObj.sets[i].indexOf(itemId);
          stateObj.sets[i].splice(index, 1);
        }
      }

      delete stateObj.characterInfo.items[name];
      delete stateObj.characterInfo.sumTotals[name];
      // delete murkup
      delete stateObj.itemToolTipMarkup[name];
      if (stateObj.characterInfo.curentEnchants[name]) {
        delete stateObj.characterInfo.curentEnchants[name]; // Нужно ли снимать энчант при удалении вещи по крестику???
      }

      if (typeof this.props !== 'undefined' && typeof history !== 'undefined') {
        history.push('/tools/gearplanner/' + this.getLink());
      }
      this.updateState(stateObj, this.sumAttributes());
    }
    return stateObj;
  };

  resetItemsByPhase = (obj, phase) => {
    if (obj.characterInfo.items) {
      Object.keys(obj.characterInfo.items).map((itemName) => {
        const items = obj.characterInfo.items[itemName];
        const itemPhase = items[2];
        if (itemPhase !== phase && phase < itemPhase) {
          delete obj.characterInfo.items[itemName];
          delete obj.characterInfo.sumTotals[itemName];
          // delete murkup
          delete obj.itemToolTipMarkup[itemName];
          if (obj.characterInfo.curentEnchants[itemName]) {
            delete obj.characterInfo.curentEnchants[itemName];
          }
        }
        return null;
      });
    }
    return obj;
  };

  resetItemsByLevel = (obj, level) => {
    if (obj.characterInfo.items) {
      Object.keys(obj.characterInfo.items).map((itemName) => {
        const items = obj.characterInfo.items[itemName];
        const itemlevel = items[13];
        if (itemlevel !== level && level < itemlevel) {
          delete obj.characterInfo.items[itemName];
          delete obj.characterInfo.sumTotals[itemName];
          // delete murkup
          delete obj.itemToolTipMarkup[itemName];

          if (obj.characterInfo.curentEnchants[itemName]) {
            delete obj.characterInfo.curentEnchants[itemName];
          }
        }
        return null;
      });
    }
    return obj;
  };

  resetEnchantByLevel = (obj, level) => {
    if (obj.characterInfo.curentEnchants) {
      Object.keys(obj.characterInfo.curentEnchants).forEach((enchantName) => {
        const enchantLevel = obj.characterInfo.curentEnchants[enchantName][3];
        if (enchantLevel !== level && level < enchantLevel) {
          delete obj.characterInfo.curentEnchants[enchantName];
        }
      });
    }
    return obj;
  };

  resetEnchantByPhase = (obj, phase) => {
    if (obj.characterInfo.curentEnchants) {
      Object.keys(obj.characterInfo.curentEnchants).forEach((enchantName) => {
        const enchantPhase = obj.characterInfo.curentEnchants[enchantName][2];
        if (enchantPhase !== phase && phase < enchantPhase) {
          delete obj.characterInfo.curentEnchants[enchantName];
        }
      });
    }
    return obj;
  };

  updateState = (setStateObject) => {
    this.setState(setStateObject);
  };

  sumAttributes = (noUpdate, state = this.state) => {
    const stateAttributes = Object.assign({}, state);
    // calculate attributes
    let atts = Object.keys(stateAttributes.characterInfo.sumTotals).map(
      (key) => stateAttributes.characterInfo.sumTotals[key]
    );

    if (Object.keys(stateAttributes.characterInfo.curentEnchants).length > 0) {
      const enchantAtts = Object.keys(
        stateAttributes.characterInfo.curentEnchants
      ).map((key) => stateAttributes.characterInfo.curentEnchants[key][0]);
      atts = [...atts, ...enchantAtts];
    }

    // add setBonuses to sumTotals
    if (Object.keys(this.state.sets).length !== 0) {
      const setsEquipment = { ...this.state.sets };
      for (const key in setsEquipment) {
        if (setBonuses[key]) {
          const countOfEquipedItemsById = setsEquipment[key].length;
          const setBonusesByKey = setBonuses[key];
          const arrOfsetsAttrs = [];
          for (const key in setBonusesByKey) {
            if (+key <= countOfEquipedItemsById) {
              arrOfsetsAttrs.push(setBonusesByKey[key]);
            }
          }
          atts = [...atts, ...arrOfsetsAttrs];
        }
      }
    }
    // end

    const attributes = atts.reduce((acc, curr) => {
      acc = Object.keys(curr).reduce((acc, key) => {
        acc[key] = acc[key] ? acc[key] + curr[key] : curr[key];
        return acc;
      }, acc);
      return acc;
    }, {});

    this.addBuffs([attributes]);

    // do percent modifiers
    Object.keys(stateAttributes.characterInfo.percentTotals).map((percentObj) =>
      Object.keys(
        stateAttributes.characterInfo.percentTotals[percentObj]
      ).map((percentKey) =>
        attributes[percentKey]
          ? (attributes[percentKey] =
              attributes[percentKey] +
              attributes[percentKey] *
                stateAttributes.characterInfo.percentTotals[percentObj][
                  percentKey
                ])
          : ''
      )
    );
    /**
     * SET && CALCULATE BASE CHARACTER STATS
     */
    //    let strength = attributes["strength"] ? attributes["strength"] : 0;
    const health = attributes.health ? attributes.health : 0;
    const mana = attributes.mana ? attributes.mana : 0;
    const defense = attributes.defense
      ? attributes.defense + stateAttributes.characterInfo.lvl * 5
      : stateAttributes.characterInfo.lvl * 5;
    const spellPwr = attributes.spell_pwr_min
      ? attributes.spell_pwr_min
      : 0;
    const meleeHit = attributes.hit_pct ? attributes.hit_pct : 0;
    const spellHit = attributes.spell_hit_pct
      ? attributes.spell_hit_pct
      : 0;
    const heal_pwr_min = attributes.heal_pwr_min
      ? attributes.heal_pwr_min
      : 0;
    const mp5 = attributes.mp5 ? attributes.mp5 : 0;
    let main_hand_skill = this.calcSkills('main_hand_skill');
    let offhand_skill = this.calcSkills('offhand_skill');
    let ranged_skill = this.calcSkills('ranged_skill');
    attributes.main_hand_skill = main_hand_skill; // skill
    attributes.offhand_skill = offhand_skill; // skill
    attributes.ranged_skill = ranged_skill; // skill

    main_hand_skill =
      main_hand_skill > 300 ? (main_hand_skill - 300) * 0.04 : 0;
    offhand_skill = offhand_skill > 300 ? (offhand_skill - 300) * 0.04 : 0;
    ranged_skill = ranged_skill > 300 ? (ranged_skill - 300) * 0.04 : 0;
    attributes.armor = this.calculateArmor(attributes);
    attributes.defense = defense;
    attributes.dodge = this.calculateDodge(attributes); // this.calculateAvoidance(attributes, "dodge");
    attributes.parry = this.calculateAvoidance(attributes, 'parry');
    attributes.miss = this.calculateAvoidance(attributes, 'miss');
    attributes.block_pct = this.calculateBlockPct(attributes); // this.calculateAvoidance(attributes, "block_pct");
    attributes.mp5 = mp5;
    attributes.spirit_regen = this.calculateSpiritRegen(attributes);
    attributes.block_value_total = this.calculateBlockValue(attributes);
    attributes.crit_pct = this.calculateMeleeCrit(
      attributes,
      main_hand_skill,
      offhand_skill,
      ranged_skill
    );
    attributes.attack_pwr_1 = this.calcAttackPower(attributes);
    attributes.range_attack_pwr = this.calcRangedAttackPower(attributes);
    attributes.spell_pwr_min = spellPwr;
    attributes.hit_pct = (
      meleeHit +
      main_hand_skill +
      offhand_skill +
      ranged_skill
    ).toFixed(2);
    attributes.spell_hit_pct = spellHit;
    attributes.spell_crit_pct = this.calculateSpellCrit(attributes);
    attributes.spell_penetration = this.calculateSpellPenetration(
      stateAttributes.characterInfo.items
    );
    attributes.heal_pwr_min = heal_pwr_min;

    attributes.arcane_res_value = attributes.arcane_res_value
      ? attributes.arcane_res_value
      : 0;
    attributes.fire_res_value = attributes.fire_res_value
      ? attributes.fire_res_value
      : 0;
    attributes.frost_res_value = attributes.frost_res_value
      ? attributes.frost_res_value
      : 0;
    attributes.nature_res_value = attributes.nature_res_value
      ? attributes.nature_res_value
      : 0;
    attributes.shadow_res_value = attributes.shadow_res_value
      ? attributes.shadow_res_value
      : 0;
    attributes.holy_res_value = attributes.holy_res_value
      ? attributes.holy_res_value
      : 0;

    // calculate health
    attributes.health =
      parseInt(health, 10) + this.convertHealthMana(attributes.stamina, 10);
    // set human/gnome/tauren racials
    // if (this.state.characterInfo.race === 0) {
    //   attributes["spirit"] = Math.round(
    //     parseInt(attributes["spirit"], 10) +
    //     parseInt(attributes["spirit"], 10) * 0.05
    //   );
    // }
    // if (this.state.characterInfo.race === 3) {
    //   attributes["intellect"] = Math.round(
    //     parseInt(attributes["intellect"], 10) +
    //     parseInt(attributes["intellect"], 10) * 0.05
    //   );
    // }
    if (this.state.characterInfo.race === 6) {
      attributes.health = Math.round(
        parseInt(attributes.health, 10) +
          parseInt(attributes.health, 10) * 0.05
      );
    }

    // calculate mana

    attributes.mana =
      parseInt(mana, 10) +
      this.convertHealthMana(attributes.intellect, 15, attributes.mana);
    // calculate dps
    // let dpsval =
    //   (attributes["attack_pwr_1"] / 14) * (stateAttributes.weaponSpeed / 1000);
    // stateAttributes.dps = Math.round(dpsval * 100) / 100;

    stateAttributes.characterInfo.attributes.baseAttributes = attributes;

    if (noUpdate) return attributes;
    else this.setState(stateAttributes);
  };

  getSkillBonus (isRanged, typeWeapon, items) {
    let keyItem = 0;
    if (isRanged) {
      keyItem = attributeData.rangedSkill[typeWeapon];
    } else {
      keyItem = attributeData.handSkill[typeWeapon];
    }
    let valSkill = 0;
    for (const item in items) {
      valSkill +=
        items[item][12][47] === keyItem && !!items[item][12][46]
          ? items[item][12][46]
          : 0;
      valSkill +=
        items[item][12][53] === keyItem && !!items[item][12][52]
          ? items[item][12][52]
          : 0;
      valSkill +=
        items[item][12][55] === keyItem && !!items[item][12][54]
          ? items[item][12][54]
          : 0;
    }
    return valSkill;
  }

  calcSkills (skill_type) {
    let valSkill = this.state.characterInfo.lvl * 5;
    switch (skill_type) {
      case 'main_hand_skill':
        if (this.state.characterInfo.items.mainhand) {
          valSkill += this.getSkillBonus(
            false,
            this.state.characterInfo.items.mainhand[5],
            this.state.characterInfo.items
          );
          if (this.state.characterInfo.race === 0) {
            if (
              [4, 5, 7, 8].includes(this.state.characterInfo.items.mainhand[5])
            ) {
              valSkill += 5;
            }
          }
          if (this.state.characterInfo.race === 4) {
            if ([0, 1].includes(this.state.characterInfo.items.mainhand[5])) {
              valSkill += 5;
            }
          }
          return valSkill;
        }
        break;
      case 'offhand_skill':
        if (
          !!this.state.characterInfo.items.offhand &&
          ![21].includes(this.state.characterInfo.items.offhand[5])
        ) {
          valSkill += this.getSkillBonus(
            false,
            this.state.characterInfo.items.offhand[5],
            this.state.characterInfo.items
          );
          if (this.state.characterInfo.race === 0) {
            if ([4, 7].includes(this.state.characterInfo.items.offhand[5])) {
              valSkill += 5;
            }
          }
          if (this.state.characterInfo.race === 4) {
            if ([0].includes(this.state.characterInfo.items.offhand[5])) {
              valSkill += 5;
            }
          }
          return valSkill;
        }
        break;
      case 'ranged_skill':
        if (
          !!this.state.characterInfo.items.ranged &&
          !![2, 3].includes(this.state.characterInfo.items.ranged[5])
        ) {
          valSkill += this.getSkillBonus(
            true,
            this.state.characterInfo.items.ranged[5],
            this.state.characterInfo.items
          );
          if (this.state.characterInfo.race === 1) {
            if (this.state.characterInfo.items.ranged[5] === 3) {
              valSkill += 5;
            }
          }
          if (this.state.characterInfo.race === 7) {
            if (this.state.characterInfo.items.ranged[5] === 2) {
              valSkill += 5;
            }
          }
          return valSkill;
        }
        break;
      default:
        break;
    }
    return '–';
  }

  calculateSpellPenetration = (items) => {
    let result = 0;

    for (const key in items) {
      result += items[key][12][11] ? items[key][12][11] : 0;
    }

    return result;
  };

  calculateDodge = (attributes) => {
    let result = 0;

    let dodgeRatio = 20;
    const charClass = this.state.characterInfo.class;
    // hunter
    if (charClass === 7) {
      dodgeRatio = 26.5;
    }
    // rogue
    if (charClass === 8) {
      dodgeRatio = 14.5;
    }

    const charAgility = attributes.agility ? attributes.agility : 0;
    const charRace = this.state.characterInfo.race;
    const charDefense = attributes.defense ? attributes.defense : 0;
    if (charRace === 2) {
      const bonusNightElf = 1;
      result +=
        attributeData.baseDodge[charClass] +
        bonusNightElf +
        charAgility / dodgeRatio;
    } else {
      result += attributeData.baseDodge[charClass] + charAgility / dodgeRatio;
    }
    result += (charDefense - this.state.characterInfo.lvl * 5) * 0.04;

    result += attributes.dodge ? attributes.dodge : 0;

    return result.toFixed(2);
  };

  calculateBlockPct = (attributes) => {
    let calcStat = attributes.block_pct ? attributes.block_pct + 5 : 5;
    const defense = attributes.defense;
    let bonusDefense = 0;
    if (defense > 300) {
      bonusDefense = defense - 300;
      calcStat = calcStat + bonusDefense * 0.04;
    }
    return calcStat.toFixed(2);
  };

  calculateAvoidance = (attributes, stat) => {
    let calcStat = attributes[stat] ? attributes[stat] + 5 : 5;
    const defense = attributes.defense;
    let bonusDefense = 0;
    if (stat === 'dodge') {
      calcStat = calcStat - 5;
      const charAgility = attributes.agility ? attributes.agility : 0;
      calcStat = calcStat + charAgility / 20;
    }
    if (defense > 300) {
      bonusDefense = defense - 300;
      calcStat = calcStat + bonusDefense * 0.04;
    }
    return calcStat.toFixed(2);
  };

  calculateSpiritRegen = (attributes) => {
    const charClass = this.state.characterInfo.class;
    const charSpirit = attributes.spirit ? attributes.spirit : 0;
    const baseRegen = 15;
    let regen = 0;

    // paladin
    if (charClass === 3 || charClass === 7 || charClass === 4) {
      regen = baseRegen + charSpirit / 5;
    }
    // mage, priest
    else if (charClass === 1 || charClass === 6) {
      regen = 13 + charSpirit / 4;
    }
    // warlock
    else if (charClass === 5) {
      regen = 8 + charSpirit / 4;
    }

    return regen;
  };

  calculateBlockValue = (attributes) => {
    const charClass = this.state.characterInfo.class;
    let block_value_total = attributes.block_value_total
      ? attributes.block_value_total
      : 0;
    const charStrength = attributes.strength ? attributes.strength : 0;
    // warrior, paladin, shaman
    if (charClass === 0 || charClass === 2 || charClass === 4) {
      block_value_total = block_value_total + charStrength / 2;
    }

    return block_value_total;
  };

  calculateArmor = (attributes) => {
    const charAgility = attributes.agility ? attributes.agility : 0;
    let armor = attributes.armor ? attributes.armor : 0;
    armor = armor + charAgility * 2;
    return armor;
  };

  calculateMeleeCrit = (
    attributes,
    main_hand_skill,
    offhand_skill,
    ranged_skill
  ) => {
    const charClass = this.state.characterInfo.class;
    const charAgility = attributes.agility ? attributes.agility : 0;
    let meleeCrit = attributes.crit_pct ? +attributes.crit_pct : 0;
    let critModifier = 20;

    if (charAgility > 0) {
      // rogue
      if (charClass === 8) {
        critModifier = 29;
        // hunter
      } else if (charClass === 7) {
        critModifier = 53;
      }
      // everyone else
      meleeCrit = meleeCrit + charAgility / critModifier;
    }

    meleeCrit += main_hand_skill + offhand_skill + ranged_skill;

    return meleeCrit.toFixed(2);
  };

  calculateSpellCrit = (attributes) => {
    const charClass = this.state.characterInfo.class;
    const charIntellect = attributes.intellect ? attributes.intellect : 0;
    let spellCrit = attributes.spell_crit_pct
      ? attributes.spell_crit_pct
      : 0;
    let critModifier = 59.5;
    const casters = [1, 2, 3, 4, 5, 6];

    if (charIntellect > 0 && casters.indexOf(charClass) !== -1) {
      if (charClass === 2) {
        // paladin
        critModifier = 29.5;
      } else if (charClass === 3) {
        // druid,
        critModifier = 60;
      } else if (charClass === 6) {
        // priest
        critModifier = 59.2;
      } else if (charClass === 5) {
        // warlock
        critModifier = 60.6;
      } else if (charClass === 4) {
        // shaman
        critModifier = 39.5;
      }
      // everyone else
      spellCrit = (spellCrit + charIntellect / critModifier).toFixed(2);
    }

    return spellCrit;
  };

  calcRangedAttackPower = (attributes) => {
    const charLevel = this.state.characterInfo.lvl;
    const charClass = this.state.characterInfo.class;
    const charAgility = attributes.agility ? attributes.agility : 0;
    let range_attack_pwr = 0;

    if (charClass === 7) {
      range_attack_pwr = charLevel * 2 + charAgility * 2 - 10;
    } else if (charClass === 0 || charClass === 8) {
      range_attack_pwr = charLevel + charAgility - 10;
    } else {
      range_attack_pwr = charAgility - 10;
    }

    return range_attack_pwr;
  };

  calcAttackPower = (attributes) => {
    const charLevel = this.state.characterInfo.lvl;
    const charClass = this.state.characterInfo.class;
    const charStrength = attributes.strength ? attributes.strength : 0;
    const charAgility = attributes.agility ? attributes.agility : 0;
    const charAttackPower = attributes.attack_pwr_1
      ? attributes.attack_pwr_1
      : 0;
    let baseAttackPower = 0;

    // warrior, paladin
    if (charClass === 0 || charClass === 3) {
      baseAttackPower = charStrength * 2 + charLevel * 3 - 20;
    }
    // mage, warlock, priest
    else if (charClass === 1 || charClass === 5 || charClass === 6) {
      baseAttackPower = charStrength - 10;
    }
    // druid
    else if (charClass === 2) {
      baseAttackPower = charStrength * 2 - 20;
      // DIRE BEAR (Strength x 2) - 20 + DireBear: 120 Normal: 30
      // CAT 	(Strength x 2) + Agility - 20 + 40
    }
    // shaman
    else if (charClass === 4) {
      // ((Agility x 2) - 20) + (level x 2)
      baseAttackPower = charAgility * 2 - 20 + charLevel * 2;
    }
    // hunter
    else if (charClass === 7) {
      // Strength + Agility + (Char.Level x 2) - 20
      baseAttackPower = charStrength + charAgility + charLevel * 2 - 20;
    }
    // rogue
    else if (charClass === 8) {
      // Strength + (Agility x 2) + (Char.Level x 2) - 20
      baseAttackPower = charStrength + charAgility + charLevel * 2 - 20;
    }

    return baseAttackPower + charAttackPower;
  };

  convertHealthMana = (attribute, factor, mana) => {
    // first 20 attribute counts as 1HP/1MP, after 20 counts as 10
    // base hp + 20 + (stam - 20) * 10
    let newval = 0;
    if (mana === 0) {
      return newval;
    }
    if (attribute && factor) {
      newval = (parseInt(attribute, 10) - 20) * factor + 20;
    }
    return newval;
  };

  getRaceNameById = (raceId) => {
    switch (raceId) {
      case 0:
        return 'human';
      case 1:
        return 'dwarf';
      case 2:
        return 'night elf';
      case 3:
        return 'gnome';
      case 4:
        return 'orc';
      case 5:
        return 'undead';
      case 6:
        return 'tauren';
      case 7:
        return 'troll';
      default:
        break;
    }
  };

  handleCheckBoxToggle = (event) => {
    const value = event.target.value;
    const optionType = event.target.name.split('-')[0];
    const optionName = event.target.name.split('-')[1];
    const checked = event.target.checked;
    const stateAttributes = Object.assign({}, this.state);

    // Check all children checkboxes
    if (value === 'checkall') {
      Object.keys(stateAttributes[optionType][optionName]).map(
        (key) =>
          (stateAttributes[optionType][optionName][key].checked = checked)
      );
      Object.keys(stateAttributes[optionType][optionName]).map((key) =>
        checked && stateAttributes[optionType][optionName][key].attributes
          ? (stateAttributes.characterInfo.sumTotals[
            event.target.name + '-' + key
          ] = stateAttributes[optionType][optionName][key].attributes)
          : delete stateAttributes.characterInfo.sumTotals[
            event.target.name + '-' + key
          ]
      );
    }
    // single checkbox selected
    else {
      stateAttributes[optionType][optionName][value].checked = checked;
      if (checked) {
        if (stateAttributes[optionType][optionName][value].attributes) {
          stateAttributes.characterInfo.sumTotals[event.target.name] =
            stateAttributes[optionType][optionName][value].attributes;
        }
        if (stateAttributes[optionType][optionName][value].attributesPercent) {
          stateAttributes.characterInfo.percentTotals[event.target.name] =
            stateAttributes[optionType][optionName][value].attributesPercent;
        }
      } else {
        delete stateAttributes.characterInfo.sumTotals[event.target.name];
        delete stateAttributes.characterInfo.percentTotals[event.target.name];
      }
    }
    this.updateState(stateAttributes);

    this.sumAttributes();
  };

  handleChangeActiveAttributes = (attributes) => {
    this.setState({ activeAttributes: [...attributes] });
  };

  addBuffs = (attrs) => {
    const attrToBuff = [
      'strength',
      'stamina',
      'agility',
      'intellect',
      'spirit'
    ];

    let factor = 1;
    // Calculate BlessingOfKings & SpiritOfZandalar
    this.state.characterInfo.buffs.forEach((buff) => {
      factor = factor * buffsImport.values[buff];
    });
    // Multiple base attributes by factor
    attrs.forEach((item) => {
      if (Array.isArray(item)) {
        item.forEach((item) => {
          attrToBuff.forEach((attr) => {
            if (item[attr]) item[attr] = Math.round(item[attr] * factor);
          });
        });
      }
      attrToBuff.forEach((attr) => {
        if (item[attr]) item[attr] = Math.round(item[attr] * factor);
      });
    });
    return attrs;
  };
}

export default CharacterView;
