import React, { Fragment } from 'react';
import SlotName from './slotName';
// import SlotImage from "./slotImage";
import ItemToolTip from '../itemToolTip';

// const itemImagesImport = require("../../../data/itemNamesMap.json");
class itemSlot extends React.Component {
  render () {
    const props = this.props;

    const enchant = props.enchants[props.data.name]
      ? props.enchants[props.data.name]
      : '';
    const selected =
      props.currentItem && props.itemSlot.name === props.currentItem
        ? 'selected-slot'
        : '';
    const slotNameValue = props.item.length ? props.item[1] : props.data.name;
    const itemRarity = props.item.length ? props.item[7] : 1;

    let currentSetId = '';
    for (const key in props.sets) {
      if (props.sets[key].includes(props.item[0])) {
        currentSetId = key;
      }
    }

    return (
      <div
        className={'col s12 m6 itemrow ' + selected}
        onClick={props.handleClick}
        id={props.data.name + '_' + props.data.id}
        onMouseOver={() => {
          props.updateTooltip(null);
        }}
      >
        {props.item.length ? (
          <Fragment>
            <ItemToolTip
              item={props.item}
              classAdd={'b-link'}
              color={props.item[7]}
              currentSetId={currentSetId}
              sets={props.sets}
              data={props.item[0]}
              enchantId={enchant ? enchant[6] : ''}
              itemUrl={'https://wowclassicdb.com/item/' + props.item[0]}
              slotName={props.data.name}
              id={props.data.name + '_' + props.data.id}
            >
              {/* <SlotImage
              alt={slotNameValue}
              src={
                "http://media.blizzard.com/wow/icons/56/" +
                itemImagesImport[props.item[6]].toLowerCase() +
                ".jpg"
              }
            /> */}
              <SlotName name={slotNameValue} rarity={itemRarity} />
            </ItemToolTip>
            <div
              className="remove-item"
              id={props.data.name}
              onClick={props.resetCurrentItem.bind(this, props.data.name)}
            >
            <span role="img" aria-label="donut">
              &#10060;
            </span>
            </div>
              {!props.disableEnchant ? (
                <div
                className='enchant-scroll-container modal-trigger'     
                href="#enchants-modal"
                onClick={props.handleEnchantClick.bind(this, props.data.name)}>
                  {enchant[8] ? 
                  (<img className='enchant-scroll tooltipped enchant-active' data-position="bottom" data-tooltip={enchant[8]} src="https://cdn.wowclassicdb.com/icons/inv_scroll_06.jpg"/>) 
                  : (<img className='enchant-scroll tooltipped' data-position="bottom" data-tooltip="Add Enchant"  src="https://cdn.wowclassicdb.com/icons/inv_scroll_06.jpg"/>)}
                </div>
              ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <div className={'item-doll-img left ' + props.slot} />
            <SlotName name={slotNameValue} rarity={itemRarity} />
          </Fragment>
        )}
      </div>
    );
  }
}

export default itemSlot;
