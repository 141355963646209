import React from 'react';

class ItemToolTip extends React.Component {
  render () {
    const props = this.props;

    if (!props.itemUrl) return null;
    const dataWowHeadStr = getWowheadDataStr(props);

    return (
      <a
        className={`${'item-rarity-' + props.color}`}
        href={props.itemUrl}
        data-wowdb-rename-link="false"
        data-wowhead={dataWowHeadStr}
        rel="external nofollow"
      >
        {props.children}
      </a>
    );
  }
}

const getWowheadDataStr = props => {
  const dataWowHeadStr =
    (props.enchantId ? `ench=${props.enchantId}` : '') +
    (props.sets && props.currentSetId ? getSetsText(props) : '') +
    (props.item[25] ? `&rand=${props.item[25]}` : '');

  return dataWowHeadStr;
};

const getSetsText = props => {
  let setPiecesText = '';
  if (props.sets && props.currentSetId) {
    setPiecesText = '&pcs=';
    props.sets[props.currentSetId].map(el => {
      setPiecesText = setPiecesText + el + ':';
      return null;
    });
  }
  return setPiecesText;
};

export default ItemToolTip;
