<template>
  <TemplateSinglePage>
    <div id="react-atlas" />
  </TemplateSinglePage>
</template>

<script>
import atlasReducer from '@/components/atlas/reducers/atlasReducer'
import TemplateSinglePage from '@/components/TemplateSinglePage.vue'
import { createElement } from 'react'
import { createRoot } from 'react-dom/client'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

export default {
  name: 'Atlas',
  components: {
    TemplateSinglePage
  },
  mounted () {
    const domContainer = document.querySelector('#react-atlas')
    const root = createRoot(domContainer)
    // Load client-side only
    if (typeof window !== 'undefined') {
      const store = createStore(atlasReducer, applyMiddleware(thunk))
      const Atlas = require('@/components/atlas').default
      root.render(createElement(Atlas, { store }))
    }
  },
  metaInfo: {
    link: [
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' }
    ],
    title: 'WoW Classic Atlas & Online Interactive Map',
    meta: [
      { property: 'og:title', vmid: 'og:title', content: 'WoW Classic Atlas & Online Interactive Map' },
      { property: 'og:url', vmid: 'og:url', content: `${process.env.VUE_APP_BASE_URL}/tools/atlas` },
      { property: 'og:description', vmid: 'og:description', content: 'Atlas game and satellite maps for WoW Classic.' },
      { property: 'description', vmid: 'description', content: 'Atlas game and satellite maps for WoW Classic.' }
    ]
  }
}
</script>
