import React from 'react';

const enchantModal = props => {
  let sortedEnchants = props.enchants.sort(sortEnchants);

  const currentEnchant =
    props.currentEnchants && props.currentEnchants[props.slotName]
      ? props.currentEnchants[props.slotName]
      : [];

  // Set selected enchant to start of enchants array
  if (currentEnchant.length) {
    sortedEnchants = sortedEnchants.filter(enchant => {
      return enchant[5] !== currentEnchant[5];
    });
    sortedEnchants.unshift(currentEnchant);
  }

  return (
    <div id="enchants-modal" className="modal modal-fixed-footer">
      <div className="modal-header">
        <h4>Enchant {props.slotName}</h4>
        <hr />
      </div>
      <div className="modal-content">
        <table>
          <tbody>
            {sortedEnchants.map(enchant => (
              <tr
                onClick={props.handleSetEnchant.bind(this, enchant)}
                key={enchant[1]}
                className={
                  'modal-item row' +
                  (currentEnchant[5] === enchant[5]
                    ? ' modal-enchant-current'
                    : '')
                }
              >
                <td className="modal-close">
                  <a
                    className="enchants-link"
                    href={'https://wowclassicdb.com/spell/' + enchant[5]}
                  >
                    {enchant[8]}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <a
          href="#!"
          className="modal-close btn-small left"
          onClick={props.handleRemoveEnchant}
        >
          Remove Enchant
        </a>
        <a href="#!" className="modal-close btn-small">
          Cancel
        </a>
      </div>
    </div>
  );
};

const sortEnchants = (first, second) => {
  let a = first[0][Object.keys(first[0])[0]];
  let b = second[0][Object.keys(second[0])[0]];

  if (a && b) {
    return b - a;
  } else {
    if (!a) a = 0;
    if (!b) b = 0;

    return b - a;
  }
};

export default enchantModal;
