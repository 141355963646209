import React from 'react';

const loadSetup = (props) => {
  const savedGearSetups = props.savedGearSetups
    ? props.decryptSavedGearSetups(props.savedGearSetups)
    : {};

  return (
    <div>
      <button className="btn-small left" onClick={props.handleSave}>
        <i className="material-icons left">save</i>save setup
      </button>
      <ul id="dropdown2" className="dropdown-content left">
        {Object.keys(savedGearSetups).map((key) => (
          <li key={'saved-setup-' + savedGearSetups[key]}>
            <a href={'/tools/gearplanner/' + savedGearSetups[key]}>{key}</a>
          </li>
        ))}
      </ul>
      {Object.keys(savedGearSetups).length ? (
        <a
          className="btn-small dropdown-trigger left"
          href="#!"
          data-target="dropdown2"
        >
          <i className="material-icons left">open_in_browser</i>
          Load Setup ({Object.keys(savedGearSetups).length})
        </a>
      ) : null}
      {savedGearSetups[props.getSaveName()] ? (
      <button className="btn-small right" onClick={props.delete}>
        <i className="material-icons left">delete_forever</i>Delete
      </button>
      ) : null }
    </div>
  );
};

export default loadSetup;
