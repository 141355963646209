import React from 'react';

class WeightsLabel extends React.Component {
  render () {
    const props = this.props;

    const labelValue =
      props.weightsData.EXTENDED_NAME_MAP &&
      props.weightsData.EXTENDED_NAME_MAP[props.tooltipKey]
        ? props.weightsData.EXTENDED_NAME_MAP[props.tooltipKey]
        : '';

    return (
      <div className="valign-wrapper">
        <label
          className="left"
          htmlFor={props.tooltipKey}
          id={`Tooltip-${props.id}`}
        >
          {labelValue}
        </label>
        {props.description ? (
          <i
            className="tiny material-icons tooltipped left"
            data-position="right"
            data-tooltip={props.description}
          >
            info_outline
          </i>
        ) : null}
      </div>
    );
  }
}

export default WeightsLabel;
