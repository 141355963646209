<template>
  <TemplateSinglePage :show-loading="!race">
    <div
      v-if="race"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <h1 class="mb-2 font-sans text-3xl font-bold text-primary">
        <div class="flex flex-wrap items-center">
          <img
            v-if="hasAllowedClass"
            :src="`${baseUrl}/img/race_${race.name.replace(/\s/g, '').toLowerCase()}_male.jpg`"
            :alt="race.name"
            class="mr-2 object-cover w-12 h-12 p-0 m-0 text-black border border-solid border-bg-900"
          >
          <img
            v-if="hasAllowedClass"
            :src="`${baseUrl}/img/race_${race.name.replace(/\s/g, '').toLowerCase()}_female.jpg`"
            :alt="race.name"
            class="mr-2 object-cover w-12 h-12 p-0 m-0 text-black border border-solid border-bg-900"
          >
          <span> {{ race.name }} </span>
        </div>
      </h1>
      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />
      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Race',
  components: {
    TemplateSinglePage,
    QuickFacts,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.race?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.race?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.race?.quests, 'quests', '', RelatedTableFilterOption.quests),
        setRelatedTable(this.race?.classes, 'classes', '', RelatedTableFilterOption.classes),
        setRelatedTable(this.race?.racials, 'racials', 'Racials', RelatedTableFilterOption.spells),
        setRelatedTable(this.race?.languages, 'languages', 'Languages', RelatedTableFilterOption.spells),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {}
    },
    metaDescription () {
      return `View Race ${this.race?.name} from WoW Classic.`
    },
    race () {
      return this.$store.state.races[this.$route.params.id]
    },
    hasAllowedClass () {
      return this.race?.allowedClasses?.length > 0
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchRace().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.race) this.fetchRace()
  },
  mounted () { // Client side fetching
    this.fetchRace()
  },
  methods: {
    fetchRace () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchRace', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>
