import { kebabCase, lowerCase, pipe } from '../../../../../../lib/src/util'
import locationTypes from './location-types'

const state = () => ({
  locations: {
    1: { name: 'Dun Morogh', continent: 'Eastern Kingdoms' },
    3: { name: 'Badlands', continent: 'Eastern Kingdoms' },
    4: { name: 'Blasted Lands', continent: 'Eastern Kingdoms' },
    8: { name: 'Swamp of Sorrows', continent: 'Kalimdor' },
    10: { name: 'Duskwood', continent: 'Eastern Kingdoms' },
    11: { name: 'Wetlands', continent: 'Eastern Kingdoms' },
    12: { name: 'Elwynn Forest', continent: 'Eastern Kingdoms' },
    14: { name: 'Durotar', continent: 'Kalimdor' },
    15: { name: 'Dustwallow Marsh', continent: 'Kalimdor' },
    16: { name: 'Azshara', continent: 'Kalimdor' },
    17: { name: 'The Barrens', continent: 'Kalimdor' },
    28: { name: 'Western Plaguelands', continent: 'Eastern Kingdoms' },
    33: { name: 'Stranglethorn Vale', continent: 'Eastern Kingdoms' },
    36: { name: 'Alterac Mountains', continent: 'Eastern Kingdoms' },
    38: { name: 'Loch Modan', continent: 'Eastern Kingdoms' },
    40: { name: 'Westfall', continent: 'Eastern Kingdoms' },
    41: { name: 'Deadwind Pass', continent: 'Eastern Kingdoms' },
    44: { name: 'Redridge Mountains', continent: 'Eastern Kingdoms' },
    45: { name: 'Arathi Highlands', continent: 'Eastern Kingdoms' },
    46: { name: 'Burning Steppes', continent: 'Eastern Kingdoms' },
    47: { name: 'The Hinterlands', continent: 'Eastern Kingdoms' },
    51: { name: 'Searing Gorge', continent: 'Eastern Kingdoms' },
    85: { name: 'Tirisfal Glades', continent: 'Eastern Kingdoms' },
    130: { name: 'Silverpine Forest', continent: 'Eastern Kingdoms' },
    139: { name: 'Eastern Plaguelands', continent: 'Eastern Kingdoms' },
    141: { name: 'Teldrassil', continent: 'Kalimdor' },
    148: { name: 'Darkshore', continent: 'Kalimdor' },
    206: { name: 'Westfall', continent: 'Eastern Kingdoms' },
    209: { name: 'Shadowfang Keep', type: 'Dungeon' },
    215: { name: 'Mulgore', continent: 'Kalimdor' },
    267: { name: 'Hillsbrad Foothills', continent: 'Eastern Kingdoms' },
    331: { name: 'Ashenvale', continent: 'Kalimdor' },
    357: { name: 'Feralas', continent: 'Kalimdor' },
    361: { name: 'Felwood', continent: 'Kalimdor' },
    400: { name: 'Thousand Needles', continent: 'Kalimdor' },
    405: { name: 'Desolace', continent: 'Kalimdor' },
    406: { name: 'Stonetalon Mountains', continent: 'Kalimdor' },
    440: { name: 'Tanaris', continent: 'Kalimdor' },
    490: { name: "Un'goro Crater", continent: 'Kalimdor' },
    491: { name: 'Razorfen Kraul', type: 'Dungeon' },
    493: { name: 'Moonglade', continent: 'Kalimdor' },
    618: { name: 'Winterspring', continent: 'Kalimdor' },
    717: { name: 'The Stockade', type: 'Dungeon' },
    718: { name: 'Wailing Caverns', type: 'Dungeon' },
    719: { name: 'Blackfathom Deeps', type: 'Dungeon' },
    721: { name: 'Gnomeregan', type: 'Dungeon' },
    722: { name: 'Razorfen Downs', type: 'Dungeon' },
    796: { name: 'Scarlet Monastery', type: 'Dungeon' },
    1176: { name: "Zul'Farrak", type: 'Dungeon' },
    1337: { name: 'Uldaman', type: 'Dungeon' },
    1377: { name: 'Silithus', continent: 'Kalimdor' },
    1417: { name: "The Temple of Atal'Hakkar", type: 'Dungeon' },
    1477: { name: 'Sunken Temple', type: 'Dungeon' },
    1497: { name: 'Undercity', continent: 'Eastern Kingdoms' },
    1519: { name: 'Stormwind City', continent: 'Eastern Kingdoms' },
    1537: { name: 'Ironforge', continent: 'Eastern Kingdoms' },
    1581: { name: 'The Deadmines', type: 'Dungeon' },
    1583: { name: 'Blackrock Spire', type: 'Dungeon' },
    1584: { name: 'Blackrock Depths', type: 'Dungeon' },
    1637: { name: 'Orgrimmar', continent: 'Kalimdor' },
    1638: { name: 'Thunder Bluff', continent: 'Kalimdor' },
    1657: { name: 'Darnassus', continent: 'Kalimdor' },
    1941: { name: 'Caverns of Time', continent: 'Kalimdor' },
    1977: { name: "Zul'Gurub", type: 'Dungeon' },
    2017: { name: 'Stratholme', type: 'Dungeon' },
    2057: { name: 'Scholomance', type: 'Dungeon' },
    2100: { name: 'Maraudon', type: 'Dungeon' },
    2159: { name: "Onyxia's Lair", type: 'Raid' },
    2437: { name: 'Ragefire Chasm', type: 'Dungeon' },
    2557: { name: 'Dire Maul', type: 'Dungeon' },
    2597: { name: 'Alterac Valley', type: 'Battleground' },
    2677: { name: 'Blackwing Lair', type: 'Raid' },
    2717: { name: 'Molten Core', type: 'Raid' },
    3277: { name: 'Warsong Gulch', type: 'Battleground' },
    3358: { name: 'Arathi Basin', type: 'Battleground' },
    3428: { name: "Ahn'Qiraj", type: 'Raid' },
    3429: { name: "Ruins of Ahn'Qiraj", type: 'Raid' },
    3456: { name: 'Naxxramas', type: 'Raid' },
    3478: { name: "Gates of Ahn'Qiraj", continent: 'Kalimdor' }
  }
})

const actions = {
  [locationTypes.actions.filterLocationsByEasternKingdoms] ({
    commit,
    dispatch,
    getters
  }) {
    dispatch(
      'handleFilterChange',
      {
        ...this.$store?.filters,
        location: getters[locationTypes.getters.locationIdsInEasternKingdoms]
      },
      { root: true }
    )
  },
  [locationTypes.actions.filterLocationsByAll] ({ commit, dispatch, getters }) {
    dispatch(
      'handleFilterChange',
      {
        ...this.$store?.filters,
        location: getters[locationTypes.getters.allLocationIds]
      },
      { root: true }
    )
  },
  [locationTypes.actions.filterLocationsByKalimdor] ({
    commit,
    dispatch,
    getters
  }) {
    dispatch(
      'handleFilterChange',
      {
        ...this.$store?.filters,
        location: getters[locationTypes.getters.locationIdsInKalimdor]
      },
      { root: true }
    )
  },
  [locationTypes.actions.filterLocationsByName] (
    { commit, dispatch, getters },
    { name }
  ) {
    dispatch(
      'handleFilterChange',
      {
        ...this.$store?.filters,
        location: getters[locationTypes.getters.locationByName](name)
      },
      { root: true }
    )
  }
}

const getters = {
  [locationTypes.getters.locations] (state) {
    return state.locations
  },
  [locationTypes.getters.locationsWithIds] (state) {
    return Object.entries(state.locations).map(item => ({
      id: item[0],
      ...{ ...item[1] }
    }))
  },
  [locationTypes.getters.locationsInBattlegrounds]: (_, getters) =>
    getters.locationsWithIds.filter(
      location => location.type === 'Battleground'
    ),
  [locationTypes.getters.locationIdsInBattlegrounds]: (_, getters) =>
    getters.locationsWithIds
      .filter(location => location.type === 'Battleground')
      .map(location => location.id),
  [locationTypes.getters.locationsInRaids]: (_, getters) =>
    getters.locationsWithIds.filter(location => location.type === 'Raid'),
  [locationTypes.getters.locationIdsInRaids]: (_, getters) =>
    getters.locationsWithIds
      .filter(location => location.type === 'Raid')
      .map(location => location.id),

  [locationTypes.getters.locationsInDungeons]: (_, getters) =>
    getters.locationsWithIds.filter(location => location.type === 'Dungeon'),
  [locationTypes.getters.locationIdsInDungeons]: (_, getters) =>
    getters.locationsWithIds
      .filter(location => location.type === 'Dungeon')
      .map(location => location.id),
  [locationTypes.getters.locationsInEasternKingdoms]: (_, getters) =>
    getters.locationsWithIds.filter(
      location => location.continent === 'Eastern Kingdoms'
    ),
  [locationTypes.getters.locationIdsInEasternKingdoms]: (_, getters) =>
    getters.locationsWithIds
      .filter(location => location.continent === 'Eastern Kingdoms')
      .map(location => location.id),

  [locationTypes.getters.locationsInKalimdor]: (_, getters) =>
    getters.locationsWithIds.filter(
      location => location.continent === 'Kalimdor'
    ),
  [locationTypes.getters.locationIdsInKalimdor]: (_, getters) =>
    getters.locationsWithIds
      .filter(location => location.continent === 'Kalimdor')
      .map(location => location.id),
  [locationTypes.getters.allLocationIds]: (_, getters) => [
    ...getters[locationTypes.getters.locationIdsInEasternKingdoms],
    ...getters[locationTypes.getters.locationIdsInKalimdor]
  ],
  [locationTypes.getters.locationById]: state => id => {
    return state.locations[id]
  },
  [locationTypes.getters.locationByName]: state => name => {
    const locationId = Object.entries(state.locations).filter(data => {
      const value = data[1]
      return pipe(kebabCase, lowerCase)(value.name) === name
    })[0]

    return locationId && (locationId[0] || {})
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
