<template>
  <TemplateListPage
    :page-title="pageTitle"
    :columns="columns"
    :api-path="apiPath"
    single-page-prefix="object"
  />
</template>

<script>
import TemplateListPage from '@/components/TemplateListPage'

export default {
  name: 'Objects',
  components: {
    TemplateListPage
  },
  data () {
    return {
      columns: [
        { field: 'name', label: 'Name', sortable: true, primary: true },
        { field: 'gameObjectType.name', label: 'Type' },
        { field: 'locations', format: (object) => object?.gameObjectSpawns?.[0]?.location?.name, label: 'Location' }
      ],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.pageTitle },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: 'View all Game Objects from WoW Classic including categories and filters for Mining and Herb nodes.' },
        { property: 'description', vmid: 'description', content: 'View all Game Objects from WoW Classic including categories and filters for Mining and Herb nodes.' }
      ]
    }
  },
  computed: {
    pageTitle () {
      return this.$store.getters.titleForRoute || 'Objects'
    },
    apiPath () {
      return this.$route.path
    }
  }
}
</script>
