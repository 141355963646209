<template>
  <div class="flex items-center justify-center w-full h-full ">
    <div class="w-full h-auto">
      <label
        for="search"
        class="sr-only"
      >Search</label>
      <div class="relative ">
        <input
          id="search"
          v-model="searchInput"
          name="search"
          autocomplete="off"
          class="block w-full py-2 pl-0 pr-12 sm:pr-3 font-bold transition border border-t-0 border-l-0 border-r-0 placeholder-footer-text text-md border-bg-100 border-b-1 bg-bg-500 focus:outline-none focus:bg-dark focus:text-gray-100 focus:ring-0 focus:border-b-2 focus:border-primary"
          placeholder="Try: Thunderfury, Blessed Blade of..."
          type="search"
          @input="getSuggestions"
          @keyup.enter="searchInput.length ? navigateToSearchPageWithQuery() : () => {}"
        >
        <router-link
          class="absolute z-50 inset-y-0 right-0 flex items-center pl-3"
          :class="{ 'pointer-events-none': !searchInput.length }"
          :to="{ path: `search`, query: { name: searchInput } }"
        >
          <!-- Search icon -->
          <svg
            class="
          w-7
          h-7
          text-footer-text"
            alt="Search Icon"
            xmlns="http://www.w3.org/2000/svg"
            view-box="0 0 24 24"
            fill="currentColor"
            width="18px"
            height="18px"
          >
            <path
              fill="currentColor"
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
          </svg>
        </router-link>
        <div
          v-if="searchSuggestions.length"
          class="absolute z-20 w-full overflow-y-auto shadow-2xl border-4 border-t-0 border-bg-300  max-h-96 bg-bg-dark"
        >
          <router-link
            v-for="(s, i) in searchSuggestions"
            :key="i"
            :to="`/${getSearchSuggestionType(s)}/${s.id}`"
            class="flex items-center px-4 py-2 cursor-pointer"
            @click.native="clearSearchInput"
          >
            <img
              v-if="s.items || s.icon"
              v-tooltip="{data: s, type: getSearchSuggestionType(s)}"
              class="border-2 border-solid border-bg-200 w-10"
              :src="`https://cdn.wowclassicdb.com/icons/${s.items ? s.items[0].icon?.name : s.icon.name}.jpg?width=36`"
              :alt="s.name"
            >
            <img
              v-if="s.sells"
              class="w-10"
              :src="`https://cdn.wowclassicdb.com/npcs/${s.id}.png?width=200`"
            >
            <img
              v-if="s.objectives"
              class="w-5"
              src="@/assets/img/icons/map/atlas_map_marker_quest_start.png"
            >
            <div
              v-tooltip="{data: s, type: getSearchSuggestionType(s)}"
              class="flex flex-col items-start ml-3 text"
            >
              <span
                v-if="s.itemQualityId"
                class="text-lg"
                :style="{'color': '#' + itemQualities[s.itemQualityId].colorCode}"
              >{{ s.name }}</span>
              <span
                v-else
                class="text-lg"
              >{{ s.name }}</span>
              <span
                v-if="s.itemType"
                class="text-sm text-white opacity-70"
              >{{
                s.itemType.name
              }}</span>
              <span
                v-if="s.objectives"
                class="text-sm text-white opacity-70"
              >Quest</span>
              <span
                v-if="s.gameObjectType"
                class="text-sm text-white opacity-70"
              >{{ s.gameObjectType.name }}</span>
              <span
                v-if="s.area"
                class="text-sm text-white opacity-70"
              >Zone - {{ s.area.name }}</span>
              <span
                v-if="s.npcType"
                class="text-sm text-white opacity-70"
              >{{ s.npcType.name }}</span>
            </div>
          </router-link>
        </div>
        <div
          v-if="searchInput.length && !searchSuggestions.length"
          class="absolute p-5 z-20 flex justify-center items-center w-full overflow-y-auto shadow-2xl border-4 border-t-0 border-bg-300  max-h-96 bg-bg-dark"
        >
          <div v-if="loading">
            <div class="lds-ring">
              <div /><div /><div /><div />
            </div>
          </div>
          <div v-else>
            <h2 class="text-xl block text-center">
              No results found
            </h2>
            <h2 class="text-xl block text-center">
              Check your spelling and try again.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/api-client'
import { debounce } from 'lodash'
import { itemQualities } from '../../../../lib/src/constants/item-constants'
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
export default {
  name: 'SearchBar',
  mixins: [
    tooltipMixin
  ],
  data () {
    return {
      itemQualities: itemQualities,
      searchInput: '',
      searchSuggestions: [],
      loading: false
    }
  },
  methods: {
    getSuggestions: debounce(async function (event) {
      const value = event?.target.value
      this.searchSuggestions = []
      if (value?.length < 3) {
        return
      }
      this.loading = true
      this.searchSuggestions = await api.getSearchSuggestions(
        value.trim()
      )
      this.loading = false
    }, 300),
    clearSearchInput () {
      this.searchInput = ''
      this.searchSuggestions = []
    },
    getSearchSuggestionType (searchSuggestion) {
      if (searchSuggestion.itemQualityId) { return 'item' }
      if (searchSuggestion.npcType) { return 'npc' }
      if (searchSuggestion.items) { return 'item-set' }
      if (searchSuggestion.minLevel >= 0) { return 'quest' }
      if (!isNaN(searchSuggestion.spellSchoolId)) { return 'spell' }
      if (searchSuggestion.gameObjectType) { return 'object' }
      if (searchSuggestion.area) { return 'zone' }
    },
    navigateToSearchPageWithQuery () {
      this.$router.push({
        path: '/search',
        query: {
          name: this.searchInput
        }
      }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
    !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
    }

  }

}
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

</style>
